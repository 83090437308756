import axios from 'axios';
import { CARTO, SERVER } from '../settings';

export function getProviders() {
  return axios.get(SERVER.LOCAL_STATISTICS);
}

export function statesInScreen(bounds) {
  //lng lat
  let sw = [bounds.nw[0], bounds.se[1]]
  let ne = [bounds.se[0], bounds.nw[1]]
  const query = CARTO.GET_QUERY(`SELECT DISTINCT name FROM ${CARTO.STATE_TABLE} WHERE ST_Intersects(the_geom,ST_Makeenvelope(${sw[0]},${sw[1]},${ne[0]},${ne[1]},4326))`);
  return axios.get(query);
}

export function citiesInScreen(bounds) {

  let sw = [bounds.nw[0], bounds.se[1]]
  let ne = [bounds.se[0], bounds.nw[1]]
  const query = CARTO.GET_QUERY(`SELECT DISTINCT city, state, CONCAT(city,', ',state_ab) as display, city as cityname  FROM ${CARTO.CITY_TABLE} WHERE ST_Intersects(the_geom,ST_Makeenvelope(${sw[0]},${sw[1]},${ne[0]},${ne[1]},4326))`);
  return axios.get(query);
}

export function searchState(providers, state) {
  const ans = {};
  providers.forEach(provider => {
    if (provider.citystate === state) {
      ans['state'] = state;
      ans['link'] = provider.link;
    }
  });
  return ans;
}

export function searchCitiesOnStateProviders(state, cities, providers) {
  providers.forEach(provider => {
    for (const city of cities) {
      if (city.city) {
        if (provider?.citystate?.toLowerCase().includes(city?.city?.toLowerCase()) ||
        city.city.toLowerCase().includes(provider.citystate.toLowerCase())) {
        city.city = provider.citystate;
        city['link'] = provider.link;
        break;
        }
      }
    }
  });
  const ans = [];
  cities.forEach(city => {
    if (city['link'] && city.state === state) {
      // special case with new york city
      if (city.city === 'New York') {
        city.city = 'New York City, NY';
        city.link = 'https://aidsvu.org/state/New-York-City';
      }
      ans.push({city: city.city, link: city.link});
    }
  });
  return ans;
}