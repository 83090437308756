import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap';
import OptionComparison from './OptionComparison';

const DataComparison = ({options,updateDC, changeDCOption, close}) => {

  const [availableOpts, setAvailableOpts] = useState({
    'Poverty': '',
    'High School Education': '',
    'Median Household Income': '',
    'Income Inequality':'',
    'People Without Health Insurance': '',
    'Unemployment':'',
    'Severe Housing Cost Burden':'',
    'Food Insecurity':'',
    'Syphilis':'',
    'Hepatitis C': '',
    'Medicaid Expansion':'',
    'HIV Criminalization':'',
    'HIV Prosecution Laws':''
  });
  // useEffect(()=>{
  //   console.log("AVLOPTS",availableOpts);
  // },[availableOpts]);
  useEffect(() => {
    let opts = availableOptions('datacomparison');
    let newAvOpt = {...availableOpts}
    if(opts.length > 0){
      for(let key in newAvOpt) {
        let isOpt = opts.filter(({label})=> label.toLowerCase() === key.toLowerCase());

        if(isOpt.length > 0) {
          isOpt.map(({value,label})=>{
            newAvOpt[key] = {
              value: value,
              label: label
            }
          });
        } else {
          newAvOpt[key] = '';
        }
      }
      console.log('New Av opt', newAvOpt);
      setAvailableOpts(newAvOpt);
    }
  },[options]);
  const updateFilters = (type, value) => {
    this.model[type] = value.split('/')[1];
    this.options[type]['selected'] = value.split('/')[0];
    return true;
  }
  const  availableOptions = (param) => {
    if (options) {
      let filter = [];
      options[param]['labels'].forEach((ele, idx) => {
        if (options[param]['hidden']) {
          // console.log("the ones hidden are HIDDEN", options[param]['hidden'], 'if hiddens includes ', options[param][param][idx]);
          if (!options[param]['hidden'].includes(options[param][param][idx])) {
            // console.log("IS ADDING BECAUSE IS NOT HIDDEN", { value: options[param][param][idx], label: ele });
            filter.push({ value: options[param][param][idx], label: ele });
          }
        }
      });
      return filter;
    }
    return [];
  }

  const selectID = (value,label) => {
    updateDC({value,label});
    changeDCOption('datacomparison',value);
    close();
  }
    function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable callback ref
    const innerRef = useRef(); // returned to client, who sets the "border" element

    // update callback on each render, so second useEffect has most recent callback
    useEffect(() => { callbackRef.current = callback; });
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (innerRef.current && callbackRef.current &&
          !innerRef.current.contains(e.target)
        ) callbackRef.current(e);
      }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
  }
  const innerRef = useOuterClick(ev => { close() });

  return (
    <div ref={innerRef} className="menu-nav">
      {/* <button type="button" className="btn btn-link btn-close" (click)="unCollapseDC()">Close <img src="assets/close.svg" alt=""></button> */}
      <button type="button" onClick={close} className="btn btn-link btn-close" >Close <img src="assets/close.svg" alt=""/></button>
      <div className="container" style={{display:'block'}}>
        <div className="align-items-baseline data-section">
          <div className="row">
            <div className="col-md-6">
              <h4 className="mr-2"><strong>Data Comparisons</strong></h4>
            </div>
          </div>
        <div className="row">
          <div className="col-md-6">
             <p>Select a comparison map to view alongside your current map</p>
             <div className="row">
               <div className="col-md-6" id="select-comparison">
                   <OptionComparison label={availableOpts['Poverty']!=''?availableOpts['Poverty'].label:'Poverty'} value={availableOpts['Poverty']!=''?availableOpts['Poverty'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Poverty']==''}></OptionComparison>
                   <OptionComparison label={availableOpts['High School Education']!=''?availableOpts['High School Education'].label:'High School Education'} value={availableOpts['High School Education']!=''?availableOpts['High School Education'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['High School Education']==''}></OptionComparison>
                   <OptionComparison label={availableOpts['Median Household Income']!=''?availableOpts['Median Household Income'].label:'Median Household Income'} value={availableOpts['Median Household Income']!=''?availableOpts['Median Household Income'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Median Household Income']==''}></OptionComparison>
                  <OptionComparison label={availableOpts['Income Inequality']!=''?availableOpts['Income Inequality'].label:'Income Inequality'} value={availableOpts['Income Inequality']!=''?availableOpts['Income Inequality'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Income Inequality']==''}></OptionComparison>
               </div>
               <div className="col-md-6">
                  <OptionComparison label={availableOpts['People Without Health Insurance']!=''?availableOpts['People Without Health Insurance'].label:'People Without Health Insurance'} value={availableOpts['People Without Health Insurance']!=''?availableOpts['People Without Health Insurance'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['People Without Health Insurance']==''}></OptionComparison>
                  <OptionComparison label={availableOpts['Unemployment']!=''?availableOpts['Unemployment'].label:'Unemployment'} value={availableOpts['Unemployment']!=''?availableOpts['Unemployment'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Unemployment']==''}></OptionComparison>
                  <OptionComparison label={availableOpts['Severe Housing Cost Burden']!=''?availableOpts['Severe Housing Cost Burden'].label:'Severe Housing Cost Burden'} value={availableOpts['Severe Housing Cost Burden']!=''?availableOpts['Severe Housing Cost Burden'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Severe Housing Cost Burden']==''}></OptionComparison>
                  <OptionComparison label={availableOpts['Food Insecurity']!=''?availableOpts['Food Insecurity'].label:'Food Insecurity'} value={availableOpts['Food Insecurity']!=''?availableOpts['Food Insecurity'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Food Insecurity']==''}></OptionComparison>
               </div>
             </div>
          </div>

          <div className="col-md-3 line-vertical" style={{padding: '0 7%'}}>
            <p >Infectious Diseases</p>
            <OptionComparison label={availableOpts['Syphilis']!=''?availableOpts['Syphilis'].label:'Syphilis'} value={availableOpts['Syphilis']!=''?availableOpts['Syphilis'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Syphilis']==''}></OptionComparison>
            <OptionComparison label={availableOpts['Hepatitis C']!=''?availableOpts['Hepatitis C'].label:'Hepatitis C'} value={availableOpts['Hepatitis C']!=''?availableOpts['Hepatitis C'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Hepatitis C']==''}></OptionComparison>
          </div>

          <div className="col-md-3" style={{paddingLeft: '5%'}}>
            <p>Policy Implications</p>
            <OptionComparison label={availableOpts['Medicaid Expansion']!=''?availableOpts['Medicaid Expansion'].label:'Medicaid Expansion'} value={availableOpts['Medicaid Expansion']!=''?availableOpts['Medicaid Expansion'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Medicaid Expansion']==''}></OptionComparison>
            <OptionComparison label={availableOpts['HIV Criminalization']!=''?availableOpts['HIV Criminalization'].label:'HIV Criminalization'} value={availableOpts['HIV Criminalization']!=''?availableOpts['HIV Criminalization'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['HIV Criminalization']==''}></OptionComparison>
            <OptionComparison label={availableOpts['HIV Prosecution Laws']!=''?availableOpts['HIV Prosecution Laws'].label:'HIV Prosecution Laws'} value={availableOpts['HIV Prosecution Laws']!=''?availableOpts['HIV Prosecution Laws'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['HIV Prosecution Laws']==''}></OptionComparison>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dcoptions: state.dcoptions,
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeDCOption: (filter,value) => {
      dispatch({ type: 'CHANGE_SELECTED', key:filter, value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataComparison)
