import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CustomRoute from './components/CustomRoute';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Main from './Main';
import Login from './components/Login';
import './App.scss';

function App() {

  return (
    <BrowserRouter>
    <Switch>
      <Redirect from='/teste' to='/' exact />
      <CustomRoute path="/login" component={Login} type="login" mapt='any'/>
      <CustomRoute path="/county-frame" component={Main} type="frame" mapt='county'/>
      <CustomRoute path="/state-frame" component={Main} type="frame" mapt='state'/>
      <CustomRoute path="/city-frame" component={Main} type="frame" mapt='city'/>
      <CustomRoute path="/" component={Main} type="menu" mapt='any'/>
      <CustomRoute path="/map" component={Main} type="menu" mapt='any'/>
      
      {/* <CustomRoute path="/map" component={Main} type="menu"/> */}
    </Switch>
  </BrowserRouter>
  );
}

export default App;
