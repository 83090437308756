import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { getStateCodes } from './../services/stateService';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { countyLoader , communityLoader, wardLoader} from './../services/downloadService';
import {saveAs} from 'file-saver';
const DownloadDataModal = ({ close , options, dataValues }) => {
  const copyText = "Sullivan PS, Woodyatt C, Koski C, Pembleton E, McGuinness P, Taussig J, Ricca A, Luisi N, Mokotoff E, Benbow N, Castel AD. A data visualization and dissemination resource to support HIV prevention and care at the local level: analysis and uses of the AIDSVu Public Data Resource. Journal of medical Internet research. 2020;22(10):e23173.";
  const [countyData, setCountyData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const forbiddenDownload = () => {
    const forbbidenCities = ['baltimore', 'boston', 'jackson', 'memphis', 'nashville', 'newark', 'oakland/alameda', 'philadelphia', 'sandiego', 'seattle' ];
    if (!options) {
      return false;
    }
    if (options.maptype !== 'city') {
      return false;
    }
    return (forbbidenCities.indexOf(options.city) >= 0);
  }
  const downloadCSV = async () => {
    if (options.maptype === 'county') {
      // this.loaderService.start();
      let statesD = await getStateCodes();
      let states = statesD.data.rows;
      
      // console.log("STATES", states);
        // this.loaderService.start();
      let counties = await countyLoader();
      if(countyData.length > 0 ) {
        for (const county of counties) {
          for (const data of countyData) {
            if (county.geo_id2 === data.geo_id2) {
              county['value'] = data.value;
            }
          }
          for (const state of states) {
            if (state.state == county.state) {
              county['statename'] = state.name;
            }
          }
        }
        let linesUnique = [];
        const lines = new Set();
        lines.add('Note: Several counties contain special characters that can only be rendered correctly by following the steps below for opening the csv in Excel or Google Sheets.');
        lines.add('Excel on Mac and Google Sheets : please refer to this official article: Import data from a CSV HTML or text file.');
        lines.add('https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fsupport.microsoft.com%2Fen-us%2Foffice%2Fimport-data-from-a-csv-html-or-text-file-b62efe49-4d5b-4429-b788-e1211b5e90f6%3Fui%3Den-us%26rs%3Den-us%26ad%3Dus&data=05%7C01%7Cshamaya.lurae.whitby%40emory.edu%7C0fd288bc84be41fae33c08dac34a0c82%7Ce004fb9cb0a4424fbcd0322606d5df38%7C0%7C0%7C638037020071507443%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=fbuH9B3RH8A5Ourun52x3TKT9ua%2B5DFN886z9ZgH4nw%3D&reserved=0');
        lines.add('Excel on Mircosoft: please refer to this official article: Import data from a CSV HTML or text file.');
        lines.add('https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fanswers.microsoft.com%2Fen-us%2Fmsoffice%2Fforum%2Fall%2Fhow-to-open-utf-8-csv-file-in-excel-without-mis%2F1eb15700-d235-441e-8b99-db10fafff3c2&data=05%7C01%7Cshamaya.lurae.whitby%40emory.edu%7C0fd288bc84be41fae33c08dac34a0c82%7Ce004fb9cb0a4424fbcd0322606d5df38%7C0%7C0%7C638037020071507443%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=sv24XsWqYIGnrQGkEiQCghebOTHFKFF2RU%2BuYFy0LE8%3D&reserved=0');
        lines.add('');
        lines.add('county,state,"' + options.mainmaptitle + '"');
        counties.sort((a,b) => {
          if (a.statename === b.statename) {
            return a.name.localeCompare(b.name);
          } else {
            return a.statename.localeCompare(b.statename);
          }
        })
        counties.forEach(county => {
            lines.add('"' + county.name + '","' + county.statename + '",' + county.value);
        });
        linesUnique = Array.from(lines);
        const lineString = linesUnique.join('\n');
        let blob = new Blob([lineString], { type: 'text/csv' });
        const fileName = 'county';
        saveAs(blob, fileName + '.csv');
        // this.loaderService.end();

      // this.loaderService.end();

      }

    }
    else if (options.maptype === 'state') {
      // this.loaderService.start();
      const lines = new Set();
      lines.add('state,"' + options.mainmaptitle + '"');
      const states = stateData;
      states.sort((a,b) => {
        return a.state.localeCompare(b.state);
      })
      states.forEach(state => {
          lines.add('"' + state.state + '",'  + state.value);
      });
      let linesUnique = Array.from(lines);
      const lineString = linesUnique.join('\n');
      let blob = new Blob([lineString], { type: 'text/csv' });
      const fileName = 'state';
      saveAs(blob, fileName + '.csv');
      // this.loaderService.end();
    } else if (options.maptype === 'city') {
      if (options.geographytype['selected'] === 'zipcode' ) {
        const lines = [];
        lines.push('city,zip,"', options.mainmaptitle, '"');
        const cities = cityData;
        cities.forEach(city => {
          if (city.value >= 0) {
            lines.push('"' + city.city + '",' + city.zip + ',' + city.value);
          }
        });
        const lineString = lines.join('\n');
        let blob = new Blob([lineString], { type: 'text/csv' });
        const fileName = 'city_zipcode';
        saveAs(blob, fileName + '.csv');
      } else if (options.geographytype['selected'] === 'censustract') {
        const lines = [];
        lines.push('city,census tract,"' + options.mainmaptitle + '"');
        const cities = cityData;
        cities.forEach(city => {
            lines.push('"' + city.city + '",' + city.census + ',' + city.value);
        });
        const lineString = lines.join('\n');
        let blob = new Blob([lineString], { type: 'text/csv' });
        const fileName = 'city_census';
        saveAs(blob, fileName + '.csv');
      } else if (options.geographytype['selected'] === 'communityarea') {
        this.loaderService.start();
        const community = await communityLoader();
        const cities = this.downloadService.cityData;
          for (const city of cities) {
            for (const data of community) {
              if (data.cartodb_id === city.cartodb_id) {
                let community = data.community;
                community = community.split(' ');
                const community_string = [];
                for (let com of community) {
                  com = com.substring(0, 1) + com.substring(1).toLowerCase();
                  community_string.push(com);
                }
                community = community_string.join(' ');
                city['community'] = community;
              }
            }
          }
          const lines = [];
          lines.push('city,community,"' + options.mainmaptitle + '"');
          cities.forEach(city => {
            if (city.community) {
              lines.push('"' + city.city + '",' + city.community + ',' + city.value);
            }
          });
          const lineString = lines.join('\n');
          let blob = new Blob([lineString], { type: 'text/csv' });
          const fileName = 'city_community';
          saveAs(blob, fileName + '.csv');

        this.loaderService.end();
      } else if (options.geographytype['selected'] === 'ward') {
        this.loaderService.start();
        let ward = await wardLoader();
          const cities = this.downloadService.cityData;
          for(const city of cities) {
            for (const data of ward) {
              if (data.cartodb_id === city.cartodb_id) {
                city['ward'] = data.ward;
              }
            }
          }
          const lines = [];
          lines.push('city,ward,"' + options.mainmaptitle + '"');
          cities.forEach(city => {
              lines.push('"' + city.city + '",' + city.ward + ',' + city.value);
          });
          const lineString = lines.join('\n');
          let blob = new Blob([lineString], { type: 'text/csv' });
          const fileName = 'city_ward';
          saveAs(blob, fileName + '.csv');

        this.loaderService.end();
      }
    }
  }

  useEffect(()=>{
    if(dataValues.countyData) {
      setCountyData(dataValues.countyData);
    }
    if(dataValues.stateData) {
      setStateData(dataValues.stateData);
    }
    if(dataValues.cityData) {
      setCityData(dataValues.cityData);
    }
  },[dataValues]);
  return (
    <div className="menu-panel">
      <button type="button" className="btn btn-link btn-close" onClick={() => close(false)}>
        Close
        <img src="assets/close.svg" alt="" className="ml-1"/>
      </button>
      <div className="container">
        <h6>
          <strong>
            Download Data
          </strong>
        </h6>
        <p className="d-flex my-4">
          <img height="40px" src="/assets/ic_download-grey.png" alt="" />
          <span className="d-flex ml-3 flex-column">
            <span>Download Map Data</span>
            {
              !forbiddenDownload() &&
              <a href="javascript:void(0)" onClick={downloadCSV} id="download-data" class="download-class-test">
                <strong id="download-string">
                  Download
                </strong>
              </a>
            }
          </span>
        </p>
        <hr />
        <h6>
          <strong>
            Data Methods
          </strong>
        </h6>
        <p>
          Please visit the <a href="https://aidsvu.org/data-methods-statecounty/"><strong>Data Methods</strong></a> for more information regarding the data in these datasets.</p>
        <hr/>
        <h6><strong>Tools & Resources</strong></h6>
        <p>Please visit the <a href="https://aidsvu.org/resources/#/tab-data/"><strong>Tools & Resources</strong></a> for more downloadable datasets.</p>
        <hr />
        <h6><strong>Citation</strong></h6>
        <p style={{marginBottom:'2px'}}>Sullivan PS, Woodyatt C, Koski C, Pembleton E, McGuinness P, Taussig J, Ricca A, Luisi N, Mokotoff E, Benbow N, Castel AD. A data visualization and dissemination resource to support HIV prevention and care at the local level: analysis and uses of the AIDSVu Public Data Resource. Journal of medical Internet research. 2020;22(10):e23173.”</p>
        <p><CopyToClipboard text={ copyText}><a href="#"><strong>Copy Citation to Clipboard</strong></a></CopyToClipboard> | <a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/AIDSVu-Protocol-Paper.pdf"><strong>Download PDF</strong></a> </p>
        <hr/>
        <h6><strong>Export Meta Data</strong></h6>
        <p style={{marginBottom:'2px'}}><a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/END.end"><strong>END</strong></a> for: Endnote</p>
        <p style={{marginBottom:'2px'}}><a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/Bib-TeX.bib"><strong>BibTeX</strong></a> for: BibDesk, LaTeX </p>
        <p style={{marginBottom:'2px'}}><a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/RIS.ris"><strong>RIS</strong></a> for: RefMan, Procite, Endnote, RefWorks</p>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    options: state.options,
    dataValues: state.dataValues
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadDataModal)
