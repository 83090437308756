import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import BottomToolbar from './../components/BottomToolbar';
import * as colorsService from '../services/colorsService';
import MapService from './MapService';
import { shouldDisplayExtraCero } from './utils';

const Footer = ({ options, isDC, hasParams}) => {
  const [title, setTitle] = useState('Rates of Persons Living with HIV, 2018');
  const [legendColors, setLegendColors ] = useState("0-180-180-180,0-180-180-180,204-180-180-180,178-200-200-200,177-255-252-209,234-244-242-120,216-241-220-141,179-253-178-75,193-253-141-60,191-252-78-43,230-252-78-43,226-224-49-33,204-135-0-0,255-102-17-15");
  const [legendValues, setLegendValues ] = useState("0 - 50, 51 - 60, 61 - 80, 81 - 90, 91 - 120, 121 - 150, 151 - 190, 191 - 250, 251 - 380, 381+");
  const legends = legendValues.split(',');
  
  const formattingLegends = (options.maptype != 'state' && shouldDisplayExtraCero(options)) ? ['0']: [];
  const [showFooter, setShowFooter] = useState(true);
  const transformOpacity = (value) => {
    // return 1;
    return (value == 255 || value == 0) ? 0.9 : value / 255;
  }
  const transformLegendColors = () => {

    const newLC = legendColors;
    const splittedColors = newLC.split(',');
    let aux;
    const res = new Array();
    // Convert values of legendcolors to an array with
    // rgba(155,21,132,0.45)
    for (const entry of splittedColors){
      const rgb = entry.split('-');
      aux = 'rgba(';
      for (let i = 1; i < 4; i++) {
        aux += rgb[i] + ',';
      }
      aux += transformOpacity(rgb[0]) + ')';
      res.push(aux);
    }
    for (let i = 0; i < 4; ++i) {
      res.shift();
    }
    
    if ((options.maptype != 'state' && shouldDisplayExtraCero(options) )) {
      res.unshift('rgba(200,200,200,255)');
    }
    return res;
  }
  const getColor = (color) => {
    if (!color) {
      return '#000000';
    }
    /*let r:number = (i*8*4);
    return "rgb("+r+","+r+","+r+")";
  */
    // checks the luminosity of background
    const c = color.substring(5, color.length - 1);
    const vals = c.split(',');
    const r = parseInt(vals[0]);
    const g = parseInt(vals[1]);
    const b = parseInt(vals[2]);
    const op = parseFloat(vals[3]);
    if ((r * 0.299 + g * 0.587 + b * 0.114) > 150) {
      return '#000000';
    } else {
      return '#ffffff';
    }

  }
  const [isMobile, setIsMobile] = useState(false);
  let legendcolorsArray = transformLegendColors();
  useEffect(() => {
    legendcolorsArray = transformLegendColors();
  }, [legendColors, formattingLegends]);

  useEffect(() => {
    if(options.maptype == 'city' && options.city == '') {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
    if (options.mainmaptitle) {
      setTitle(options.mainmaptitle);
    }
    if (options.legendcolors) {
      setLegendColors(options.legendcolors);
    }
    if (options.legendvalues) {
      setLegendValues(options.legendvalues);
    }
  }, [options]);
  useEffect(()=>{
    const changeMobile  = window.innerWidth < 900 ? true: false;
    setIsMobile(changeMobile);
  },[]);
  const format = (n) => {
    let k = n.split('.');
    return k[0].replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    }) + (k[1] ? '.' + k[1] : '');
  }
  
  for (let leg of legends) {
    // console.log('leg', leg);
    if (leg) {
      let parts = leg.split('-');
      if (parts.length > 1) {
        leg = format(parts[0].trim()) + ' - ' + format(parts[1].trim());
      } else {
        parts[0] = format(parts[0].replace('+', '').trim());
        leg = parts[0] + '+';
      }
    }
    formattingLegends.push(leg);
  }

  const datacomparison = MapService.getComparisonOptions();
  let formattingLegendsComp = options.maptype !== 'state' ? []: [];
  let legendcolorsArray2 = [];
  if (datacomparison) {
    // console.log('datacomparison', datacomparison);
    const selectedComparator = MapService.search(options);
    if (selectedComparator && selectedComparator.length > 0) {
      let legend2 = selectedComparator[0]['legendvalues'];
      let colors2 = selectedComparator[0]['legendcolors'];
      legendcolorsArray2 = legendcolorsArray2.concat(colorsService.transformLegendColors(colors2, options.maptype, undefined, undefined, isDC,undefined,undefined,options));
      const legends2 = legend2.split(',');
      
      for (let leg2 of legends2) {
        if (leg2 && !leg2.includes('dopted') && !leg2.includes('crimi') && !leg2.includes('nhance') && !leg2.includes('cesute') && !leg2.includes('secute') ) {
          
          let parts2 = leg2.split('-');
          if (parts2.length > 1) {
            leg2 = format(parts2[0].trim()) + ' - ' + format(parts2[1].trim());
          } else {
            parts2[0] = format(parts2[0].replace('+', '').trim());
            leg2 = parts2[0] + '+';
          }
        }
        formattingLegendsComp.push(leg2);
      }
    }
  }
  let width = window.innerWidth;
  let width2 = window.innerWidth;
  let totalAvailablePercentage = 100;
  if (width > 2200) {
    totalAvailablePercentage = 60;
  }
  if (formattingLegends.length) {
    if (isDC) {
      const numberOfLegends = formattingLegends.length + (formattingLegends.length % 2);
      let multiplier = (numberOfLegends > 5 ? 2: 1);
      multiplier = numberOfLegends == 4 ? 2: multiplier;
      width = (totalAvailablePercentage / numberOfLegends * multiplier);
    } else {
      
      width = (totalAvailablePercentage / formattingLegends.length);
    }
  } else {
    width = 0;
  }
  if (formattingLegendsComp.length) {
    if (isDC) {
      let multiplier = formattingLegendsComp.length > 5 ? 2: 1;
      multiplier = formattingLegendsComp.length == 4 ? 2: multiplier;
      width2 = (totalAvailablePercentage / formattingLegendsComp.length * multiplier);
    } else {
      width2 = (totalAvailablePercentage / formattingLegendsComp.length);
    }
  } else {
    width2 = 0;
  }

  // width = parseInt(width);
  width += '%';
  width2 = parseInt(width2);
  width2 += '%';

  return (
    <>
    { showFooter && 
    !hasParams && <BottomToolbar title={title}/> }
    { showFooter &&
    <footer className={(isMobile && isDC?'new-scala':'') }>

      <div className="row no-gutters ">
        <div className={isDC ? 'col-6' : 'col-12'}>
          <div className={"escala-container " +  (isDC ? 'escala-02' : (hasParams && isMobile?'escala-new escala-frame':'escala-new'))} >
            {
              (!isDC && !hasParams) &&
              <div className="header-map-new">
                <h6>{title}</h6>
              </div>
            }
            <div className="escala-table">
            {
              formattingLegends.map((fl, i) => (
                <div key={'legend-' + i} style={{ display: 'flex', width: width, background: legendcolorsArray[i] ? legendcolorsArray[i] : '#000000', color: getColor(legendcolorsArray[i]), fontWeight: 'bold' }}>
                  <span style={{ margin: 'auto' }}> {fl} </span>
                </div>
              ))
            }
           </div>
          </div>
        </div>
        {
          isDC &&
          <div className='col-6'>
            <div className={"escala-container escala-02"} >
            {
              formattingLegendsComp.map((fl, i) => (
                <div key={'legendc-' + i} style={{ display: 'flex', width: width2, background: legendcolorsArray2[i] ? legendcolorsArray2[i] : '#000000', color: getColor(legendcolorsArray2[i]), fontWeight: 'bold' }}>
                  <span style={{ margin: 'auto' }}> {fl} </span>
                </div>
              ))
            }
            </div>
          </div>
        }
      </div>
    </footer>}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options,
    isDC: state.options.datacomparison.selected && state.options.datacomparison.selected !== '' && state.options.datacomparison.selected !== 'none'
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
