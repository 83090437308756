
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadCities } from '../services/cityService';
import { defaultOptions } from './../defaultOptions';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { search } from './../services/dataComparisonService';
import { statesInScreen, searchState, citiesInScreen, searchCitiesOnStateProviders } from './../services/localStatisticsService';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import classNames from 'classnames';


const FloatingButtons = ({ options, zoomin, zoomout, bounds, zoom, isCollapsed, stateLocalStatisticsArray, updateState, comparisonOpt, hasParams }) => {
  const [showReturnButton, setShowReturnButton] = useState(false);
  const [showViewCityMode, setShowViewCityMode] = useState(false);
  const [showLocalStatics, setShowLocalStatistics] = useState(false);
  const [cityLocalStatisticsArray, setCityLocalStatisticsArray] = useState([]);
  const [dropdownOpenStatistics, setDropdownOpenStatistics] = useState(false);
  const [dropdownOpenCities, setDropdownOpenCities] = useState(false);
  const [dropdownSelectYear, setDropdownSelectYear] = useState(false); // ESTO es la variable para que este abierto o cerrado
  const [stateLocalStatisticsDropdown, setStateLocalStatisticsDropdown] = useState([]);
  const localStatisticsZoomLimit = 4.6;
  const searchCitiesOnState = (state) => {
    return searchCitiesOnStateProviders(state, cityLocalStatisticsArray, stateLocalStatisticsArray);
  }
  const loadCityMode = (selCity) => {
    loadCities().then((cities) => {
      let cityname = '';
      cities.forEach(({ city }) => {
        let mycity = city.replace(/ /g, '');
        mycity = mycity.toLowerCase();
        if (mycity.includes(selCity.toLowerCase()) || selCity.toLowerCase().includes(mycity)) {
          cityname = city;
        }
      });
      let newOptions = defaultOptions;
      newOptions.maptype = 'city';
      newOptions.geographytype['selected'] = 'zipcode';
      newOptions.city = cityname;
      updateState(newOptions);
    })
  }
  const [isMobile, setIsMobile]  = useState(false);
  useEffect(() => {
    setShowLocalStatistics(zoom >= localStatisticsZoomLimit);
    setShowViewCityMode(zoom >= localStatisticsZoomLimit);
    if (zoom >= localStatisticsZoomLimit) {
      citiesInScreen(bounds).then((d) => {
        let cities = d.data.rows;
        setCityLocalStatisticsArray(cities);
      })
      statesInScreen(bounds).then((d) => {
        let states = d.data.rows;
        let newStateDropdownValues = [];
        states.forEach(state => {
          state.name = state.name.replace(/\./g, '');
          const provider = searchState(stateLocalStatisticsArray, state.name);
          if (provider['state']) {
            newStateDropdownValues.push(provider);
          }
        });
        newStateDropdownValues.sort((a, b) => {
          return a.state.toLowerCase().localeCompare(b.state.toLowerCase());
        });
        setStateLocalStatisticsDropdown(newStateDropdownValues);
      });

    }

  }, [zoom]);
  useEffect(()=>{
    const changeMobile  = window.innerWidth < 900 ? true: false;
    setIsMobile(changeMobile);
  },[]);
  const getDataComparisonTitle = () => {
    if (options.datacomparison['selected'] !== 'none' && options.datacomparison['selected'] !== '') {
      let value = search(options, comparisonOpt)[0];
      if(value) {
        return value.maptitle;
      } else {
        return '';
      }

    } else {
      return '';
    }
  }

  const showDropdownCity = (showViewCityMode && cityLocalStatisticsArray.length && options.maptype !== 'city' && options.datatype !== 'PrEP');

  return (
    <div className={"row no-gutters " + ((options.datacomparison.selected == 'none' || options.datacomparison.selected == '') ? 'row-postion1 ' : 'row-postion2 ')  + (isMobile?'title-mobile':'')} >
      { (options.datacomparison.selected !== 'none' && options.datacomparison.selected !== '') &&
        <div className={"header-map title-map-comparison " + (isCollapsed ? 'collapsed-titles' : '')} style={{ textAlign: 'center' }} id="header-mainmaptitle"  >
          <h6>{options.mainmaptitle}</h6>
        </div>
      }
      { (options.datacomparison.selected !== 'none' && options.datacomparison.selected !== '') &&
        <div className={"header-map title-map-comparison " + (isCollapsed ? 'collapsed-titles' : '')} style={{ textAlign: 'center', left: '50%' }} >
          <h6>{getDataComparisonTitle()}</h6>
        </div>
      }
      {
        showReturnButton &&
        <div className="col-xl-3 col-md-3 col-btn-next">
          <button type="button" className="btn btn-view" ><i className="mdi mdi-city"></i> Return </button>
        </div>
      }
      <div className={classNames({
          'map-buttons': true, 'text-right': true,
          'map-buttons-arrows': isCollapsed,
          // 'col-xl-6': showDropdownCity,
          // 'col-md-7': showDropdownCity,
          // 'col-xl-4': !showDropdownCity,
          // 'col-md-4': !showDropdownCity,
        })} >
        {
          (showDropdownCity && !hasParams) &&
          <Dropdown className="btn-group map-btn" isOpen={dropdownOpenCities} toggle={() => setDropdownOpenCities(!dropdownOpenCities)}>
            <DropdownToggle caret className="btn dropdown-toggle dp-map">
              VIEW CITY MODE &nbsp;
                </DropdownToggle>
            <DropdownMenu>
              {
                cityLocalStatisticsArray.map((city) => (
                  <DropdownItem key={'di-' + city.city} onClick={() => loadCityMode(city.cityname)}>
                    {city.display}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </Dropdown>
        }
        {
          (showLocalStatics && options.datatype != 'PrEP' && !hasParams) &&

          <Dropdown className="btn-group map-btn" isOpen={dropdownOpenStatistics} toggle={() => setDropdownOpenStatistics(!dropdownOpenStatistics)}>
            <DropdownToggle caret className="btn dropdown-toggle dp-map">
              <img src="/assets/icon-01.svg" alt="" width="18px;" /> &nbsp;&nbsp; VIEW LOCAL STATISTICS &nbsp;
                </DropdownToggle>
            <DropdownMenu>
              {
                stateLocalStatisticsDropdown.map((provider) => {

                  let array = searchCitiesOnState(provider.state);
                  let arrayItems = [];
                  array.map((cityprovider) => {
                    arrayItems.push(
                      <DropdownItem key={'di-' + cityprovider.city} className="li-style">
                        <a className="dropdown-item" href={cityprovider.link} target="_blank">{cityprovider.city}</a>
                      </DropdownItem>
                    )
                  });
                  var item = <DropdownItem key={'di-' + provider.state} >
                    <a className="dropdown-item" href={provider.link} target="_blank">{provider.state}</a>
                    {arrayItems}
                  </DropdownItem>
                  return item;
                }
                )
              }
            </DropdownMenu>
          </Dropdown>
        }

        {
          !hasParams &&
          <div className="search-container geocoder" id="geocoder"></div>
        }
        {
          !hasParams &&
        <div className="btn-group btn-group-map" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-map" style={{paddingTop: '4px'}} onClick={zoomin}><i className="mdi mdi-minus center" ></i></button>
          <button type="button" className="btn btn-map btn-line" style={{paddingTop: '4px'}}  onClick={zoomout}><i className="mdi mdi-plus center"></i></button>
        </div>
        }
        {/* <div className="btn-year-map">
          <Dropdown isOpen={dropdownSelectYear} toggle={() => setDropdownSelectYear(!dropdownSelectYear)}>
            <DropdownToggle caret className="dropdown-toggle">
              Select Comparison Year <i className="mdi mdi-menu-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <a className="dropdown-item"> 2017</a>
              <a className="dropdown-item"> 2018</a>
              <a className="dropdown-item"> 2019</a>
              <a className="dropdown-item"> 2020</a>
              <a className="dropdown-item"> 2021</a>
            </DropdownMenu>
          </Dropdown>
        </div> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (options) => {
      dispatch({ type: 'REPLACE_OPTIONS_UPDATE', options })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FloatingButtons)
