import {defaultOptions} from './../defaultOptions';
import {CartoLayer, MAP_TYPES} from '@deck.gl/carto';
import {CARTO} from './../settings';
export const updateFilters = (newValues) => {
  let opt = defaultOptions;
  if(newValues){
  opt.city = newValues.city?newValues.city:'';
  opt.maptype = newValues.maptype?newValues.maptype:'';
  opt.datatype = newValues.datatype?newValues.datatype:'';
  opt.mainmaptable = newValues.mainmaptable?newValues.mainmaptable:'';
  opt.mainmaplegendvariable = newValues.mainmaplegendvariable?newValues.mainmaplegendvariable:'';
  opt.mainmapvariable = newValues.mainmapvariable?newValues.mainmapvariable:'';
  opt.legendvalues = newValues.legendvalues?newValues.legendvalues:'';
  opt.legendcolors = newValues.legendcolors?newValues.legendcolors:'';
  opt.mainmaptitle = newValues.mainmaptitle?newValues.mainmaptitle:'';
  opt.footnotes = newValues.footnotes?newValues.footnotes:'';
  opt.overall = newValues.selected.includes('overall');
  opt.hasYear = newValues.year !== 'hidden';
  // if (opt.hasYear) {
    opt.year['selected'] = newValues.year;
  // }
  opt.geographytype['selected'] = newValues.selected?newValues.selected.filter(ele => opt.geographytype['geographytype'].includes(ele)):'';
  opt.geographytype['available'] = newValues.available?newValues.available.filter(ele => opt.geographytype['geographytype'].includes(ele)):'';
  opt.geographytype['unavailable'] = newValues.unavailable?newValues.unavailable.filter(ele => opt.geographytype['geographytype'].includes(ele)):'';
  opt.geographytype['hidden'] = newValues.hidden?newValues.hidden.filter(ele => opt.geographytype['geographytype'].includes(ele)):'';
  opt.geographytype['selected'] = opt.geographytype['selected'].concat(newValues.autoselect?newValues.autoselect.filter(ele => opt.geographytype['geographytype'].includes(ele)):'');
  opt.age['selected'] = newValues.selected?newValues.selected.filter(ele => ele.includes('age')):'';
  opt.age['selected'] = opt.age['selected'].concat(newValues.autoselect?newValues.autoselect.filter(ele => ele.includes('age')):'');
  opt.age['available'] = newValues.available?newValues.available.filter(ele => ele.includes('age')):'';
  opt.age['unavailable'] = newValues.unavailable?newValues.unavailable.filter(ele => ele.includes('age')):'';
  opt.age['hidden'] = newValues.hidden?newValues.hidden.filter(ele => ele.includes('age')):'';
  opt.rateslabel = newValues.rateslabel;
  opt.caseslabel = newValues.caseslabel;
  opt.additionalrateslabel = newValues.additionalrateslabel;
  opt.ratescases['selected'] = newValues.ratescases;
  opt.race['selected'] = newValues.selected?newValues.selected.filter(ele => opt.race['race'].includes(ele) || ele === 'race'):'';
  opt.race['selected'] = opt.race['selected'].concat(newValues.autoselect?newValues.autoselect.filter(ele => opt.race['race'].includes(ele)  || ele === 'race'):'');
  opt.race['available'] = newValues.available?newValues.available.filter(ele => opt.race['race'].includes(ele)  || ele === 'race'):'';
  opt.race['unavailable'] = newValues.unavailable?newValues.unavailable.filter(ele => opt.race['race'].includes(ele)  || ele === 'race'):'';
  opt.race['hidden'] = newValues.hidden?newValues.hidden.filter(ele => opt.race['race'].includes(ele)  || ele === 'race'):'';
  opt.sex['selected'] = newValues.selected?newValues.selected.filter(ele => ele === 'male' || ele === 'female' || ele === 'sex'):'';
  opt.sex['selected'] = opt.sex['selected'].concat(newValues.autoselect?newValues.autoselect.filter(ele => ele === 'male' || ele === 'female' || ele === 'sex'):'' );
  opt.sex['unavailable'] = newValues.unavailable?newValues.unavailable.filter(ele => ele === 'male' || ele === 'female' || ele === 'sex'):'';
  opt.sex['hidden'] = newValues.hidden?newValues.hidden.filter(ele => ele === 'male' || ele === 'female' || ele === 'sex'):'';
  opt.transcat['selected'] = newValues.selected?newValues.selected.filter(ele => opt.transcat['transcat'].includes(ele) || ele === 'transcat'):'';
  opt.transcat['selected'] = opt.transcat['selected'].concat(newValues.autoselect?newValues.autoselect.filter(ele => opt.transcat['transcat'].includes(ele)  || ele === 'transcat'):'');
  opt.transcat['available'] = newValues.available?newValues.available.filter(ele => opt.transcat['transcat'].includes(ele) || ele === 'transcat'):'';
  opt.transcat['unavailable'] = newValues.unavailable?newValues.unavailable.filter(ele => opt.transcat['transcat'].includes(ele) || ele === 'transcat'):'';
  opt.transcat['hidden'] = newValues.hidden?newValues.hidden.filter(ele => opt.transcat['transcat'].includes(ele) || ele === 'transcat'):'';
  opt.datacomparison['selected'] = newValues.selected?newValues.selected.filter(ele => opt.datacomparison['datacomparison'].includes(ele) || ele === 'datacomparison'):'';
  opt.datacomparison['selected'] = opt.datacomparison['selected'].concat(newValues.autoselect?newValues.autoselect.filter(ele => opt.datacomparison['datacomparison'].includes(ele) || ele === 'datacomparison'):'');
  opt.datacomparison['available'] = newValues.available?newValues.available.filter(ele => opt.datacomparison['datacomparison'].includes(ele) || ele === 'datacomparison'):'';
  opt.datacomparison['unavailable'] = newValues.unavailable?newValues.unavailable.filter(ele => opt.datacomparison['datacomparison'].includes(ele) || ele === 'datacomparison'):'';
  opt.datacomparison['hidden'] = newValues.hidden?newValues.hidden.filter(ele => opt.datacomparison['datacomparison'].includes(ele) || ele === 'datacomparison'):'';
  opt.age['selected'] = opt.age['selected'].length ? opt.age['selected'][0] : 'none';
  opt.race['selected'] = opt.race['selected'].length ? opt.race['selected'][0] : 'none';
  opt.sex['selected'] = opt.sex['selected'].length ? opt.sex['selected'][0] : 'none';
  opt.transcat['selected'] = opt.transcat['selected'].length ? opt.transcat['selected'][0] : 'none';
  opt.datacomparison['selected'] = opt.datacomparison['selected'].length ?
  opt.datacomparison['selected'][0] : 'none';
  opt.geographytype['selected'] = opt.geographytype['selected'].length ?
  opt.geographytype['selected'][0] : 'none';
  opt.indicators = newValues.indicators?newValues.indicators:'';
  opt.areatype = newValues.areatype?newValues.areatype:'all';
  }
  return opt;
}
export const getCityQuery = (stateOptions) => {
  let city = stateOptions.city;
  city = city.replace(/oakland\/alameda/g, 'oakland');
  const geotype = stateOptions.geographytype['selected'];
  let q = '';
  if (geotype === 'zipcode') {
    // tslint:disable-next-line:max-line-length
    q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%' )`;
    if (stateOptions.city === 'jackson') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jackson')`;
    }
    if (stateOptions.city === 'jacksonville') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jacksonville')`;
    }
    // q = encodeURI(q);
  } else if (geotype === 'censustract') {
    // tslint:disable-next-line:max-line-length
    q = `SELECT * FROM ${CARTO.CITY_CENSUS} WHERE (LOWER(REPLACE(cityid, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(cityid, ' ', ''))||'%' )`;
    if (stateOptions.city === 'Jackson') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jackson')`;
    }
    if (stateOptions.city === 'Jacksonville') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jacksonville')`;
    }
    // q = encodeURI(q);
  } else if (geotype === 'communityarea') {
    q = `SELECT * FROM ${CARTO.CITY_COMMUNITY}`;
  } else if (geotype === 'ward') {
    q = `SELECT * FROM ${CARTO.CITY_WARD}`;
  }
  return q;
}

export  const getCentroidsLayers = (city, clickCentroids) => {
  let wherePart = '';
  if(city && city != ''){
    wherePart = ` WHERE cityname != '${city}'`
  } 
  let query =  `SELECT * from ${CARTO.CITIES_CENTROIDS} ${wherePart}`;
  let newLayer = new CartoLayer({
    type: MAP_TYPES.QUERY,
    pickable: true,
    id: 'centroids',
    data: query,    
    getLineColor: [223, 221, 221],
    getFillColor: [178, 44, 41, 220],
    pointRadiusMinPixels: 7,
    pointRadiusMaxPixels: 7,
    // radiusScale: 100,
    lineWidthMinPixels: 1,
    getRadius: 40000,
    pickable: true,
    onClick: clickCentroids
  });
  return newLayer;
}