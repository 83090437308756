export function transformLegendColors (legend, indicator, casetype, caselabel, isDC, datatype, rateslabel, options) {
  const splittedColors = legend.split(',');
  let aux;
  const res = new Array();
  // Convert values of legendcolors to an array with
  // rgba(155,21,132,0.45)
  for (const entry of splittedColors){
    const rgb = entry.split('-');
    aux = 'rgba(';
    for (let i = 1; i < 4; i++) {
      aux += rgb[i] + ',';
    }
    aux += transformOpacity(rgb[0]) + ')';
    res.push(aux);
  }
  for (let i = 0; i < 4; ++i) {
    res.shift();
  }
  if (isDC) {
    // if (indicator === 'city') {
    //   const lastElemGray = 'rgba(200,200,200,255)';
    //   res.unshift(lastElemGray);
    // } else {
    //   const lastElemGray = 'rgba(200,200,200,255)';
    //   res.pop();
    //   res.reverse();
    //   res.push(lastElemGray);
    //   res.reverse();  
    // }
  } else if (indicator === 'city' ) {
    if (
      (options.datatype === 'prevalence' && options.ratescases.selected === 'cases') 
      ||
      (options.datatype === 'newdx' && (options.rateslabel === 'Rates' || options.rateslabel === 'Risk'))
      || (
        (options.datatype === "linkage" || options.datatype === "receipt" || options.datatype === "viralsuppression")
        && 
        (options.caseslabel === 'Cases' && options.ratescases.selected === 'cases')
      )
      || (
        (options.datatype === 'latedx') && options.rateslabel === '% Cases'
      )
    ) {
      const lastElemGray = 'rgba(200,200,200,255)';
      // res.pop();
      res.reverse();
      res.push(lastElemGray);
      res.reverse();
    }
  }else if (
      (datatype !== "linkage" && datatype !== "receipt" && datatype !== "viralsuppression")
      &&
      ((indicator === 'county' && casetype === 'cases')
      ||
      (datatype === 'newdx' && rateslabel === 'Rates')
      ||
      (datatype === 'newdx' && options.ratescases.selected === 'additionalrates' && options.additionalrateslabel === 'Rates' ))
  ) {
    if (caselabel === 'Users') {
      res.unshift('rgba(200,200,200,255)');
    } else {
      const lastElemGray = 'rgba(200,200,200,255)';
      res.pop();
      res.reverse();
      res.push(lastElemGray);
      res.reverse();
    }
    // console.log('splittedcolors', splittedColors, 'res', res);
    // if ((options.maptype != 'state' && options.ratescases.selected === 'cases' && (options.caseslabel === 'Cases' || options.caseslabel === 'Users'))) {
      
    // }
  } else if ((indicator === 'county' && casetype === 'rates')) {
    if (res[res.length-1].includes('220,220,220')) {
      res.pop();
    }
  }
  return res;
}
export function transformLegendColorsToHex(legend) {
  
  const splittedColors = legend.split(',');
  let aux;
  const res = new Array();
  // Convert values of legendcolors to an array with
  // rgba(155,21,132,0.45)
  for (const entry of splittedColors){
    const rgb = entry.split('-');
    aux = '#';
    for (let i = 1; i < 4; i++) {
      let color = (+rgb[i]).toString(16);
      if (color.length === 1) {
        color = '0' + color;
      }
      aux += color;
    }
    const opacity = transformOpacity(rgb[0]);
    const opacity255 = (Number(rgb[0]) == 255 || Number(rgb[0]) == 0)?230:Number(rgb[0]); 
    res.push({color: aux, opacity: opacity, colorB:[Number(rgb[1]),Number(rgb[2]),Number(rgb[3]), opacity255 ]});
  }
  // console.log('Original lenge', legend, 'Transformed', res);
  return res;
}
/*
  Transform opacity to 0 to 1
*/
export function transformOpacity(value) {
  // return 1;
  return (value == 255 || value == 0) ? 0.9 : value / 255;
}
/*
  input:  color is in format rgba(255,255,255,0.2)
  tranforms it to an array to get the colors independent 
  and check the luminosity of the color
  output: white or black in hex
*/
export function getColor(color) {
  if (!color) {
    return '#000000';
  }
  /*let r:number = (i*8*4);
  return "rgb("+r+","+r+","+r+")";
*/
  // checks the luminosity of background
  const c = color.substring(5, color.length - 1);
  const vals = c.split(',');
  const r = parseInt(vals[0]);
  const g = parseInt(vals[1]);
  const b = parseInt(vals[2]);
  const op = parseFloat(vals[3]);
  if ((r * 0.299 + g * 0.587 + b * 0.114)  > 150) {
    return '#000000';
  } else {
    return '#ffffff';
  }

}