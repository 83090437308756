import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

const DatasetDropdown = ({options, changeDatatype}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const originalItems = [
    { label: 'Prevalence', key: 'prevalence', ind: 'prev' },
    { label: 'New Diagnoses', key: 'newdx', ind: 'newdx' },
    { label: 'PrEP', key: 'PrEP', ind: 'prep' },
    { label: 'PnR', key: 'PNR', ind: 'pnr' },
    { label: 'Linkage to Care', key: 'linkage', ind: 'link' },
    { label: 'Receipt of Care', key: 'receipt', ind: 'receipt' },
    { label: 'Viral Suppression', key: 'viralsuppression' , ind: 'viral'},
  ];
  const originalItemsCity = [
    { label: 'Prevalence', key: 'prevalence', ind: 'prev' },
    { label: 'New Diagnoses', key: 'newdx', ind: 'newdx' },
    { label: 'Late Diagnoses', key: 'latedx', ind:'latedx' },
    { label: 'Linkage to Care', key: 'linkage', ind: 'link' },
    { label: 'Receipt of Care', key: 'receipt', ind: 'receipt' },
    { label: 'Viral Suppression', key: 'viralsuppression' , ind: 'viral'}
  ]
  const [items, setItems] = useState(originalItems);
  const [dataset, setDataset] = useState(items[0]);
  const changeDataSet = (value)=> {
    setDataset(value);
    changeDatatype(value.key);
  }
  useEffect(()=>{
    setTimeout(() => {
      let item = items.find( element => element.key === options.datatype);
      if(item) {
        setDataset(item);
      }
    },400);
  },[options.datatype]);
  useEffect(()=>{
    setDataset({ label: 'Prevalence', key: 'prevalence', ind: 'prev' });
    switch(options.maptype) {
      case 'county': 
        return  setItems([
          { label: 'Prevalence', key: 'prevalence', ind: 'prev' },
          { label: 'New Diagnoses', key: 'newdx', ind: 'newdx' },
          { label: 'PrEP', key: 'PrEP', ind: 'prep' },
          { label: 'PnR', key: 'PNR', ind: 'pnr' },
          { label: 'Linkage to Care', key: 'linkage', ind: 'link' },
          { label: 'Receipt of Care', key: 'receipt', ind: 'receipt' },
          { label: 'Viral Suppression', key: 'viralsuppression' , ind: 'viral'},
        ])
      case 'state':
        return setItems([
          { label: 'Prevalence', key: 'prevalence', ind: 'prev' },
          { label: 'New Diagnoses', key: 'newdx', ind: 'newdx' },
          { label: 'Mortality', key: 'death' },
          { label: 'PrEP', key: 'PrEP', ind: 'prep' },
          { label: 'PnR', key: 'PNR', ind: 'pnr' },
          { label: 'Testing', key: 'testing' },
          { label: 'Late Diagnoses', key: 'latedx', ind:'latedx' },
          { label: 'Linkage to Care', key: 'linkage', ind: 'link' },
          { label: 'Receipt of Care', key: 'receipt', ind: 'receipt' },
          { label: 'Viral Suppression', key: 'viralsuppression' , ind: 'viral'},
        ])
      case 'city':
        return  setItems(originalItemsCity);

    }
    
  },[options.maptype])
  useEffect(()=>{
    if(options.maptype == 'city' && options.indicators) {
      let newItems = [];
      for(const value of originalItemsCity){
        if(options.indicators.includes(value.ind)) {
          newItems.push(value);
        }
      }
      setItems(newItems);
      setDataset({ label: 'Prevalence', key: 'prevalence', ind: 'prev' });
    }
  },[options.indicators]);
  return (
    <Dropdown className="btn-group mr-4" isOpen={dropdownOpen} id="select-dataset" toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle caret>
        <small>{dataset.label}</small>
        <i className="mdi mdi-menu-down icon-lg "></i>
      </DropdownToggle>
      <DropdownMenu id="select-datasetmenu">
        {
          items.map(({ label, key }) => (
            <DropdownItem key={ 'di-' + key } id={'di'+key} onClick={() => changeDataSet({ label, key })}>
              {label}
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeDatatype: (value) => {
      dispatch({ type: 'CHANGE_VALUE', key:'datatype', value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetDropdown)