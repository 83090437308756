import axios from 'axios';
import {SERVER, CARTO} from '../settings';
import {environment} from '../environments/environment';

const SERVER_URL = SERVER.BASE_URL;
let lastFilterQuery = null;
let filterCached = null;
export async function asyncGetCountyFilters(query) {
  // console.log("ASKING Data Filters", query);
  let areEqual = false;
  if (lastFilterQuery) {
    areEqual = JSON.stringify(lastFilterQuery) === JSON.stringify(query);
  }
  lastFilterQuery = JSON.parse(JSON.stringify(query));
  if (areEqual) {
    return filterCached;
  }

  if (typeof query.selected != "string") {
    query.selected = query.selected.sort().join('|');
  }

  let filterResponse = await axios.post(SERVER.COUNTY_FILTERS, query);
  
  let data = filterResponse.data;
    if( data.selected ) {
      data.selected = data.selected?data.selected.split('|'):'';
      data.hidden = data.hidden?data.hidden.split('|'):'';
      data.autoselect = data.autoselect?data.autoselect.split('|'):'';
      data.unavailable = data.unavailable?data.unavailable.split('|'):'';
      data.available = data.available?data.available.split('|'):'';
    } 
      
  filterCached = data;
  return filterCached;
}

export async function getValuesOfTablesCounty(mainmaptable, mainmapvariable, mainmaplegendvariable, mainmaptable2, year) {
  const mainmapvariableyn = mainmapvariable.replace('rate', '').replace('case', '').replace('count', '') + 'yn';
  if (!mainmaptable2 || mainmaptable2 === 'none') {
    let query;
    if (year !== 'hidden') {
      // tslint:disable-next-line:max-line-length
      query = CARTO.GET_QUERY(`SELECT t1.*, t1.${mainmapvariable} , t1.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} t1 WHERE year=${year}`);
    } else {
      query = CARTO.GET_QUERY(`SELECT t1.*, t1.${mainmapvariable} , t1.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} t1`);
    }
    let response = await axios.get(query);
    return response.data.rows;
  } else {
    let query;
    if (year !== 'hidden') {
      // tslint:disable-next-line:max-line-length
      query = CARTO.GET_QUERY(`SELECT mt.*, mt.${mainmapvariable} , mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.countyname=mt2.countyname and mt2.year=${year} and mt.year=mt2.year`);
    } else {
      // tslint:disable-next-line:max-line-length
      query = CARTO.GET_QUERY(`SELECT mt.*, mt.${mainmapvariable} , mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.countyname=mt2.countyname`);
    }
    let response = await axios.get(query);
    return response.data.rows;
  }
}
const search = (options, comparisonOptions) => {
  if(comparisonOptions) {
    const ans = comparisonOptions.filter(ele => {
      if (options.maptype === 'city') {
        // tslint:disable-next-line:max-line-length
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
      } else {
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
      }
    });
    return ans;
  } else {
    return [];
  }
  
}
export async function dataComparisonCounty(options, comparisonOptions) {
  if (options.datacomparison.selected !== 'none') {
    const comparer = search(options, comparisonOptions)[0];
    console.log('Comparerr', comparer);
    if(comparer) {
      const query = CARTO.GET_QUERY(`SELECT ${comparer.mapvariable} as value, ${comparer.maplegendvariable} as legendPos, geo_id2, countyname, year FROM ` + CARTO.COUNTY_COMPARER);
      let response = await axios.get(query);
      return response.data.rows;
    } else {
      return [];
    }
    
  }
}

export function getCountyPopup() {
  return axios.get(SERVER.COUNTY_POPUPS);
}

