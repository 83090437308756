const initialState = {
  city: '',
  hasYear: false,
  year: {
    year: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  ratescases: {
    ratescases: ['rates', 'cases', 'additionalrates'],
    labels: ['Rates', 'Cases', 'RATE'],
    selected: 'rates',
    available: [],
    hidden: [],
    unavailable: []
  },
  rateslabel: '',
  caseslabel: '',
  additionalrateslabel: '',
  overall: false,
  age: {
    age: ['agele24', 'age1324','age1824', 'age2534', 'age2544', 'age3544', 'age4554', 'age4559','age5564' , 'age55over', 'age60over','age65over'],
    labels: ['24 and under', '13 to 24', '18 to 24' , '25 to 34', '25 to 44', '35 to 44', '45 to 54', '45 to 59','55 to 64', '55+', '60+', '65+'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  race: {
    race: ['aian', 'asian', 'black', 'hispanic', 'nhpi', 'multirace', 'white'],
    labels: ['American Indian/Alaska Native', 'Asian', 'Black', 'Hispanic',
    'Native Hawaiian/Pacific Islander', 'Multiple Races', 'White'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  sex: {
    sex: ['male', 'female'],
    labels: ['Male', 'Female'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  transcat: {
    transcat: ['msm', 'msmidu', 'idu', 'heterosexual', 'other'],
    labels: ['Male-to-Male Sexual Contact', 'Male-to-Male Sexual Contact / Injection Drug Use',
    'Injection Drug Use', 'Heterosexual Contact', 'Other'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  datacomparison: {
    datacomparison: ['poverty', 'hseducation', 'medianincome', 'incinequality', 'nohealthinsurance'],
    labels: ['Poverty', 'High School Education', 'Median Household Income', 'Income Inequality', 'People without Health Insurance'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  geographytype: {
    geographytype: ['zipcode', 'censustract', 'communityarea', 'neighborhood', 'ward', 'none'],
    labels: ['Zip Code', 'Census Tract', 'Community Area', 'Neighborhood', 'Ward', 'none'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  datatype: 'prevalence',
  datatypecontrol: 'available',
  mainmaptable: '',
  mainmaptable2: '',
  mainmapvariable: '',
  mainmaplegendvariable: '',
  legendvalues: '',
  legendcolors: '',
  mainmaptitle: '',
  footnotes: '',
  maptype: 'county',
  areaType: {value:'all', name:'All Counties'},
  update: true,
  latestValue: '',
  areatype: 'all',
  slider: 100
};

const optionsReducer = (state = initialState , action) => {
  switch(action.type) {
    case 'CHANGE_VALUE': 
      if(action.key === 'maptype') {
        return {
          ...initialState,
          [action.key]: action.value,
          update: true,
          latestValue: action.key
        }
      } if (action.key === 'datatype') {
        console.log('inital state year', initialState.year);
        return {
          ...state, 
          [action.key]: action.value,
          update: true,
          latestValue: action.key,
          hasYear: false,
          year: initialState.year 
        }
      } else {
        return {
          ...state, 
          [action.key]: action.value,
          update: true,
          latestValue: action.key
        }
      }
    case 'CHANGE_SELECTED': 
      return {
        ...state, 
        [action.key]: {
          ...state[action.key],
          selected: action.value
        },
        update: true,
        latestValue: action.key
      }
    case 'REPLACE_OPTIONS':
      return {
        ...action.options,
        update: false,
        checker: Math.random()
      }
    case 'REPLACE_OPTIONS_UPDATE':
      return {
        ...action.options,
        update: true,
        checker: Math.random()
      }  
    case 'SELECT_CITY':
      return {
        ...initialState,
        update: true, 
        latestValue: 'city',
        maptype: 'city',
        city: action.city,
        geographytype: {
          ...state['geographytype'],
          selected: 'zipcode'
        },
        overall:true,
      }
    case 'CHANGE_SLIDER': {
      return {
        ...state, 
        slider: action.value,
        update: true,
        latestValue: 'slider'
      }
    }
    case 'RESET_FILTER':
      let year = state.year['selected'];
      let resetState = {
        ...initialState, 
        maptype: state.maptype,
        ratescases: state.ratescases,
        rateslabel: state.rateslabel,
        caseslabel: state.caseslabel,
        city: state.city,
        geographytype: state.geographytype,
        year: state.year,
        datatype: state.datatype,
        latestValue: 'reset'
      }
      if (year !== 'none') {
        resetState.year['selected'] = year;
        resetState.hasYear = true;
      } 
      return  resetState;
    default: 
      return state;
  }
}

export default optionsReducer;
