import axios from 'axios';

import { CARTO, SERVER } from '../settings';

let comparisonOptions;
let drawBounding;
const MapService = () => {

  const setDrawBounding = (draw) => {
    drawBounding = draw;
  }

  const getMap = (mapconfig) => {
    return axios.post(`https://${CARTO.USERNAME}.carto.com/api/v1/map`, mapconfig);
  }

  const getCartoQuery = (query) => {
    return new Promise((resolve, reject) => {
      const queryC = CARTO.GET_QUERY(query);
      return axios.get(encodeURI(queryC))
        .then(res => {
          resolve(res.data.rows);
        })
        .catch(reject);
    })
  }

  const loadComparisonOptions = () => {
    return axios.get(SERVER.GET_DC)
    .then((res) => {
      comparisonOptions = res.data;
    })
  }

  const search = (options) => {
    // console.log('comparisonOptions', comparisonOptions);
    // console.log('options', options);
    // console.log(options.maptype,  options.datacomparison['selected']);
    // console.log("DATA COMPARISON ", comparisonOptions);
    const ans = comparisonOptions.filter(ele => {
      if (options.maptype === 'city') {
        // tslint:disable-next-line:max-line-length
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
      } else {
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
      }
    });
    return ans;
  }

  const dataComparisonGenerics = (options) => {
    if (options.maptype === 'county' && search(options).length !== 0) {
      const comparer = search(options)[0];
      return getCartoQuery(`SELECT ${comparer.mapvariable} as value, ${comparer.maplegendvariable} as legendPos, geo_id2, countyname, year FROM ` + CARTO.COUNTY_COMPARER);
    }
    if (options.maptype === 'state' && search(options).length !== 0) {
      const comparer = search(options)[0];
      return getCartoQuery(`SELECT ${comparer.mapvariable} as value, ${comparer.maplegendvariable} as legendPos, state, year FROM ` + CARTO.STATE_COMPARER);
    }
    // city
    let city = options.city;
    city = city.replace(/oakland\/alameda/g, 'oakland');
    let table = '';
    let zipcensus = '';
    if (options.geographytype['selected'] === 'zipcode') {
      table = CARTO.CITY_ZIP_COMPARER;
      zipcensus = 'zip';
    } else {
      table = CARTO.CITY_CENSUS_COMPARER;
      zipcensus = 'census';
    }

    if (search(options).length !== 0) {
      const comparer = search(options)[0];
      // console.log('comparer', comparer);
      // try encodeURI if fails
      return getCartoQuery(`SELECT ${comparer.mapvariable} as value, ${comparer.maplegendvariable} as legendPos, ${zipcensus} as zipcensus, city, year FROM ${table} WHERE LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%'`);
    }
  }

  loadComparisonOptions();

  return {
    getisDistricts: false,
    getComparisonOptions: () => {
      return comparisonOptions;
    },
    getDrawBounding: () => {
      return drawBounding;
    },
    getMap,
    getBBoxx: () => {},
    getZoom: () => {},
    dataComparisonGenerics,
    search,
    setDrawBounding
  }
}

export default MapService();