import React, { useState } from 'react';
import DownloadDataModal from './DownloadDataModal';
import DownloadMapModal from './DownloadMapModal';
import MoreInformationModal from './MoreInformationModal';
import ShareAidsvuModal from './ShareAidsvuModal';
import { connect } from 'react-redux';
import axios from 'axios';
import {SERVER} from '../settings';
import TutorialPopup from './TutorialPopup';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const loginURL = (username, password) => {
    const body = {
      'user': username,
      'password': password
    };
    const URL = SERVER.LOGIN;
    return axios.post(URL, body);
  }

  const loginCall = () => {
    loginURL(username, password).then( (d) => {
      console.log("data", d);
      window.location.href = 'http://18.218.191.169/upload';
    });
  }
  const visibility = () => {
    const x = document.getElementById('password');
    if (x.type === 'password') {
        x.type = 'text';
    } else {
        x.type = 'password';
    }
  }
  const submitted = (event) => {
    event.preventDefault();
  }
  return (
    <div className="login-dark">
      <div className="bg-login"></div>
      <img src="/assets/logo.svg" alt="" width="160px" className="logo"/>
      <img src="/assets/vu-logo.svg" alt="" width="160px" className="logo-01"/>
        <form onSubmit={ submitted }>
          <h5>Permutations & Messaging File Uploader</h5>
          <div className="form-group">
            <i className="mdi mdi-email form-control-feedback"></i>
            <input className="form-control" type="email" name="email" placeholder="Email" onChange={e => setUsername(e.target.value)}/>
          </div>
          <div className="form-group">
            <div className="input-group mb-3">
              <i className="mdi mdi-lock form-control-feedback"></i>
              <input type="password" id="password" className="form-control" name="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2" onClick={visibility}> <img src="/assets/eye.png" alt="" width="14px"/> </span>
              </div>
            </div>
          </div>
            <button className="btn btn-primary btn-block" onClick={() => loginCall()}>Log In</button>
        </form>
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)