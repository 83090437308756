import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';



const RatesCasesRadio = ({options,changeRatesCases}) => {


  const [items,setItems] = useState([
  ]);
  const [radioSelected, setRadioSelected] = useState('rates');

  const changeRadio = (key) => {
    changeRatesCases(key);
    setRadioSelected(key);
  }
  useEffect(()=>{
    setRadioSelected(options.ratescases.selected);
  },[options.ratescases, items]);
  useEffect(()=>{
    let radios = [];
    if(options.rateslabel != 'hidden'){
      radios.push({
        label: options.rateslabel, key: 'rates'
      })
    }
    if(options.caseslabel != 'hidden'){
      radios.push({
        label: options.caseslabel, key: 'cases'
      })
    }
    if(options.additionalrateslabel != 'hidden'){
      radios.push({
        label: options.additionalrateslabel, key: 'additionalrates'
      })
    }
    setItems(radios);
  },[options])
  return (
    <ButtonGroup style={{top: '4px'}}>
      {

        items.map(({label, key}, i) => (
          label &&
          <label key={key} id={"ratescases"+key} className={"btn btn-primary" + (i === 0 ? ' radio-left' : i === items.length - 1 ? ' radio-right' : '' ) + ' ' + (radioSelected === key ? 'active':'')} onClick={() => changeRadio(key)}>
            {label}
          </label>
        ))
      }
    </ButtonGroup>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeRatesCases: (value) => {
      dispatch({ type: 'CHANGE_SELECTED', key:'ratescases', value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RatesCasesRadio)
