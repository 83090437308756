import axios from 'axios';
import {SERVER, CARTO} from '../settings';
import {environment} from '../environments/environment';

const SERVER_URL = SERVER.BASE_URL;
let lastFilterQuery = null;
let filterCached = null;

export async function asyncGetStateFilters(query) {
  let areEqual = false;
  if (lastFilterQuery) {
    areEqual = JSON.stringify(lastFilterQuery) === JSON.stringify(query);
  }
  lastFilterQuery = JSON.parse(JSON.stringify(query));
  if (areEqual) {
    return filterCached;
  }

  if (typeof query.selected != "string") {
    query.selected = query.selected.sort().join('|');
  }

  let filterResponse = await axios.post(SERVER.STATES_FILTERS, query);
  let data = filterResponse.data;
    if(data.selected) {
      data.selected = data.selected?data.selected.split('|'):'';
      data.hidden = data.hidden?data.hidden.split('|'):'';
      data.autoselect = data.autoselect?data.autoselect.split('|'):'';
      data.unavailable = data.unavailable?data.unavailable.split('|'):'';
      data.available = data.available?data.available.split('|'):'';
    }
      

  filterCached = data;
  return filterCached;
}


export async function getValuesOfTablesState(mainmaptable, mainmapvariable, mainmaplegendvariable, mainmaptable2, year) {
  const mainmapvariableyn = mainmapvariable.replace('rate', '').replace('case', '').replace('count', '') + 'yn';
  if (mainmaptable2 === 'none') {
    let query;
    if (year !== 'hidden') {
      // tslint:disable-next-line:max-line-length
      query = CARTO.GET_QUERY(`SELECT t1.*, t1.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} t1 WHERE year=${year}`);
    } else {
      query = CARTO.GET_QUERY(`SELECT t1.*, t1.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} t1`);
    }
    let response = await axios.get(query);
    return response.data.rows;
  } else {
    let query;
    if (year !== 'hidden') {
      // tslint:disable-next-line:max-line-length
      query = CARTO.GET_QUERY(`SELECT mt.*, mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.state=mt2.state and mt2.year=${year} and mt.year=mt2.year`);
    } else {
      // tslint:disable-next-line:max-line-length
      query = CARTO.GET_QUERY(`SELECT mt.*, mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.state=mt2.state`);
    }
    let response = await axios.get(query);
    return response.data.rows;
  }
}
// export async function getStateCodes() {
//   const query = CARTO.GET_QUERY(`SELECT name, state FROM ${CARTO.STATE_TABLE}`);
//   let response = await axios.get(query);
//   return response.data.rows;
// }
export async function loadStatePrint() {
  const query = CARTO.GET_QUERY('SELECT abbrev, state, region FROM ' + CARTO.STATE_TABLE, false);
  let response = await axios.get(query);
  return response.data.rows;
}
const EXCLUDED_STATES = ['AK', 'HI', 'PR'];
export async function getQuerySearchStateOrRegionPrint(states) {
  let sql = 'SELECT state FROM ' + CARTO.STATE_TABLE;
  let where = [];
  let isNotAll = true;
  for (let state of states) {
    if (state == 'all') {
      isNotAll = false;
    } else if (state.indexOf('region_') >= 0) {
      const region = state.split('_')[1];
      where.push("upper(region) = '" + region.toUpperCase() + "'");
    } else if ( state.includes("North") || state.includes("South") || state.includes("West" ) || state.includes("Midwest") ) {
      const region = state;
      where.push("upper(region) = '" + region.toUpperCase() + "'");
    } else {
      where.push("upper(abbrev) like '%" + state.toUpperCase() + "%'");
    }
  }
  if (where.length > 0 && isNotAll) {
    sql = sql + ' WHERE ' + where.join(' OR ');
  } else {
    const excluded = EXCLUDED_STATES.map(state => "abbrev != '" + state + "'");
    sql = sql + ' WHERE ' + excluded.join(' AND ');
  }
  // console.log("SQL ", sql);
  return sql;
}
export async function searchStateOrRegionPrint(states) {
  let sql = 'SELECT ST_AsGeoJSON(ST_Collect(the_geom)) as the_geom, ST_AsGeoJSON(ST_Centroid(ST_Collect(the_geom))) as centroid, ST_AsGeoJSON(ST_BoundingDiagonal(ST_Collect(the_geom))) as envelope FROM ' + CARTO.STATE_TABLE;
  
  let where = [];
  let isNotAll = true;
  for (let state of states) {
    // // console.log('STATE', state);
    if (state == 'All') {
      isNotAll = false;
    } else if (state.indexOf('region_') >= 0) {
      const region = state.split('_')[1];
      where.push("upper(region) = '" + region.toUpperCase() + "'");
    } else {
      where.push("upper(abbrev) like '%" + state.toUpperCase() + "%'");
    }
  }
  // console.log('QUERY ',sql );
  if (where.length > 0 && isNotAll) {
    sql = sql + ' WHERE ' + where.join(' OR ');
  } else {
    const excluded = EXCLUDED_STATES.map(state => "abbrev != '" + state + "'");
    sql = sql + ' WHERE ' + excluded.join(' AND ');
  }
  const query = CARTO.GET_QUERY(sql, false);
  const result = null;
  let response = await axios.get(encodeURI(query));
  return response.data.rows[0];
}
const search = (options, comparisonOptions) => {
  if(comparisonOptions) {
    const ans = comparisonOptions.filter(ele => {
      if (options.maptype === 'city') {
        // tslint:disable-next-line:max-line-length
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
      } else {
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
      }
    });
    return ans;
  } else {
    return [];
  }
  
}
export async function dataComparisonState(options, comparionOptions) {
  if (options.datacomparison.selected !== 'none' && options.datacomparison.selected !== '') {
    const comparer = search(options, comparionOptions)[0];
    if(comparer) {
      const query = CARTO.GET_QUERY(`SELECT ${comparer.mapvariable} as value, ${comparer.maplegendvariable} as legendPos, state, year, abbrev FROM ` + CARTO.STATE_COMPARER);
      let response = await axios.get(query);
      return response.data.rows;
    } else {
      return [];
    }
  }
}

export function getStatePopup() {
  return axios.get(SERVER.STATE_POPUPS);
}

export function getStateCodes() {
  const query = CARTO.GET_QUERY(`SELECT name, state FROM ${CARTO.STATE_TABLE}`);
  return axios.get(query);
}