import PrintDataService from './PrintDataService';
import MapService from './MapService';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {transformLegendColors, getColor, transformLegendColorsToHex} from '../services/colorsService';
import { CARTO, SERVER } from '../settings';
import { addLayers } from './DownloadMapLayerService';
import {printService} from '../services/printService';
import { getQuerySearchStateOrRegionPrint } from '../services/stateService';
import { shouldDisplayExtraCero } from './utils';

const mapboxgl = {
  LngLat: function(lng, lat){
    this.lng = lng;
    this.lat = lat;
  }
}

const printDataService = PrintDataService();

const getImageComparisonFromBackEnd = (data64, data64Comp, options, print, exportModel, endLoader) => {
  const search = MapService.search(options)[0];
    let comparer;
    if (!search) {
      comparer = {
        title: null,
        legendvalues: '',
        legendcolors: ''
      };
    } else {
      comparer = JSON.parse(JSON.stringify(search));
    }
    let body = {
      map: data64,
      legend: [],
      title: options.mainmaptitle,
      showLegend: print.legend,
      showTitle: print.title,
      showFooter: print.footer,
      resolution: print.resolution,
      height: exportModel.height,
      width: exportModel.width,
      // data comparison body
      legendComparison: [],
      mapComparison: data64Comp,
      titleComparison: comparer.maptitle,
      heightComparison: exportModel.height,
      widthComparison: exportModel.width,
      format: exportModel.selectedFormat
    };
    
    const colors = transformLegendColors(options.legendcolors, options.maptype, options.ratescases.selected, options.caseslabel, undefined, options.datatype, options.rateslabel, options);
    const values = options.legendvalues.split(',');
    if (options.maptype != 'state' && options.ratescases.selected === 'cases' && (options.caseslabel === 'Cases' || options.caseslabel === 'Users')) {
      values.unshift(0);
    } 
    for (let i = 0; i < values.length; i++) {
      body.legend.push({
        background: colors[i],
        color: getColor(colors[i]),
        text: values[i]
      });
    }
    // comparer.legendcolors = '0-180-180-180,0-180-180-180,204-180-180-180,' + JSON.parse(JSON.stringify(comparer.legendcolors));
    comparer.legendcolors = JSON.parse(JSON.stringify(comparer.legendcolors));
    const colorsComparison = transformLegendColors(comparer.legendcolors, options.maptype, options.ratescases.selected, options.caseslabel, true, options.datatype, options.rateslabel, options);
    const valuesComparison = comparer.legendvalues.split(',');
    for (let i = 0; i < valuesComparison.length; i++) {
      body.legendComparison.push({
        background: colorsComparison[i],
        color: getColor(colorsComparison[i]),
        text: valuesComparison[i]
      });
    }
    axios.post(SERVER.PRINT_COMPARISON, body, {
      responseType: 'blob'
     }).then((response) => {
      let blob = new Blob([response.data])
      saveAs(blob, `AIDSVu.png`);
    }, error => {
      console.error("Error on print", error);
    }).finally(() => {
      endLoader();
    }); 
}

const getImageFromBackEnd = (options, exportModel, data64, trueHeight, trueWidth, print, endLoader) => {
  console.log('data64', data64);
  let body = {
    map: data64,
    legend: [],
    title: options.mainmaptitle,
    showLegend: print.legend,
    showTitle: print.title,
    showFooter: print.footer,
    resolution: print.resolution,
    height: trueHeight ? trueHeight: exportModel.height,
    width: trueWidth ? trueWidth: exportModel.width,
    format: exportModel.selectedFormat
  };
  // console.log("BODY",body);
  let colors;
  if (options.maptype === 'state') {
    colors = transformLegendColors(options['legendcolors'], 'state', options.ratescases.selected)
  } else {
    colors = transformLegendColors(options.legendcolors, options.maptype, options.ratescases.selected, options.caseslabel, undefined, options.datatype, options.rateslabel, options);
  }
  
  const values = options.legendvalues.split(',');
  if (options.maptype != 'state' && shouldDisplayExtraCero(options)) {
    values.unshift(0);
  } 
  for (let i = 0; i < values.length; i++) {
    body.legend.push({
      background: colors[i],
      color: getColor(colors[i]),
      text: values[i]
    });
  }
   axios.post(SERVER.PRINT, body, {
    responseType: 'blob'
   }).then((response) => {
    let blob = new Blob([response.data])
    saveAs(blob, `AIDSVu.png`);
  }, error => {
    console.error("Error on print", error);
  }).finally(() => {
    endLoader();
  }); 
}

const printFn = async (exportModel, options, print, overlays, printImage, printValues, endLoader) => {
  let searchSql = await getQuerySearchStateOrRegionPrint(printValues.area);
  const styleOptions = {
    lineWidth: 3
  }
  // this.loaderService.start();
  let mapColor = '';
  let transparent = true;
  let mapconfig = { layers: []};
  let urlTemplate = `http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png`;
  if (exportModel.selectedBaseMap.includes('White')) {
    mapColor = ' Map {background-color: #ffffff} ';
    transparent = false;
  } else if (exportModel.selectedBaseMap.includes('Road')) {
    urlTemplate = `http://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png`;
    transparent = false;
  } else if (exportModel.selectedBaseMap.includes('Transparent')) {
    // empty
  } else {
    transparent = false;
  }

  if (!transparent) {
     mapconfig = {
      layers: [
        {
          type: 'http',
          options: {
            urlTemplate: urlTemplate,
            subdomains: [
              'a',
              'b',
              'c'
            ]
          }
        }
      ]
    };
  }

  printImage.filters.forEach((filters, pos) => {
    if (filters && filters.length) {
      let printing = '';
      let check = false;
      filters.forEach((filter, f) => {
        // if (f >= 2) {
          if (check) {
            printing += ', ';
          }
          check = true;
          if (printImage.type === 'string') {
            printing += `'${filter}'`;
          } else {
            printing += filter;
          }
        // }
      });
      let localAreaCondition = '';
      if ( exportModel.selectedArea  !== 'All' && !exportModel.selectedArea.includes('Select')) {
        localAreaCondition = `AND state IN (${searchSql})`;
      }
      if(printing.length > 0 ) {
        mapconfig.layers.push({
          type: 'cartodb',
          options: {
            cartocss_version: '2.1.1',
            sql: `SELECT * from  ${printImage.table} WHERE ${printImage.filterField} IN (${printing}) ` + localAreaCondition,
            cartocss: `#layer { polygon-fill:  ${printImage.colors[pos]};\n\t line-color: #000;\n\tline-width: ${0.2 * styleOptions.lineWidth};\n\tline-opacity: 1;\n} ${mapColor}`
          }
        });
      }
    }
  });
  let areaCondition = '';
  if ( exportModel.selectedArea  !== 'All' && !exportModel.selectedArea.includes('Select')) {
    areaCondition = `WHERE state IN (${searchSql})`;
  }

  if (options.maptype !== 'city') {
    mapconfig.layers.push({
      type: 'cartodb',
      options: {
        cartocss_version: '2.1.1',
        sql: `SELECT * FROM state2010 ` + areaCondition,
        cartocss: `#layer {line-color: #000; \n\tline-width: ${0.8 * styleOptions.lineWidth};}`
      }
    });
  }

  if(overlays.isDistricts) {
    mapconfig.layers.push({
      type:'cartodb',
      options: {
        cartocss_version: '2.1.1',
        sql:`SELECT * FROM ${CARTO.DISTRICT_TABLE} `,
        cartocss: `#layer {line-color: #3645E3; \n\tline-width: ${0.5 * styleOptions.lineWidth};} 
          #layer::labels {
          text-name: [id];
          text-face-name: 'DejaVu Sans Book';
          text-size: 10;
          text-fill: #661d1d;
          text-label-position-tolerance: 0;
          text-halo-radius: 2;
          text-halo-fill: #ffffff;
          text-allow-overlap: true;
          text-placement: point;
          text-placement-type: dummy;
        }`
      }
    })
  }

  let markerSize = Math.floor(exportModel.height / 40);
  markerSize=markerSize<24?25:markerSize;
  addLayers(overlays, mapconfig, markerSize);
  MapService.getMap(mapconfig).then(res => {
    let drawBounding = MapService.getDrawBounding()
    let sw, ne;

    if (drawBounding !== null) {
      let { north, east, south, west } = drawBounding;
      sw = new mapboxgl.LngLat(west, south);
      ne = new mapboxgl.LngLat(east, north);
    } else {
      sw = new mapboxgl.LngLat(printService.nw[0], printService.se[1]);
      ne = new mapboxgl.LngLat(printService.se[0], printService.nw[1]);
    }

    let dLng = Math.abs(ne.lng - sw.lng);
    let dLat = Math.abs(ne.lat - sw.lat);
    let dMax = Math.max(dLng, dLat);
    let offset = dMax * 0.20;
    // if(!this.printBounds && options.maptype !== 'city') {
    //   offset = 0;
    // }
    sw = new mapboxgl.LngLat(sw.lng - offset, sw.lat - offset);
    ne = new mapboxgl.LngLat(ne.lng + offset, ne.lat + offset);
    let z = '' + Math.ceil(printService.zoom);
    // if (this.print.area[0] === 'AK') {//Especial case of Alaska
    //   sw = new mapboxgl.LngLat(sw.lng, sw.lat+3.5);
    //   ne = new mapboxgl.LngLat(ne.lng, ne.lat+3.5);
    //   z = '' + (Math.floor(printService.zoom) - 1);
    // }
    let bounds = `${sw.lng},${sw.lat},${ne.lng},${ne.lat}`;
    let dx = Math.abs(ne.lng - sw.lng);
    let dy = Math.abs(ne.lat - sw.lat);
    let r = dy / dx;
    let w = 30;
    let h = Math.ceil(r * w);

    if (options.datacomparison.selected && options.datacomparison.selected !== 'none' && options.datacomparison.selected !== '') {
      MapService.dataComparisonGenerics(options).then(rows => {
        const comparer = MapService.search(options)[0];
        const availableColors =  comparer['legendcolors'];
        const colors = transformLegendColorsToHex(availableColors);
        const comparerfilters = [];
        for (let i = 0; i < 10; i++) {
          comparerfilters.push([]);
        }
        for (const row of rows) {
          if (row.legendpos < 0) {
            row.legendpos++;
          }
          if (row.legendpos + 3 < 0) {
            continue;
          }
          if (!comparerfilters[row['legendpos'] + 3]) {
            comparerfilters[row['legendpos'] + 3] = [];
          }
          if (options.maptype === 'county') {
            comparerfilters[row['legendpos'] + 3].push(row.geo_id2);
          }
          if (options.maptype === 'state') {
            comparerfilters[row['legendpos'] + 3].push(row.state);
          }
          if (options.maptype === 'city') {
            comparerfilters[row['legendpos'] + 3].push(row.zipcensus);
          }
        }

        let transparentComp = true;
        let mapconfigComp = { layers: []};
        if (exportModel.selectedBaseMap.includes('White')) {
          transparentComp = false;
        } else if (exportModel.selectedBaseMap.includes('Road')) {
          transparentComp = false;
        } else if (exportModel.selectedBaseMap.includes('Transparent')) {
          // empty
        } else {
          transparentComp = false;
        }

        if (!transparentComp) {
          mapconfigComp = {
            layers: [
              {
                type: 'http',
                options: {
                  urlTemplate: urlTemplate,
                  subdomains: [
                    'a',
                    'b',
                    'c'
                  ]
                }
              }
            ]
          };
        }
        let row_variable = '', table = '';
        if (options.maptype === 'state') {
          row_variable = 'state';
          table = CARTO.STATE_TABLE;
        }
        if (options.maptype === 'county') {
          row_variable = 'geo_id2';
          table = CARTO.COUNTY_TABLE;
        }
        if (options.maptype === 'city') {
          row_variable = options.geographytype.selected;
          table = CARTO.CITY_ZIP_CODE;
          if (!row_variable.includes('zip')) {
            table = CARTO.CITY_CENSUS;
          }
        }
        console.log('coimpararer filters', comparerfilters);
        colors.forEach((color, index) => {
          const comp = comparerfilters[index];
          if (!comp.length) {
            return;
          }
          let areaConditionComp = '';
          if ( exportModel.selectedArea  !== 'All' && !exportModel.selectedArea.includes('Select')) {
            areaConditionComp = `AND state IN (${searchSql})`;
          }
          let toprint = '';
          let check = false;
          for (const element of comp) {
            if (check) {
              toprint += ', ';
            }
            check = true;
            if (typeof element === 'string') {
              toprint += `'${element}'`;
            } else {
              toprint += element;
            }
          }
          if(toprint.length > 1) {
            mapconfigComp.layers.push({
              type: 'cartodb',
              options: {
                cartocss_version: '2.1.1',
                sql: `SELECT * from  ${table} WHERE ${printImage.filterField} IN (${toprint}) ` + areaConditionComp,
                cartocss: `#layer { polygon-fill:  ${color.color};\n\t line-color: #000;\n\tline-width: ${0.2 * styleOptions.lineWidth};\n\tline-opacity: 1;\n} ${mapColor}`
              }
            });
          }
          
        });

        if ( exportModel.selectedArea  !== 'All' && !exportModel.selectedArea.includes('Select')) {
          areaCondition = `WHERE state IN (${searchSql})`;
        }
        if (options.maptype !== 'city') {
          mapconfigComp.layers.push({
            type: 'cartodb',
            options: {
              cartocss_version: '2.1.1',
              sql: `SELECT * FROM state2010 ` + areaCondition,
              cartocss: `#layer {line-color: #000; \n\tline-width: ${0.8 * styleOptions.lineWidth};}`
            }
          });
        }

        if(overlays.isDistricts) {
          mapconfigComp.layers.push({
            type:'cartodb',
            options: {
              cartocss_version: '2.1.1',
              sql:`SELECT * FROM ${CARTO.DISTRICT_TABLE} `,
              cartocss: `#layer {line-color: #4b49b8; \n\tline-width: ${0.4 * styleOptions.lineWidth};}`
            }
          })
        }


        MapService.getMap(mapconfigComp).then(resComp => {
          printDataService.getImagePython({ 
            "map_id": res.data.layergroupid,
            "width": w,
            "height": h,
            "zoom": z,
            "bounds": bounds
          }).then(
            re => {
              const data = re.data;
              exportModel.height = data.height;
              exportModel.width = data.width;
              printDataService.getImagePython({ 
                "map_id": resComp.data.layergroupid,
                "width": w,
                "height": h,
                "zoom": z,
                "bounds": bounds
              }).then(
                re2 => {
                  const data2 = re2.data;
                  getImageComparisonFromBackEnd(
                    'data:image/png;base64,' + data.base64,
                    'data:image/png;base64,' + data2.base64,
                    options,
                    print,
                    exportModel,
                    endLoader
                  );
                }
              )
            },
            () => {
            }
          );
        })
      })
    } else {
      printDataService.getImagePython({
          "map_id": res.data.layergroupid,
          "width": w,
          "height": h,
          "zoom": z,
          "bounds": bounds
      }).then(
        response => {
          const data = response.data;
          console.log('data', data);
          exportModel.height = data.height;
          exportModel.width = data.width;
          getImageFromBackEnd(options, exportModel, 'data:image/png;base64,'+data.base64, data.height, data.width, print, endLoader);
        },
        err => {
          console.log('err', err);
        }
      );
    }
  });
}

export default printFn;