import axios from 'axios';

const PrintDataService = () => {

  return {
    getImagePython: (body) => {
      let route = process.env.REACT_APP_STAGE == 'production'?'https://map.aidsvu.org':'https://mapdev.aidsvu.org'
      return axios.post(`${route}/python/print`, body)
    }
  }
}

export default PrintDataService;