import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
const MoreInformationModal = ({ close , options,changeSlider }) => {
  const copyText = "Sullivan PS, Woodyatt C, Koski C, Pembleton E, McGuinness P, Taussig J, Ricca A, Luisi N, Mokotoff E, Benbow N, Castel AD. A data visualization and dissemination resource to support HIV prevention and care at the local level: analysis and uses of the AIDSVu Public Data Resource. Journal of medical Internet research. 2020;22(10):e23173.";
  const [slider, setSlider] = useState(100);
  const [htmlMI,setHtmlMI] = useState({__html:''});
  const updateSlider = () => {
    changeSlider(slider);
    }
  useEffect(()=>{
    if(options.footnotes){
      let footnoteValue = options.footnotes.replace('<p>Rates displayed are the number of cases per 100,000 population.</p>', '');
      footnoteValue = footnoteValue.replace('<p> Rates displayed are the number of cases per 100,000 population.</p>', '')
      setHtmlMI({__html: footnoteValue});
    }
    if(options.slider) {
      setSlider(options.slider);
    }
  },[options])
  return (
    <div className="menu-panel menu-info">
      <button type="button" className="btn btn-link btn-close" onClick={() => close(false)}>
        Close
        <img src="assets/close.svg" alt="" className="ml-1" />
      </button>
      <div className="container">
        <h6><strong>More information</strong></h6>
        <div dangerouslySetInnerHTML={htmlMI}></div>
        <hr />
        <h6>
          <strong>Data Methods</strong>
        </h6>
        <p>Please visit the <a href="https://aidsvu.org/data-methods-statecounty/" style={{ fontWeight:'900 !important'}}> <strong>Data Methods</strong></a> for more information regarding the data in these datasets.</p>
        <hr/>
        <p className="information p-0 m-0">
          <strong>Layer Opacity:
            <span className="text-primary-dark">&nbsp;{slider}%</span>
          </strong>
          <input id='slider' type='range' min='0' max='100' step='0' value={slider} onMouseLeave={ updateSlider} onTouchEnd={ updateSlider} onChange={(e) => setSlider(e.target.value)} />
        </p>
        <hr />
        <h6><strong>Citation</strong></h6>
        <p style={{marginBottom:'2px'}}>Sullivan PS, Woodyatt C, Koski C, Pembleton E, McGuinness P, Taussig J, Ricca A, Luisi N, Mokotoff E, Benbow N, Castel AD. A data visualization and dissemination resource to support HIV prevention and care at the local level: analysis and uses of the AIDSVu Public Data Resource. Journal of medical Internet research. 2020;22(10):e23173.”</p>
        <p><CopyToClipboard text={ copyText}><a href="#"><strong>Copy Citation to Clipboard</strong></a></CopyToClipboard>| <a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/AIDSVu-Protocol-Paper.pdf"><strong>Download PDF</strong></a> </p>
        <hr/>
        <h6><strong>Export Meta Data</strong></h6>
        <p style={{marginBottom:'2px'}}><a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/END.end"><strong>END</strong></a> for: Endnote</p>
        <p style={{marginBottom:'2px'}}><a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/Bib-TeX.bib"><strong>BibTeX</strong></a> for: BibDesk, LaTeX </p>
        <p style={{marginBottom:'2px'}}><a href="https://aidsvuv3.staging.wpengine.com/wp-content/uploads/2021/04/RIS.ris"><strong>RIS</strong></a> for: RefMan, Procite, Endnote, RefWorks</p>
      </div>
    </div>
  )
}



const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSlider: (value) => {
      dispatch({type:'CHANGE_SLIDER', value})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreInformationModal)
