import React, { useState,useRef, useEffect } from 'react';
import { connect } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap';



const Overlays = ({options, close, overlays, changeService}) => {

  const [hivTesting,sethivTesting] = useState(false);
  const [prep,setprep] = useState(false);
  const [hivRyanwhite,sethivRyanwhite] = useState(false);
  const [hopwa,sethopwa] = useState(false);
  const [hptnMtn,sethptnMtn] = useState(false);
  const [hptn,sethptn] = useState(false);
  const [mtn,setmtn] = useState(false);
  const [hvtn,sethvtn] = useState(false);
  const [actgImpaact,setactgImpaact] = useState(false);
  const [actg,setactg] = useState(false);
  const [impaact,setimpaact] = useState(false);
  const [hcs,sethcs] = useState(false);
  const [isDistricts,setisDistricts] = useState(false)

  const changeOverlay = (value) => {
    // console.log("VALE ", value.target);
    switch(value.target.id) {
    case 'hivTesting':
      sethivTesting(!hivTesting);
      changeService(value.target.id,!hivTesting);
      break;
    case 'prep':
      setprep(!prep);
      changeService(value.target.id,!prep);
      break;
    case 'hivRyanwhite':
      sethivRyanwhite(!hivRyanwhite);
      changeService(value.target.id,!hivRyanwhite);
      break;
    case 'hopwa':
      sethopwa(!hopwa);
      changeService(value.target.id,!hopwa);
      break;
    case 'hptnMtn':
      sethptnMtn(!hptnMtn);
      sethptn(!hptnMtn);
      setmtn(!hptnMtn);
      changeService(value.target.id,!hptnMtn);
      changeService('hptn',!hptnMtn);
      changeService('mtn',!hptnMtn);
      break;
    case 'hptn':
      sethptn(!hptn);
      changeService(value.target.id,!hptn);
      break;
    case 'mtn':
      setmtn(!mtn);
      changeService(value.target.id,!mtn);
      break;
    case 'hvtn':
      sethvtn(!hvtn);
      changeService(value.target.id,!hvtn);
      break;
    case 'actgImpaact':
      setactgImpaact(!actgImpaact);
      setactg(!actgImpaact);
      setimpaact(!actgImpaact);
      changeService(value.target.id,!actgImpaact);
      changeService('actg',!actgImpaact);
      changeService('impaact',!actgImpaact);
      break;
    case 'actg':
      setactg(!actg);
      changeService(value.target.id,!actg);
      break;
    case 'impaact':
      setimpaact(!impaact);
      changeService(value.target.id,!impaact);
      break;
    case 'hcs':
      sethcs(!hcs);
      changeService(value.target.id,!hcs);
      break;
    case 'districts':
      setisDistricts(!isDistricts);
      changeService('isDistricts',!isDistricts);
    }
  }
  useEffect(()=>{
    if(hptnMtn) {
      sethptn(hptnMtn);
      changeService('hptn',hptnMtn);
      setmtn(hptnMtn);
      changeService('mtn',hptnMtn);
    }
  },[hptnMtn])
  useEffect(()=>{
    if(actgImpaact) {
      setactg(actgImpaact);
      changeService('actg',actgImpaact);
      setimpaact(actgImpaact);
      changeService('impaact',actgImpaact);
    }
  },[actgImpaact])
  useEffect(()=>{
    sethptnMtn(hptn && mtn);
    changeService('hptnMtn',hptn && mtn);
  },[hptn, mtn]);
  useEffect(()=>{
    setactgImpaact(actg && impaact);
    changeService('actgImpaact',actg && impaact )
  },[actg,impaact])
  useEffect(()=>{

      sethivTesting(overlays.hivTesting)
      setprep(overlays.prep)
      sethivRyanwhite(overlays.hivRyanwhite)
      sethopwa(overlays.hopwa)
      sethptnMtn(overlays.hptnMtn)
      sethptn(overlays.hptn)
      setmtn(overlays.mtn)
      sethvtn(overlays.hvtn)
      setactgImpaact(overlays.actgImpaact);
      setactg(overlays.actg)
      setimpaact(overlays.impaact)
      sethcs(overlays.hcs)
      setisDistricts(overlays.isDistricts);
    },[overlays]);
  function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable callback ref
    const innerRef = useRef(); // returned to client, who sets the "border" element

    // update callback on each render, so second useEffect has most recent callback
    useEffect(() => { callbackRef.current = callback; });
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (innerRef.current && callbackRef.current &&
          !innerRef.current.contains(e.target)
        ) callbackRef.current(e);
      }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
  }
  const innerRef = useOuterClick(ev => { close() });

  return (
    <div ref={innerRef} className="menu-nav"  style={{zindex: "10020", paddingBottom:'10px'}}>
      <button type="button"  onClick={close} className="btn btn-link btn-close" >Close <img src="assets/close.svg" alt="" className="ml-1"/></button>
      <div className="container" style={{display: 'block'}}>
        <div className="d-sm-block">
          <div className="align-items-baseline data-section">
            <h4><strong>Service Provider Directory:</strong></h4>
            <p>Select all the providers you’d like to view.</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <label className="controlbox controlbox--checkbox controlbox_fontsize" >
              <input type="checkbox"   id="hivTesting" checked={hivTesting} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              HIV Testing Sites <img className="ml-2" height="11px" src="assets/ic_location-blue.svg" alt=""/>
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize" >
              {/* (change)="changeCheckboxes($event)" [(ngModel)]="model.prep" */}
              <input type="checkbox"  id="prep" checked={prep} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              Pre-Exposure Prophylaxis (PrEP) Services <img className="ml-1" height="11px" src="assets/ic_location-black.svg" alt=""/>
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize" >
              {/* (change)="changeCheckboxes($event)" [(ngModel)]="model.hivRyanwhite" */}
              <input type="checkbox"  id="hivRyanwhite"  checked={hivRyanwhite} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              Ryan White HIV/AIDS Medical Care Providers <img className="ml-1" height="11px" src="assets/ic_location-red.svg" alt=""/>
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize" >
              {/* (change)="changeCheckboxes($event)" [(ngModel)]="model.hopwa" */}
              <input type="checkbox"  id="hopwa"  checked={hopwa} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              Housing Opportunities for Persons with AIDS (HOPWA) <img className="ml-1" height="11px" src="assets/ic_location-green.svg" alt=""/>
            </label>
          </div>
          {/* <div className="col-md-4 line-vertical">
            <label className="controlbox controlbox--checkbox controlbox_fontsize" >
              <input type="checkbox"  id="hptnMtn"  checked={hptnMtn} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              NIH-Funded HIV Prevention Trials Sites
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize  item-pd">
              <input type="checkbox"  id="hptn"  checked={hptn} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              HIV Prevention Trials Network (HPTN)
              <div className="colorbox bg-pink ml-1"></div>
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize  item-pd">
              <input type="checkbox"  id="mtn"  checked={mtn} onChange={(e) => changeOverlay(e)} />
              <div className="controlbox__indicator"></div>
              Microbicide Trials Network (MTN)
              <div className="colorbox bg-green ml-1"></div>
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize" >
              <input type="checkbox"  id="hvtn"  checked={hvtn} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              NIH-Funded HIV Vaccine Trials Network
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize  item-pd">
              <input type="checkbox"  id="hvtn"  checked={hvtn} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              HIV Vaccine Trials Network (HVTN)
              <div className="colorbox bg-purple ml-1"></div>
            </label>
          </div>
          <div className="col-md-4">
            <label className="controlbox controlbox--checkbox controlbox_fontsize" >
              
              <input type="checkbox"  id="actgImpaact"  checked={actgImpaact} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              NIH-Funded HIV Treatment Trials Sites
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize  item-pd">
              
              <input type="checkbox"  id="actg"  checked={actg} onChange={(e) => changeOverlay(e)}/>
              <div className="controlbox__indicator"></div>
              AIDS Clinical Trials Group (ACTG)
              <div className="colorbox bg-blue ml-1"></div>
            </label>
            <label className="controlbox controlbox--checkbox controlbox_fontsize  item-pd" style={{lineHeight:'15px'}}>
              
              <input type="checkbox"  id="impaact"  checked={impaact} onChange={(e) => changeOverlay(e)} />
              <div className="controlbox__indicator"></div>
              <span>International Maternal Pediatric Adolescent
                <br/>AIDS Clinical Trials Group (IMPAACT)
                <div className="colorbox bg-red ml-1"></div></span>
            </label>
          </div> */}
        </div>

        <div className="row">
          <hr style={{width:'92%', margin:'25px 0 25px 15px'}} className="mobile-hr"/>
          <div className="col-md-8">
            <label className="controlbox controlbox--checkbox controlbox_fontsize">
              {/* (change)="addDistricts($event)" [(ngModel)]="isDistricts"  */}
              <input type="checkbox" id="districts" checked={isDistricts} onChange={(e)=> changeOverlay(e)} />
              <div className="controlbox__indicator"></div>
              <span>View U.S. Congressional District Boundaries (118<sup>th</sup> Congress)</span>
            </label>
          </div>
          <div className="col-md-4">
            <div className="controlbox_fontsize"><i>Service providers are updated as of March 2021</i></div>
          </div>
        </div>
      </div>
</div>

  )
}

const mapStateToProps = (state) => {
  return {
    dcoptions: state.dcoptions,
    options: state.options,
    overlays: state.overlays
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeDCOption: (filter,value) => {
      dispatch({ type: 'CHANGE_SELECTED', key:filter, value });
    },
    changeService: (key, value) => {
      dispatch({type:'CHANGE_SERVICE', key, value})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overlays)
