import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './App.scss';

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { extractQueryParams } from './components/utils';
import {defaultOptions} from './defaultOptions';
import Map from './Map';

import {searchRegular} from './services/dataComparisonService';

function Main({ loader, changeType, changeSelected,  changeBounds, options, updateOptions, changeArea, changeBaseMap, type, mapt }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasParams, setHasParams] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const newOptions = defaultOptions;
  const [onInit, setOnInit] = useState(true);
  useEffect(()=>{
    if(loader) {
      setIsLoading(loader._loading != loader._loaded);
    }
    
  },[loader]);
  
  useEffect(()=>{
    if(onInit && isMobile){
      setOnInit(false);
      setTimeout(()=>{
        updateOptions(newOptions);
      },2000);
    }
  },[isMobile]);
  useEffect(() => {
    let [obj, paramsCount] = extractQueryParams();
    
    if(paramsCount === 1 && obj['prep'] ){ 
      obj = JSON.parse('{"maptype":"county","datatype":"PrEP","ratescases":"rates","overall":true,"basemap":"cjjopfjjb03ag2rpnrv3w5k62","filters":["overall"],"area":"All","year":"2018"}');
      paramsCount = 5;
    } 
    if( paramsCount === 1 && obj['cityname']) {
      obj = JSON.parse(`{"maptype":"city","datatype":"prevalence","ratescases":"rates","overall":true,"basemap":"cjjopfjjb03ag2rpnrv3w5k62","filters":["overall"],"city":"${obj['cityname']}","geotype":"zipcode","year":"hidden"}`);
      paramsCount = 7;
    }
    if (paramsCount) {
      setHasParams( type == 'frame'? true: false);
      setTimeout(() => {
        newOptions.overall = obj.overall;
        for (const key in newOptions) {
          if (newOptions && newOptions.hasOwnProperty(key)) {
            if (newOptions[key] && newOptions[key].hasOwnProperty('selected') && key !== 'year') {
              for (const filter of obj.filters) {
                let index = -1;
                newOptions[key].labels.forEach((label, pos) => {
                  if (label.split(' ').join('').toLowerCase() === filter.split(' ').join('').toLowerCase()) {
                    index = pos;
                  }
                });
                if (index >= 0) {
                  newOptions[key].selected = newOptions[key][key][index];
                }
              }
            }
          }
        }
        if(mapt != 'any') {
          newOptions.maptype = mapt;
        } else { 
          newOptions.maptype = obj['maptype']
        }
         
        
        if(newOptions.maptype == 'city') {
          newOptions.city = obj['city'];
          newOptions.geographytype.selected = obj['geotype'];
        }
        if(newOptions.maptype == 'county') {
          newOptions.areatype = obj['countytype'];
        }
        ['datatype', 'overall'].forEach((key) => {
          if (obj[key] !== '') {
            newOptions[key] = obj[key];
          }
        });
        ['ratescases', 'year'].forEach((key)=> {
          if (obj[key] !== '') {
            newOptions[key].selected = obj[key];
          }
        });
        
        if(!newOptions.areatype) {
          newOptions.areatype = 'all';
        } 
        console.log("NEW OPT", newOptions);
        setTimeout(()=> {
          updateOptions(newOptions);
          setTimeout(()=>{
            if(obj['area'] && newOptions.maptype != 'city') {
              changeArea(obj['area']);
            } else if(obj['bounds']) {
              changeBounds(obj['bounds']);
            } 
            if(obj['basemap']){
              changeBaseMap(obj['basemap']);
            }
          },1200);
        },2300);
        
      }, 5500)
    }
    const changeMobile  = window.innerWidth < 1024 ? true: false;
    setIsMobile(changeMobile);
  }, []);

  return (
    <>
      {
        isLoading && 
        <div className="loader-bg">
          <div className="loading"></div>
        </div>
      }
      { !hasParams && <Navbar />}
      {
        hasParams && <div class="header-map">
          <h6>{options.mainmaptitle}</h6>
        </div>
      }
      <div className="Main">
        <Map hasParams={hasParams} type={type}/>
      </div>
      
      {/* { !hasParams } */}
      <Footer hasParams={hasParams}/>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    options: state.options,
    loader: state.loaderOptions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeType: (key, value) => {
      dispatch({ type: 'CHANGE_VALUE', key, value });
    },
    changeSelected: (key, value) => {
      dispatch({ type: 'CHANGE_SELECTED', key: key, value });
    },
    updateOptions: ( options ) => {
      dispatch({type: 'REPLACE_OPTIONS_UPDATE', options})
    },
    changeArea: (value) => {
      dispatch({type: 'CHANGE_AREA',value})
    },
    changeBaseMap: (value) => {
      dispatch({type: 'CHANGE_BASEMAP', value})
    },
    changeBounds: (value) => {
      dispatch({type: 'CHANGE_BOUNDS', value})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
