import React, { useState } from 'react';
import RegionalFilter from './RegionalFilter';

const Navbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <nav className="navbar navbar-expand-lg fixed-top bg-white flex-column p-0">
      <div className="navbar-box-content container-fluid d-flex justify-content-between">
        <a className="navbar-brand " style={{paddingLeft: '4rem '}} href="https://aidsvu.org/"><img src="/assets/logo.svg" /></a>
        <span>
          <button className="navbar-toggler navbar-toggler-right" type="button">
          </button>
          <button className="navbar-toggler navbar-toggler-right mr-2 toggle-table" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            onClick={() => setIsCollapsed(!isCollapsed)} >
            <img src="assets/menu-mobile.svg" alt="" />
          </button>
        </span>
        <div className="collapse navbar-collapse navbar-main navbar-mobile" style={{ paddingRight:'4rem', display: isCollapsed ? 'block' : 'none' }}>
          <ul className="navbar-nav">
            <li className="nav-item"><a className="nav-link fontlink" href="https://map.aidsvu.org/map">View the map</a></li>
            <li className="nav-item"><i className="mdi mdi-circle"></i></li>
            <li className="nav-item"><a className="nav-link fontlink" href="https://aidsvu.org/local-data/">Local data</a></li>
            <li className="nav-item"><i className="mdi mdi-circle"></i></li>
            <li className="nav-item"><a className="nav-link fontlink" href="https://aidsvu.org/services/#/">Find services</a></li>
            <li className="nav-item"><i className="mdi mdi-circle"></i></li>
            <li className="nav-item"><a className="nav-link fontlink" href="https://aidsvu.org/category/news-updates/">News & Updates</a></li>
            <li className="nav-item"><i className="mdi mdi-circle"></i></li>
            <li className="nav-item"><a className="nav-link fontlink" href="https://aidsvu.org/resources/#/">Tools & Resources</a></li>
          </ul>
        </div>
      </div>
      <RegionalFilter />
    </nav>
  )
}

export default Navbar;
