export  const defaultOptions = {
  city: '',
  hasYear: false,
  year: {
    year: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  ratescases: {
    ratescases: ['rates', 'cases', 'additionalrates'],
    labels: ['Rates', 'Cases', 'RATE'],
    selected: 'rates',
    available: [],
    hidden: [],
    unavailable: []
  },
  rateslabel: '',
  caseslabel: '',
  additionalrateslabel: '',
  overall: true,
  age: {
    age: ['agele24', 'age1324','age1824', 'age2534', 'age2544', 'age3544', 'age4554', 'age4559','age5564' , 'age55over', 'age60over','age65over'],
    labels: ['24 and under', '13 to 24', '18 to 24' , '25 to 34', '25 to 44', '35 to 44', '45 to 54', '45 to 59','55 to 64', '55+', '60+', '65+'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  race: {
    race: ['aian', 'asian', 'black', 'hispanic', 'nhpi', 'multirace', 'white'],
    labels: ['American Indian/Alaska Native', 'Asian', 'Black', 'Hispanic',
    'Native Hawaiian/Pacific Islander', 'Multiple Races', 'White'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  sex: {
    sex: ['male', 'female'],
    labels: ['Male', 'Female'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  transcat: {
    transcat: ['msm', 'msmidu', 'idu', 'heterosexual', 'other'],
    labels: ['Male-to-Male Sexual Contact', 'Male-to-Male Sexual Contact / Injection Drug Use',
    'Injection Drug Use', 'Heterosexual Contact', 'Other'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  datacomparison: {
    datacomparison: ['poverty', 'hseducation', 'medianincome', 'incinequality', 'nohealthinsurance', 'unemployed', 'foodinsec', 'housing', 'syphilis', 'hepatitisc', 'medicaid', 'hivcrimlaws', 'hivprosecutionlaws'  ],
    labels: ['Poverty', 'High School Education', 'Median Household Income', 'Income Inequality', 'People without Health Insurance', 'Unemployment', 'Food Insecurity', 'Severe Housing Cost Burden', 'Syphilis','Hepatitis C','Medicaid Expansion', 'HIV Criminalization','HIV Prosecution Laws' ],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  geographytype: {
    geographytype: ['zipcode', 'censustract', 'communityarea', 'neighborhood', 'ward', 'none'],
    labels: ['Zip Code', 'Census Tract', 'Community Area', 'Neighborhood', 'Ward', 'none'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  datatype: 'prevalence',
  datatypecontrol: 'available',
  mainmaptable: '',
  mainmaptable2: '',
  mainmapvariable: '',
  mainmaplegendvariable: '',
  legendvalues: '',
  legendcolors: '',
  mainmaptitle: '',
  footnotes: '',
  maptype: 'county',
  areaType: {value:'all', name:'All Counties'},
  areatype: 'all'
};