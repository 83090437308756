import {CartoLayer, setDefaultCredentials, MAP_TYPES} from '@deck.gl/carto';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import { loadStatePrint } from './services/stateService';
import {StaticMap, WebMercatorViewport } from 'react-map-gl';
import {FlyToInterpolator} from '@deck.gl/core';
import {defaultOptions} from './defaultOptions';
import {asyncGetCountyFilters, getValuesOfTablesCounty, dataComparisonCounty, getCountyPopup} from './services/countyService';
import {asyncGetStateFilters, getValuesOfTablesState, searchStateOrRegionPrint, dataComparisonState, getStatePopup, getStateCodes} from './services/stateService';
import {asyncGetCityFilters, getValuesOfCityTables, getBoundingBox, dataComparisonCity, getGeoJsonCity, getCityPopup} from './services/cityService';
import {loadComparisonOptions, search} from './services/dataComparisonService';
import {transformLegendColorsToHex} from './services/colorsService';
import {CARTO} from './settings';
import {rural, urban} from './services/areaData';
import { connect } from 'react-redux';
import FloatingButtons from './components/FloatinButtons';
import { updateFilters, getCityQuery , getCentroidsLayers } from './services/mapService';
import MapService from './components/MapService';
import {printService} from './services/printService';
import {getProviders} from './services/localStatisticsService';
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import DeckGLWrapperTwice from './DeckGLWrapperTwice';
import { EditableGeoJsonLayer, DrawPolygonMode } from 'nebula.gl';
import {GeoJsonLayer} from '@deck.gl/layers';
import IconClusterLayer from './iconClusterLayer';
import {getHcsFromCarto, getHousingFromCarto, getHivFromCarto, getRyanFromCarto, getPrepFromCarto , getGeoJsonFromCarto} from './services/overlaysService';
import { H3ClusterLayer } from 'deck.gl';
import {environment} from './environments/environment';

const INITIAL_VIEW_STATE = {
  latitude: 39.02,
  longitude: -96,
  zoom: 3.1,
  bearing: 0,
  pitch: 0,
};

setDefaultCredentials({
  username: environment.carto_account,
  apiKey: environment.carto_api_key
});


// hay que averiguar como se pone el basemap anterior
const style = 'http://34.194.243.6:8080/style/cj9ogyubd4ebv2rrr3nibqotp/aidsvu/pk.eyJ1IjoiYWlkc3Z1IiwiYSI6ImNqOG44bDFyejE3MnIzM3J0cjJqejJjanQifQ.SEb-Tl7BdBPVTI4q5y_hWA' ;
function Map({options, overlays, updateState, changeCity, endLoader, startLoader, updateDValues, printValues, updatePrintImage, arrowValue, hasParams, drawState, type }) {
  const inputEl = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [object, setObject] = useState(null);
  const [hideObject, setHideObject] = useState(null);
  const [keep, setKeep] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [currentBaseMap, setCurrentBaseMap] = useState('mapbox://styles/aidsvu/cj9ogyubd4ebv2rrr3nibqotp');
  const [currentZoom, setCurrentZoom ] = useState(3.3);
  const [popups, setPopups] = useState([]);
  const [stateLocalStatisticsArray, setStateLocalStatisticsArray] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);
  const [prepgeojson, setPrepGeojson] = useState([]);
  const [ryangeojson, setRyanGeojson] = useState([]);
  const [housinggeojson, setHousingGeojson] = useState([]);
  const [hivgeojson, setHivGeojson] = useState([]);
  const [hcsgeojson, setHcsGeojson] = useState([]);
  const [generalGeojson, setGeneralGeojson] = useState([]);
  const [isDraw, setIsDraw] = useState(false);
  const [areaF, setAreaF ] = useState([]);
  const [valCounty, setValCounty] = useState({});
  const [currentBounds, setCurrentBounds] = useState(
    {
      nw:[-147.50743101307887, 51.01879849251501],
      se:[-44.49256898692226, 22.499768446340557]
    });
  const [printImageValues, setPrintImageValues] = useState({
    colors:[],
    filterField:'',
    filters: '',
    table:'',
    type:''
  });
  const [initialViewState, setInitialViewState] = useState({
    latitude: 39.02,
    longitude: -96,
    zoom: 3.1,
    bearing: 0,
    pitch: 0,
  });
  const [initialViewState2, setInitialViewState2] = useState({
    latitude: 39.02,
    longitude: -96,
    zoom: 3.1,
    bearing: 0,
    pitch: 0,
  });
  const [drawBounding, setDrawBounding] = useState({});
  const [currentViewState, setCurrentViewState] = useState(initialViewState);
  const [stateOptions,setOptions] = useState(defaultOptions);
  const [layers,setLayers] = useState([]);
  const [layersTwo,setLayersTwo] = useState([]);
  const [selDC, setSelDC] = useState(undefined);
  const [isDC, setIsDC] = useState(false);
  const [comparisonOptions, setComparisonOptions] = useState([]);
  const [layerCountiesBorders, setLayerCountiesBorders] = useState(new CartoLayer({
    type: MAP_TYPES.QUERY,
    pickable: true,
    id: 'countyborders',
    data: `SELECT * FROM ${CARTO.COUNTY_TABLE}`,
    getFillColor: [180,180,180,0],
    stroked: true,
    lineWidthMinPixels: 1,
    getLineColor: [ 98, 110, 120, 50 ],
  }));

  
  const layerStatesBorders = new CartoLayer({
    type: MAP_TYPES.QUERY,
    pickable: true,
    id: 'stateBorders',
    data: `SELECT * FROM state2010`,
    getFillColor: [255,255,255,0],
    stroked: true,
    lineWidthMinPixels: 1.2,
    getLineColor: [42, 47, 54,220]
  });

  const layerStatesBordersExtra = new CartoLayer({
    type: MAP_TYPES.QUERY,
    pickable: true,
    id: 'stateBordersextra',
    data: `SELECT * FROM state2010`,
    getFillColor: [255,255,255,0],
    stroked: true,
    lineWidthMinPixels: 1.2,
    getLineColor: [42, 47, 54,220]
  });
  const layerCountiesBordersExtra = new CartoLayer({
    type: MAP_TYPES.QUERY,
    pickable: true,
    id: 'countybordersextra',
    data: `SELECT * FROM ${CARTO.COUNTY_TABLE}`,
    getFillColor: [180,180,180,0],
    stroked: true,
    lineWidthMinPixels: 1,
    getLineColor: [ 98, 110, 120, 50 ],
  });

  const layerCountiesBorders2 = new CartoLayer({
    type: MAP_TYPES.QUERY,
    pickable: true,
    id: 'countyborders2',
    data: `SELECT * FROM ${CARTO.COUNTY_TABLE}`,
    getFillColor: [255,255,255,0],
    stroked: true,
    lineWidthMinPixels: 1,
    getLineColor: [ 98, 110, 120, 50 ],
  });

  const layerStatesBorders2 = new CartoLayer({
    type: MAP_TYPES.QUERY,
    pickable: true,
    id: 'stateBorders2',
    data: `SELECT * FROM state2010`,
    getFillColor: [255,255,255,0],
    stroked: true,
    lineWidthMinPixels: 1.2,
    getLineColor: [42, 47, 54,220]
  });
  const [slider, setSlider] = useState(100);

  const [countyValues,setCountyValues] = useState([]);
  let dcountyValues = [];
  let dstateValues = [];
  const [cityValues, setCityValues] = useState([]);
  let dcityValues = [];
  const [isMobile, setIsMobile] = useState(false);
  const [isSquare, setIsSquare] = useState(false);

  const [stateValues, setStateValues] = useState([]);
  const [stateComparison, setStateComparison] = useState([]);
  const [countyComparison, setCountyComparison] = useState([]);
  const [cityComparison, setCityComparison ] = useState([]);
  const clickCentroids = useCallback(event => {
    let propsCity = event.object.properties;
    if(propsCity){
      let cityname = propsCity.cityname
      if(cityname == 'oakland&alameda') {
        cityname = 'oakland/alameda';
      }
      changeCity(cityname);
    }
  }, [])

  const myFeatureCollection = {
    type: "FeatureCollection",
    features: [
      /* insert features here */
    ]
  };
  const selectedFeatureIndexes = [];

  const updateLayers = (newFeatures) => {
    setLayers([...layers, new GeoJsonLayer({
      id: "geojson-layer-drawn",
      data: newFeatures,
      filled: true,
      pointRadiusMinPixels: 2,
      pointRadiusScale: 2000,
      getElevation: 1000,
      getFillColor: [80,80,80, 180],
    })]);
    let north, south, east, west;
    const drawpolygon = newFeatures.features[0].geometry.coordinates;
    for ( let i = 0 ; i <  drawpolygon[0].length ; ++i) {
      if (!north ) {
        north = drawpolygon[0][i][1];
      } else {
        north = Math.max(north, drawpolygon[0][i][1]);
      }
      if (!south) {
        south = drawpolygon[0][i][1];
      } else {
        south = Math.min(south, drawpolygon[0][i][1]);
      }
      if (! east) {
        east = drawpolygon[0][i][0];
      } else {
        east = Math.max( east, drawpolygon[0][i][0]);
      }
      if (! west) {
        west = drawpolygon[0][i][0];
      } else {
        west = Math.min(west, drawpolygon[0][i][0]);
      }
    }
    let centerLat = (north + south) / 2;
    let centerLng = (east + west ) / 2 ;
    setDrawBounding( {
      east: east,
      south: south,
      north: north,
      west: west,
      centerLat: centerLat,
      centerLng: centerLng
    });
  }
  const drawLayer = new EditableGeoJsonLayer({
    id: "geojson-layer",
    data: myFeatureCollection,
    mode: DrawPolygonMode,
    selectedFeatureIndexes,
    filled: true,
    pointRadiusMinPixels: 80,
    pointRadiusScale: 2000,
    extruded: true,
    getElevation: 1000,
    getFillColor: [180, 40, 40, 180],

    onEdit: ({updatedData, editType}) => {
      if(editType == 'addFeature') {
        updateLayers(updatedData);
      }

    }
  });
  useEffect( () => {
    const fetchData = async () => {
      if(stateOptions) {
        if ( stateOptions.latestValue === 'maptype') {
          setInitialViewState({
            ...INITIAL_VIEW_STATE,
            transitionInterpolator: new FlyToInterpolator(),
            transitionDuration: 1500
          })
        }
        let selected = [];
        let comparer = false;
        let geotype = false;
        setSelDC(options.datacomparison['selected']);
        if( stateOptions.maptype === 'city' && (stateOptions.city == '' || !stateOptions.city) ){
          const centroidLayer = getCentroidsLayers(stateOptions.city, clickCentroids);
          setLayers([centroidLayer]);
          setIsDC(stateOptions.datacomparison['selected']!=='none' && stateOptions.datacomparison['selected']!=='');
          return;
        }

        for (const option in stateOptions) {
          if (option === 'maptype') {
            continue;
          }
          // if option is from black board ( filters ) or check if a filter is selected
          // the ones selected are added to selected array
          if ( stateOptions[option] && option !== 'ratescases' && option !== 'year' && stateOptions[option].hasOwnProperty('selected') && stateOptions[option]['selected'] !== 'none'  && stateOptions[option]['selected'] !== '') {
            if (stateOptions[option]['datacomparison']) {
              comparer = true;
            }
            if (stateOptions[option]['geographytype']) {
              geotype = true;
            }
            selected.push(stateOptions[option]['selected']);
          }
        }
        if(selected.length === 1 && !selected.includes('overall') && stateOptions.latestValue === 'reset'){
          selected.push('overall');
        }
        // if nothing is selected or is just comparer selected, set overall selected
        if(stateOptions.maptype !== 'city') {
          if ((!selected.length || (selected.length === 1 && comparer)) && stateOptions['overall']) {
            selected.push('overall');
          }
        } else {
        // if nothing is selected or is just comparer selected, set overall selected
        if ((!selected.length || (selected.length === 2 && (comparer && geotype))
        || selected.length === 1 && (comparer || geotype)) && stateOptions['overall']) {
          selected.push('overall');
        }
        }
        if (!selected.length) {
          selected.push('overall');
        }
        // console.log("SELECTED ", selected);
        let dataFilters = {
          selected: selected,
          datatype: stateOptions.datatype,
          ratescases: stateOptions.ratescases['selected']
        };
        if(stateOptions.latestValue == 'datatype') {
          if(stateOptions.maptype === 'city') {
            const order = ['overall', stateOptions.geographytype['selected']];
            order.sort();
            dataFilters = {
              selected: order,
              datatype: stateOptions.datatype,
              ratescases: 'rates',
              cityname: stateOptions.city
            };
          }  else {
            dataFilters = {
              selected: ['overall'],
              datatype: stateOptions.datatype,
              ratescases: 'rates'
            };
          }
        }
        if(stateOptions.maptype === 'city') {
          dataFilters['cityname'] = stateOptions.city;
        }
        if (stateOptions.year['selected'] !== 'none' && stateOptions.year['selected'] !== 'hidden' && stateOptions.year['selected']) {
          dataFilters['year'] = stateOptions.year['selected'];
        }
        let val = undefined;
        if( stateOptions.maptype == 'county' ) {
          val = await asyncGetCountyFilters(dataFilters);
          getCountyPopup().then( (response) => {
            setPopups(response.data);
          });
        } else if( stateOptions.maptype == 'state' ) {
          val = await asyncGetStateFilters(dataFilters);
          getStatePopup().then( (response) => {
            setPopups(response.data);
          });
        } else if( stateOptions.maptype == 'city' ) {
          val = await asyncGetCityFilters(dataFilters);
          getCityPopup(stateOptions.city).then( (response) => {
            setPopups(response.data);
          });
        }
        if( val )  {
          let newOptions = updateFilters(val);
          newOptions.areatype = stateOptions.areatype;
          //RESIZE IF COMPARISON is missing
          setIsDC(stateOptions.datacomparison['selected']!=='none' && stateOptions.datacomparison['selected']!=='');
          let year = val.year;
          if (newOptions.datatype === 'PrEP' || newOptions.datatype === 'PNR') {
            year = 'hidden';
          }
          let dataValues = undefined;
          if(stateOptions.maptype === 'county' ) {
            dataValues = await getValuesOfTablesCounty(val.mainmaptable, val.mainmapvariable, val.mainmaplegendvariable, val.mainmaptable2, year);
          } else if ( stateOptions.maptype === 'state' ) {
            dataValues = await getValuesOfTablesState(val.mainmaptable, val.mainmapvariable, val.mainmaplegendvariable, val.mainmaptable2, year)
          } else if ( stateOptions.maptype === 'city' ) {
            dataValues = await getValuesOfCityTables(val.mainmaptable, val.mainmapvariable, val.mainmaplegendvariable, val.mainmaptable2, val.year, val.city);
          }
          loadLayers(newOptions,dataValues, val);
        } else {
          endLoader();
        }
      }
    }
    if(stateOptions.update) {
      fetchData();
    }

  }, [stateOptions, areaF]);


  useEffect(()=>{
    if(isMobile) {
      setInitialViewState(
        {...initialViewState, latitude: 30, zoom: 2}
      );
    }
  },[isMobile]);
  const loadLayers = async (newOptions, data, val) => {
    // downloadArray for downloadService.countyData is ommited for now
    // and comparison too
    const mainmapvariableyn = newOptions.mainmapvariable.replace('rate', '').replace('case', '').replace('count', '') + 'yn';
    // add to countyvalues, the values that come from cartodb (data)
    // county values is used to map via geo_id to get data for popups
    // if(newOptions.maptype === 'county') {
    //   if (countyValues.length > 0) {
    //     setCountyValues([]);
    //   }
    // } else if ( newOptions.maptype === 'state') {
    //   if (stateValues.length > 0) {
    //     setStateValues([]);
    //   }
    // } else if ( newOptions.maptype === 'city') {
    //   if (cityValues.length > 0) {
    //     setCityValues([]);
    //   }
    // }
    let newStateValues = [];
    let newCountyValues = [];
    let newCityValues = [];
    for (const d of data) {
      const pos = d['mainmaplegendvariable'] - 1;
      
      let zip = undefined;
      let census = undefined;
      let hovernote = undefined;
      let cartodb_id = undefined;
      if(newOptions.maptype === 'city') {
        zip = d.zip;
        census = d.census;
        hovernote = d.hovernote;
        cartodb_id = d.cartodb_id;
      }
      let yn = d[mainmapvariableyn];
      if (yn == null || yn == undefined) {
        yn = true;
      }
      let corrwarning = d.corrwarning;
      if(newOptions.maptype != 'city'){
        if (corrwarning == null || corrwarning == undefined) {
          corrwarning = 1;
        }
      }
      if(newOptions.maptype === 'county') {
        newCountyValues.push({...d, value: d[newOptions.mainmapvariable], yn: yn, pos: pos, corrwarning: corrwarning, geo_id2: d.geo_id2});
        dcountyValues.push({geo_id2: d.geo_id2, value: d[newOptions.mainmapvariable]});
        updateDValues('countyData',dcountyValues);
      } else if ( newOptions.maptype === 'state') {
        newStateValues.push({state: d.state, value: d[newOptions.mainmapvariable], yn: yn, pos: pos, corrwarning: corrwarning , year: d.year});
        dstateValues.push({state: d.state, value: d[newOptions.mainmapvariable]});
        updateDValues('stateData',dstateValues);
      } else if ( newOptions.maptype === 'city' ) {
        newCityValues.push({...d, value: d[newOptions.mainmapvariable], yn: yn, pos: pos});
        dcityValues.push({cartodb_id: cartodb_id, city: d.cityname, value: d[newOptions.mainmapvariable], zip: zip, census: census, hovernote: hovernote});
        updateDValues('cityData',dcityValues);
      }
    }
    // esto lo cuelga todo, watch out
    if(newOptions.maptype == 'county') {
      setCountyValues(newCountyValues);
    } else if ( newOptions.maptype == 'state') {
      setStateValues(newStateValues);
    } else {;
      setCityValues(newCityValues);
    }


    // if(!isMobile){
      loadMapLayers(newOptions,data, val);
    // }
  }
  useEffect(()=>{
    afterLoad();
  },[layers,layersTwo]);
  const loadDCCity = async (newOptions) => {
    const allLayers = [];
    // to zoom needs
    const geotype = newOptions.geographytype['selected']?newOptions.geographytype['selected']:undefined;
    // let queryCity = getCityQuery(stateOptions);
    let bbox = await getBoundingBox(geotype,stateOptions.city);
    let viewportWebMercator = new WebMercatorViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const {longitude, latitude, zoom} = viewportWebMercator.fitBounds([[bbox.bboxx1, bbox.bboxy1], [bbox.bboxx2, bbox.bboxy2]], {
        padding: 100
    });
    viewportWebMercator = {
        // ...initialViewState,
        longitude,
        latitude,
        zoom: zoom,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
    };
    setInitialViewState2(viewportWebMercator);
    // end zoom needs
    let data = await dataComparisonCity(newOptions, comparisonOptions);
    setCityComparison(data);
    let comparer = search(newOptions, comparisonOptions);
    if(comparer[0]) {
      let queryCity = getGeoJsonCity(newOptions.city, newOptions.geographytype['selected']);
      const availableColors = comparer[0]['legendcolors'];
      const colors = transformLegendColorsToHex(availableColors);
      let opacity = [];
      const color = [];
      const filtersAttrib = new Array(30);
      let attribSearch = undefined;
      let attribValue = undefined;
      let parser = '';
      const type = geotype === 'zipcode' ? 'zip' : 'geoid10';
      if (geotype === 'zipcode') {
        attribSearch = 'zip';
        attribValue = 'zip';
      } else if (geotype === 'censustract') {
        attribSearch = 'geoid10';
        attribValue = 'census';
        parser = '::bigint';
      } else if (geotype === 'communityarea') {
        attribSearch = 'area_numbe';
        attribValue = 'area';
      } else if (geotype === 'ward') {
        attribSearch = 'ward';
        attribValue = 'ward';
      }
      for (const d of data) {
        if (!d['legendpos']) {
          continue;
        }
        // if (d['legendpos'] < 0) {
        //   d['legendpos']++;
        // }
        let valueData = +d['legendpos'];
        switch (valueData) {
          case -9:
            valueData = -3;
            break;
          case -4:
            valueData = -2;
            break;
          case -2:
            valueData = -1;
            break;
          case -1:
            valueData = 0;
            break;
          default:
            break;
        }
        if (valueData + 3 < 0) {
          continue;
        }
        const filter = filtersAttrib[valueData + 3]?filtersAttrib[valueData + 3]:[];
        let add = d.zipcensus;
        if (type === 'geoid10') {
          add = '' + add;
        }
        filter.push(add);
        filtersAttrib[(valueData + 3)] = filter;
      }
      colors.forEach((value, index) => {
        opacity.push(value.opacity);
        color.push(value.color);
        if(filtersAttrib[index]) {
          let query =  `${queryCity} ${filtersAttrib[index]?(` AND ${type}${parser} IN (` + filtersAttrib[index].toString()) + ')':''}`;
          let newLayer = new CartoLayer({
            type: MAP_TYPES.QUERY,
            pickable: true,
            id: 'color'+index+"map2",
            data: query,
            getFillColor: [value['colorB'][0],value['colorB'][1],value['colorB'][2],( value['colorB'][3] * (slider/100.0)  )],
            stroked: true,
            lineWidthMinPixels: 1.2,
            getLineColor: [42, 47, 54,70]
          });
          allLayers.push(newLayer);
        }
      });

      setLayersTwo(allLayers);
    }

  }
  const loadDCLayers = async (newOptions) => {
    const allLayers = [];
    let data = undefined ;
    if(newOptions.maptype === 'county') {
      data = await dataComparisonCounty(newOptions, comparisonOptions);
      setCountyComparison(data);
    }
    if(newOptions.maptype === 'state') {
      data = await dataComparisonState(newOptions, comparisonOptions);
      setStateComparison(data);
    }
    let comparer = search(newOptions, comparisonOptions);
    if(comparer[0]) {
      const availablecolors = comparer[0]['legendcolors'];
      const colors = transformLegendColorsToHex(availablecolors);
      let opacity = [];
      const color = [];
      const filtersAttrib = new Array(30);
      for (const d of data) {
        let valueData = +d['legendpos'];
        switch (valueData) {
          case -9:
            valueData = -3;
            break;
          case -4:
            valueData = -2;
            break;
          case -2:
            valueData = -1;
            break;
          case -1:
            valueData = 0;
            break;
          default:
            break;
        }
        const filter = filtersAttrib[valueData + 3]?filtersAttrib[valueData + 3]:[];
        if(newOptions.maptype === 'county') {
          filter.push(d.geo_id2);
        } else if ( newOptions.maptype === 'state') {
          filter.push(`'${d.state}'`);
        }
        filtersAttrib[(valueData + 3)] = filter;
      }
      let tableQuery = newOptions.maptype === 'county'?CARTO.COUNTY_TABLE:(newOptions.maptype === 'state'?CARTO.STATE_TABLE:'');
      let attribQuery = newOptions.maptype === 'county'?'geo_id2':(newOptions.maptype === 'state'?'name':'');
      let areatypequery = '';
      if(stateOptions.maptype === 'county' && stateOptions.areatype != 'all') {
        let arrayofvalues = stateOptions.areatype === 'urban'?urban:rural;
        areatypequery = ` AND  ${attribQuery} IN ( select value from urbanruralzipcodes where area = '${stateOptions.areatype === 'urban'?'urban':'rural'}' )`
      }
      colors.forEach((value, index) => {
        opacity.push(value.opacity);
        color.push(value.color);

        if(filtersAttrib[index]) {
          let query;
          let queryCountyDCLarge = getQueryCountiesDC(index, comparer[0], newOptions.maptype === 'county'?'geo_id2':(newOptions.maptype === 'state'?'state':'') );
          // if(filtersAttrib[index].length > 5 && !queryCountyDCLarge.includes('undefined')) { 
            query =  `SELECT * FROM ${tableQuery} ${filtersAttrib[index]?(`where ${attribQuery} IN (` + queryCountyDCLarge) + ')':''} ${areatypequery}`;
          // } else {
          //   query =  `SELECT * FROM ${tableQuery} ${filtersAttrib[index]?(`where ${attribQuery} IN (` + filtersAttrib[index].toString()) + ')':''} ${areatypequery}`;
          // }
          let newLayer = new CartoLayer({
            type: MAP_TYPES.QUERY,
            pickable: true,
            id: 'color'+index+"map2"+Math.random(),
            data: query,
            getFillColor: [value['colorB'][0],value['colorB'][1],value['colorB'][2],( value['colorB'][3] *  (slider/100.0)  )],
            stroked: false,
            lineWidthMinPixels: 0
          });
          allLayers.push(newLayer);
        }
      });
      if(newOptions.maptype === 'county') {
        allLayers.push(layerStatesBorders2);
        allLayers.push(layerCountiesBorders2);
      } else if ( newOptions.maptype === 'state') {
        allLayers.push(layerStatesBorders2);
      }
      setLayersTwo(allLayers);
      endLoader();

      // updateState(newOptions);

    }

  }
  const getQueryCountiesDC = (index, comparer, attribQuery) => {
    let query;
    let { maplegendvariable } = comparer;
    const tableCOUNTYDC = CARTO.COUNTY_COMPARER;
    const tableStateDC = CARTO.STATE_COMPARER;
    let queryCondition
    switch(index) {
      case 0:
        queryCondition = `${maplegendvariable} + 9 = ${index}`;
        break;
      case 1:
        queryCondition = `${maplegendvariable} + 5 = ${index}`;
        break;
      case 2:
        queryCondition = `${maplegendvariable} + 4 = ${index}`;
        break;
      case 3:
        queryCondition = `${maplegendvariable} + 4 = ${index}`;
        break;
      default:
        queryCondition = `${maplegendvariable} + 3 = ${index}`;
        break;
    } 
    query = `SELECT ${attribQuery} FROM ${attribQuery === 'state'?  tableStateDC : tableCOUNTYDC} t1
    WHERE  ${queryCondition}`;
    return query;
  }
  const getQueryCounties = (index, val, attribQuery) => {
    let query;
    // val.mainmaptable, val.mainmapvariable, val.mainmaplegendvariable, val.mainmaptable2, year
    let year = val.year;
    if (options.datatype === 'PrEP' || options.datatype === 'PNR') {
      year = 'hidden';
    }
    let mainmaptable = val.mainmaptable;
    let mainmapvariable = val.mainmapvariable;
    let mainmaplegendvariable = val.mainmaplegendvariable;
    let mainmaptable2 = val.mainmaptable2;
    let whereCondition;
    switch(index) {
      case 0:
        whereCondition = `${mainmaplegendvariable} + 9 = ${index}`;
        break;
      case 1:
        whereCondition = `${mainmaplegendvariable} + 5 = ${index}`;
        break;
      case 2:
        whereCondition = `${mainmaplegendvariable} + 4 = ${index}`;
        break;
      case 3:
        whereCondition = `${mainmaplegendvariable} + 4 = ${index}`;
        break;
      default:
        whereCondition = `${mainmaplegendvariable} + 3 = ${index}`;
        break;
    }
    
    if (!mainmaptable2 || mainmaptable2 === 'none') {
      let query;
      if (year !== 'hidden') {
        // tslint:disable-next-line:max-line-length
        query = (`SELECT t1.${attribQuery} FROM ${environment.table}${mainmaptable} t1 WHERE year=${year} and t1.${whereCondition}`);
      } else {
        query = (`SELECT t1.${attribQuery} FROM ${environment.table}${mainmaptable} t1 WHERE  t1.${whereCondition}` );
      }
      return query;
    } else {
      let query;
      let nameAttrib = attribQuery === 'state' ? attribQuery : 'countyname' 
      if (year !== 'hidden') {
        // tslint:disable-next-line:max-line-length
        query = (`SELECT mt.${attribQuery} FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.${nameAttrib}=mt2.${nameAttrib} and mt2.year=${year} and mt.year=mt2.year AND mt2.${whereCondition}`);
      } else {
        // tslint:disable-next-line:max-line-length
        query = (`SELECT mt.${attribQuery} FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.${nameAttrib}=mt2.${nameAttrib} AND mt2.${whereCondition}`);
      }
      return query;
    }
  }
  const getQueryCities = (index, val, attribValue) => {
    // val.mainmaptable, val.mainmapvariable, val.mainmaplegendvariable, val.mainmaptable2, year 
    let year = val.year;
    if (options.datatype === 'PrEP' || options.datatype === 'PNR') {
      year = 'hidden';
    }
    let city = val.city;
    let mainmaptable = val.mainmaptable;
    let mainmaplegendvariable = val.mainmaplegendvariable;
    let mainmaptable2 = val.mainmaptable2;
    let queryCondition;
    switch(index) {
      case 0:
        queryCondition = `${mainmaplegendvariable} + 9 = ${index}`;
        break;
      case 1:
        queryCondition = `${mainmaplegendvariable} + 5 = ${index}`;
        break;
      case 2:
        queryCondition = `${mainmaplegendvariable} + 4 = ${index}`;
        break;
      case 3:
        queryCondition = `${mainmaplegendvariable} + 4 = ${index}`;
        break;
      default:
        queryCondition = `${mainmaplegendvariable} + 3 = ${index}`;
        break;
    }

    if(city) {
      city = city.replace(/oakland\/alameda/g, 'oakland');
      if (!mainmaptable2 || mainmaptable2 === 'none') {
        let q;
        if (year !== 'hidden') {
          // tslint:disable-next-line:max-line-length
          q= `SELECT t1.${attribValue} FROM ${environment.table}${mainmaptable} t1 WHERE year=${year} AND  (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%') and ${queryCondition}`
        } else {
          q = `SELECT t1.${attribValue} FROM ${environment.table}${mainmaptable} t1 WHERE (LOWER(REPLACE(city, ', ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%') and ${queryCondition}`
        }
        return q;
      } else {
        let q;
        if (year !== 'hidden') {
          // tslint:disable-next-line:max-line-length
          q = `SELECT mt.*, mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.Cityname=mt2.Cityname and mt2.year=${year} and mt.year=mt2.year and (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%') and ${queryCondition}`;
        } else {
          // tslint:disable-next-line:max-line-length
          q = `SELECT mt.*, mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.Cityname=mt2.Cityname and (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%') and ${queryCondition}`;
        }
        return q;
      }
    } else {
      return '';
    }
  }
  const countyTable = (datatype) => {
    // if (datatype === 'PrEP' || datatype === 'PNR') {
      return CARTO.COUNTY_TABLE2;
    // } else {
    //   return CARTO.COUNTY_TABLE;
    // }
  }
  const loadMapLayers = (newOptions,data, val) => {
    if (newOptions.datacomparison['selected'] !== 'none' && newOptions.datacomparison['selected'] !== '') {
      if(newOptions.maptype === 'county' || newOptions.maptype === 'state') {
        loadDCLayers(newOptions);
      } else if (newOptions.maptype === 'city') {
        loadDCCity(newOptions);
      }

    } else {
      setLayersTwo([]);
    }
    let allLayers = [];


    if( newOptions.maptype === 'city' ) {
      loadCity(newOptions,data, val);
    } else {
      let legendsToTransform = newOptions.legendcolors;
      if (options.ratescases.selected === 'cases' && options.caseslabel === 'Users') {
        // THIS SPECIAL CASE IS DUE TO LEGEND COLORS DISCORDINATION, sometime should be fixed directly on permutation file
        legendsToTransform += ',179-220-220-220';
      }
      const colors = transformLegendColorsToHex(legendsToTransform);
      let opacity = [];
      const color = [];
      const filtersAttrib = new Array(30);
      console.log('Data', data);
      for (const d of data) {
        let mainmaplegendvariable = +d['mainmaplegendvariable'];
        switch (mainmaplegendvariable) {
          case -9:
            mainmaplegendvariable = -3;
            break;
          case -4:
            mainmaplegendvariable = -2;
            break;
          case -2:
            mainmaplegendvariable = -1;
            break;
          case -1:
            mainmaplegendvariable = 0;
            break;
          default:
            break;
        }
        if (d.county && d?.county?.includes('Liberty')) {
          console.log('d', d);
        }
        if (mainmaplegendvariable + 3 < 0) {
          continue;
        }
        const filter = filtersAttrib[mainmaplegendvariable + 3]?filtersAttrib[mainmaplegendvariable + 3]:[];
        if(newOptions.maptype === 'county') {
          filter.push(d.geo_id2);
        } else if ( newOptions.maptype === 'state') {
          filter.push(`'${d.state}'`);
        }
        filtersAttrib[(mainmaplegendvariable + 3)] = filter;
        if(+d['cartodb_id'] == 27135) {
          // console.log("dososkso", d, filter, mainmaplegendvariable, newOptions, filtersAttrib);
        }
      }
      // add layer for each color, just for data with geo_id2 ( almost all )
      let newPrint = {
        colors:[],
        filterField:'',
        filters: '',
        table:'',
        type:''
      };
      let tableQuery = newOptions.maptype === 'county'? countyTable(newOptions.datatype):(newOptions.maptype === 'state'?CARTO.STATE_TABLE:'');
      let attribQuery = newOptions.maptype === 'county'?'geo_id2':(newOptions.maptype === 'state'?'name':'');
      let areatypequery = '';
      if(stateOptions.maptype === 'county' && stateOptions.areatype != 'all') {
        let arrayofvalues = stateOptions.areatype === 'urban'?urban:rural;
        areatypequery = ` AND  ${attribQuery} IN ( select value from ${CARTO.URBAN_RURAL_TABLE} where area = '${stateOptions.areatype === 'urban'?'urban':'rural'}' )`
      }
      let addAttribQuery = ' state ';
      let areaQuery = '';
      // console.log("AREA F length ", areaF.length, areaF);
      if(hasParams && areaF.length >= 2) {
          areaF.shift();
          areaF.shift();
          for( let i = 0 ; i < areaF.length ; ++i ){
            areaF[i] = `'`+areaF[i]+`'`;
          }
          areaQuery = ` AND ${addAttribQuery} IN ( ${areaF.toString()} )`
      }
      // console.log("AREA QUERY",areaQuery);
      const addcolortoMap = (value, index) => {
        let query = '';
        let queryCountyLarge = getQueryCounties(index, val, newOptions.maptype === 'county'?'geo_id2':(newOptions.maptype === 'state'?'state':''));
        // if(filtersAttrib[index]?.length > 800 && !queryCountyLarge.includes('undefined')) { 
          
          query = `SELECT * FROM ${tableQuery} ${filtersAttrib[index]?(`where ${attribQuery} IN (` + queryCountyLarge) + ')':''} ${areatypequery} ${areaQuery}`;
        // } else {
        //   query =  `SELECT * FROM ${tableQuery} ${filtersAttrib[index]?(`where ${attribQuery} IN (` + filtersAttrib[index].toString()) + ')':''} ${areatypequery} ${areaQuery}`;
        // }
        
        let newLayer = new CartoLayer({
          type: MAP_TYPES.QUERY,
          pickable: true,
          id: 'color'+index,
          data: query,
          getFillColor: [value['colorB'][0],value['colorB'][1],value['colorB'][2],( value['colorB'][3] *  (slider/100.0) )],
          stroked: false,
          lineWidthMinPixels: 0,
          updateTriggers: {
            getFillColor: slider
          }
        });
        allLayers.push(newLayer);
      }
      colors.forEach((value, index) => {
        opacity.push(value.opacity);
        color.push(value.color);
        if(filtersAttrib[index]) {
          addcolortoMap(value, index);      
        }
        if(colors.length - 1 === index && colors.length < 10 && filtersAttrib[14]) {
          addcolortoMap(value, 14);
        }
      });
      newPrint.colors = color;
      newPrint.filters = filtersAttrib;
      newPrint.filterField = attribQuery;
      newPrint.table = tableQuery;
      setPrintImageValues({...newPrint});
      if(newOptions.maptype === 'county') {
        if(!hasParams && areaF.length <= 0 && type!='frame'){
          allLayers.push(layerStatesBorders);
          allLayers.push(layerCountiesBorders);
        } else {
          let newStateBorders =  new CartoLayer({
            type: MAP_TYPES.QUERY,
            pickable: true,
            id: 'stateBorders',
            data: `SELECT * FROM state2010 where true ${areaQuery}`,
            getFillColor: [255,255,255,0],
            stroked: true,
            lineWidthMinPixels: 1.2,
            getLineColor: [42, 47, 54,220]
          });
          let newCountyBorders = new CartoLayer({
            type: MAP_TYPES.QUERY,
            pickable: true,
            id: 'countyborders',
            data: `SELECT * FROM ${CARTO.COUNTY_TABLE} where true ${areaQuery}` ,
            getFillColor: [180,180,180,0],
            stroked: true,
            lineWidthMinPixels: 1,
            getLineColor: [ 98, 110, 120, 50 ],
          });
          allLayers.push(newStateBorders);
          allLayers.push(newCountyBorders);
        }

      } else if ( newOptions.maptype === 'state') {

        if(!hasParams && areaF.length <= 0 && type!='frame'){
          allLayers.push(layerStatesBorders);
        } else {
          let newStateBorders =  new CartoLayer({
            type: MAP_TYPES.QUERY,
            pickable: true,
            id: 'stateBorders',
            data: `SELECT * FROM state2010 where true ${areaQuery}`,
            getFillColor: [255,255,255,0],
            stroked: true,
            lineWidthMinPixels: 1.2,
            getLineColor: [42, 47, 54,220]
          });
          allLayers.push(newStateBorders);
        }
      }
      if(overlays.isDistricts) {
        allLayers = [...allLayers, ...getDistrictLayer()];
      }
      setLayers(allLayers);
      newOptions.slider = slider;
      updateState(newOptions);
      // endLoader();
    }
  }

  const loadCity = async (newOptions,data, val) => {
    const allLayers = [];
    const geotype = newOptions.geographytype['selected']?newOptions.geographytype['selected']:undefined;
    let queryCity = getCityQuery(stateOptions);
    const borderCity = new CartoLayer({
      type: MAP_TYPES.QUERY,
      pickable: true,
      id: 'cityborders',
      data: queryCity,
      getFillColor: [180,180,180,10],
      stroked: true,
      lineWidthMinPixels: 2,
      getLineColor: [ 98, 110, 120, 50 ],
    });
    allLayers.push(borderCity);
    let bbox = await getBoundingBox(geotype,stateOptions.city);
    let viewportWebMercator = new WebMercatorViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const {longitude, latitude, zoom} = viewportWebMercator.fitBounds([[bbox.bboxx1, bbox.bboxy1], [bbox.bboxx2, bbox.bboxy2]], {
        padding: (isMobile?(isDC?100:70):100)
    });
    viewportWebMercator = {
        ...initialViewState,
        longitude,
        latitude,
        zoom,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
    };
    setInitialViewState(viewportWebMercator);
    const colors = transformLegendColorsToHex(newOptions.legendcolors);
    let opacity = [];
    const color = [];
    const filtersAttrib = new Array(30);
    let attribSearch = undefined;
    let attribValue = undefined;
    let table = undefined;
    let type = undefined;
    let parser = '';
    let newPrint = {
      colors:[],
      filterField:'',
      filters: '',
      table:'',
      type:''
    };
    // let cityBorderLayer = new CartoLayer({
      // pickable: true,
    //   id: 'cityLayer',
    //   data: queryCity,
    //   getFillColor: [value['colorB'][0],value['colorB'][1],value['colorB'][2],(value['colorB'][3])],
    //   stroked: false,
    //   lineWidthMinPixels: 0
    // });
    if (geotype === 'zipcode') {
      attribSearch = 'zip';
      attribValue = 'zip';
      table = CARTO.CITY_TABLE;
      type = 'number';
    } else if (geotype === 'censustract') {
      attribSearch = 'geoid10';
      attribValue = 'census';
      parser = '::bigint';
      table = CARTO.CITY_CENSUS;
      type = 'string';
    } else if (geotype === 'communityarea') {
      attribSearch = 'area_numbe';
      attribValue = 'area';
      table = CARTO.CITY_COMMUNITY;
      type = 'number';
    } else if (geotype === 'ward') {
      attribSearch = 'ward';
      attribValue = 'ward';
      table = CARTO.CITY_WARD;
      type = 'number';
    }
    // console.log('data', data.map((sdd) => sdd));
    for (const d of data) {
      let mainmaplegendvariable = +d['mainmaplegendvariable'];
      // mainmaplegendvariable = Math.max(-3, mainmaplegendvariable);
      switch (mainmaplegendvariable) {
        case -9:
          mainmaplegendvariable = -3;
          break;
        case -4:
          mainmaplegendvariable = -2;
          break;
        case -2:
          mainmaplegendvariable = -1;
          break;
        case -1:
          mainmaplegendvariable = 0;
          break;
        default:
          break;
      }

      if(d.zip == 30185 || d.zip === 30116) {
        console.log('d', mainmaplegendvariable, '+3', d.zip, d, filtersAttrib[mainmaplegendvariable + 3], mainmaplegendvariable);
      }
      if (mainmaplegendvariable + 3 < 0) {
        continue;
      }
      const filter = filtersAttrib[mainmaplegendvariable + 3]?filtersAttrib[mainmaplegendvariable + 3]:[];
      filter.push(d[attribValue]);
      filtersAttrib[(mainmaplegendvariable + 3)] = filter;
    }
    const addColorsCityToMap = (value, index) => {
        let query =  `${queryCity} ${filtersAttrib[index]?(`${(geotype==='communityarea' || geotype === 'ward')?' WHERE ':' AND '} ${attribSearch}${parser} IN (` + filtersAttrib[index].toString()) + ')':''}`;
        let queryCityLarge = getQueryCities(index, val, attribValue);
        // console.log('query city Large', queryCityLarge);
        // if(filtersAttrib[index].length > 20 && !queryCityLarge.includes('undefined')) { 
          query =  `${queryCity} ${filtersAttrib[index]
            ?
            (`${(geotype==='communityarea' || geotype === 'ward')?' WHERE ':' AND '} ${attribSearch}${parser} IN (` + queryCityLarge ) + ')'
            :
            ''
          }`;
        // } else {
        //   query =  `${queryCity} ${filtersAttrib[index]?(`${(geotype==='communityarea' || geotype === 'ward')?' WHERE ':' AND '} ${attribSearch}${parser} IN (` + filtersAttrib[index].toString()) + ')':''}`;
        // }
        // console.log('query', query);
        let newLayer = new CartoLayer({
          type: MAP_TYPES.QUERY,
          pickable: true,
          id: 'color'+index,
          data: query,
          getFillColor: [value['colorB'][0],value['colorB'][1],value['colorB'][2],( value['colorB'][3] *  (slider/100.0) )],
          updateTriggers: {
            getFillColor: slider
          },
          stroked: true,
          lineWidthMinPixels: 1.2,
          getLineColor: [42, 47, 54,70]
        });
        allLayers.push(newLayer);
    }
    // console.log('colors', colors);
    colors.forEach((value, index) => {
      opacity.push(value.opacity);
      color.push(value.color);
      if(filtersAttrib[index]) {
        // console.log('index reach', index);
        addColorsCityToMap(value, index);
      }
      // console.log('colors', colors.length, '-1' , index, colors.length - 1 === index, colors.length, colors.length < 10, 14, filtersAttrib[14]);
      if(colors.length - 1 === index && colors.length < 10 && filtersAttrib[14]) {
        addColorsCityToMap(value, 14);
      }
    });

    newPrint.colors = color;
    newPrint.filters = filtersAttrib;
    newPrint.filterField = attribSearch;
    newPrint.table = table;
    setPrintImageValues({...newPrint});
    allLayers.push(getCentroidsLayers(stateOptions.city, clickCentroids));
    
    setLayers(allLayers);
    newOptions.slider = slider;
    updateState(newOptions);
  }
  const addText = (text, title) => {
    if (text != '') {
      text = text + '<br />';
    }
    return text + title;
  }
  const  phone_transform = (phone) => {
    if (phone == null) {
      return '';
    }
    phone = phone.replace(/-/g, '');
    let p = phone.split('');
    p.splice(3, 0, '-');
    p.splice(7, 0, '-');
    return p.join('');
  }
  useEffect(()=>{
    updatePrintImage(printImageValues);
  },[printImageValues]);
  useEffect(()=>{

    const changeMobile  = window.innerWidth < 1024 ? true: false;
    setIsMobile(changeMobile);
    const changeProportion = (window.innerWidth/window.innerHeight) < 1.4;
    setIsSquare(changeProportion);
    const  callCompOpt = async ()=>{
      let compopt = await loadComparisonOptions()
      setComparisonOptions(compopt);
    }
    callCompOpt();
    getProviders().then((d)=>{
      setStateLocalStatisticsArray(d.data);
    })

    setTimeout(()=>{
      let inputCheck = inputEl.current;
      if(inputCheck && !hasParams) {
        let map = inputCheck.getMap();
        if(map){
        var geocoder = new MapboxGeocoder({
          accessToken: 'pk.eyJ1IjoiYWlkc3Z1IiwiYSI6ImNqOG44bDFyejE3MnIzM3J0cjJqejJjanQifQ.SEb-Tl7BdBPVTI4q5y_hWA',
          mapboxgl: mapboxgl,
          country: 'us',
          bbox: [-127.617188,24.367114,-59.765625,50.064192],
          flyTo: false,
          marker: false
        });
        geocoder.on('result',e => {
          let viewportWebMercator = new WebMercatorViewport({
            width: window.innerWidth,
            height: window.innerHeight,
          });

          const {longitude, latitude, zoom} = viewportWebMercator.fitBounds([ [e.result.bbox[0],e.result.bbox[1]], [e.result.bbox[2], e.result.bbox[3] ]], {
            padding: 140
          });
          setInitialViewState({...initialViewState, latitude: latitude, longitude: longitude, zoom: zoom , transitionInterpolator: new FlyToInterpolator(), transitionDuration: 500})
        });
        let geoElem = document.getElementById('geocoder')
        if(geoElem) {
          geoElem.appendChild(geocoder.onAdd(map));
        }
        }
      }

    },3600);



      // hacer las llamadas de los overlays
      getPrepFromCarto().then(({data})=> {
        let geojson = data;
        geojson.features = geojson.features.filter(feature => {
          return feature.geometry && feature.geometry.coordinates;
        });
        let newGeojson = [];
        let cont = 0;
        for (const feature of geojson.features) {

          let text = '';
          text = addText(text, `<b><h3>Pre-Exposure Phophylaxis (PrEP) Services</h3></b>`);
          if (feature.properties.link) {
            text = addText(text, `<b>${feature.properties.title}</b>`);
          } else {
            text = addText(text, `${feature.properties.title}`);
          }
          text = addText(text,  `Address: ${feature.properties.streetaddr?(feature.properties.streetaddr+', '):''} ${feature.properties.streetad_1?feature.properties.streetad_1:''}, ${feature.properties.locality} ${feature.properties.region} ${feature.properties.postalcode}`);
          text = addText(text, `Phone: ${phone_transform('' + feature.properties.telephone)}`);
          if (feature.properties.link) {
            const multipleweb = feature.properties.link.split(',');
            const web_site = multipleweb[0].replace('||', '');
            text += `<br><a href="${web_site}" target="_blank">More Info</a>`;
          }
          feature.properties.popupTitle = text;
          feature.properties.marker = 'exposure';
          let coordinates = feature.geometry.coordinates;
          let newFeat = {...feature.properties, coordinates};
          newGeojson.push(newFeat);
        }
        setPrepGeojson(newGeojson);
      });
      getRyanFromCarto().then(({data})=> {
        let geojson = data;
        geojson.features = geojson.features.filter(feature => {
          return feature.geometry && feature.geometry.coordinates;
        });
        let newGeojson = [];
        for (const feature of geojson.features) {
          let text = '';
          text = addText(text, `<b><h3>Ryan White HIV/AIDS Medical Care Provider</h3></b>`);
          text = addText(text, `<b>${feature.properties.hpsa_nm}</b>`);
          text = addText(text, `Address: ${feature.properties.address}`);
          text = addText(text, `${feature.properties.city}, ${feature.properties.primary_state_nm}`);
          text = addText(text, `${feature.properties.zip_cd}`);
          feature.properties.popupTitle = text;
          feature.properties.marker = 'ryan';
          let coordinates = feature.geometry.coordinates;
          let newFeat = {...feature.properties, coordinates};
          newGeojson.push(newFeat);
        }
        setRyanGeojson(newGeojson);
      });
      getHousingFromCarto().then(({data})=> {
        let geojson = data ;
        geojson.features = geojson.features.filter(feature => {
          return feature.geometry && feature.geometry.coordinates;
        });
        let newGeojson = [];
        for (const feature of geojson.features) {
          let text = '';
          text = addText(text, `<b><h3>Housing Opportunities for Persons with AIDS</h3></b>`);
          text = addText(text, `<b>${feature.properties.organizati}</b>`);
          text = addText(text, `Address: ${feature.properties.streetaddr}, ${feature.properties.cityname}, ${feature.properties.state}, ${feature.properties.zipcode}`)
          text = addText(text, `Phone: ${phone_transform(feature.properties.mainphone)}`)
          if (feature.properties.organiza_1) {
            text += `<br><a href="http://${feature.properties.organiza_1}" target="_blank">More Info</a>`;
          }
          feature.properties.popupTitle = text;
          feature.properties.marker = 'housing';
          let coordinates = feature.geometry.coordinates;
          let newFeat = {...feature.properties, coordinates};
          newGeojson.push(newFeat);
        }
        setHousingGeojson(newGeojson);
      });
      getHivFromCarto().then(({data})=> {
        let geojson = data;
        geojson.features = geojson.features.filter(feature => {
          return feature.geometry && feature.geometry.coordinates;
        });
        let newGeojson = [];
        for (const feature of geojson.features) {
          let index = '';
          let text = '';
          
          text = addText(text, '<h3> HIV Testing Site </h3>');
          // text = addText(text, `{}`)
          text += `<b>${feature.properties.organization_name}</b> <br>`;
          text += `Address: ${feature.properties.street_address_1}, ${feature.properties.city_name} ${feature.properties.state} ${feature.properties.zip_code}<br>`;
          text += `Phone: ${phone_transform(feature.properties.main_phone)}<br>`;
          if (feature.properties.web_site) {
            const web_site = feature.properties.web_site.replace('||', '');
            text += `<a href="${web_site}" target="_blank">More Info</a>`;
          }
          feature.properties.marker = 'hiv';
          feature.properties.popupTitle = text;

          let coordinates = feature.geometry.coordinates;
          let newFeat = {...feature.properties, coordinates};
          newGeojson.push(newFeat);
        }
        setHivGeojson(newGeojson);
      });
      getHcsFromCarto().then(({data})=>{
        let geojson = data;
        geojson.features = geojson.features.filter(feature => {
          return feature.geometry && feature.geometry.coordinates;
        });
        let newGeojson = [];
        for (const feature of geojson.features) {
          let index = '';
          let text = '';
          text = addText(text, '<h3> Health Center Services </h3>');
          // text = addText(text, `{}`)
          text += `<b>${feature.properties.site_name}</b> <br>`;
          text += `Address: ${feature.properties.site_address} ${feature.properties.site_city}, ${feature.properties.site_state_abbreviation}<br>`;
          text += `Phone: ${phone_transform(feature.properties.site_telephone_number)}<br>`;
          if( feature.properties.site_web_address ) {
            text = addText(text, '<a href="http://' + feature.properties.site_web_address + '" target="_blank">More info</a>');
          }
          feature.properties.marker = index;
          feature.properties.popupTitle = text;

          let coordinates = feature.geometry.coordinates;
          let newFeat = {...feature.properties, coordinates};
          newGeojson.push(newFeat);
        }
        setHcsGeojson(newGeojson);
      });
      getGeoJsonFromCarto().then(({data})=>{
        let geojson = data;
        let newGeojson =[];
      let i = 0;
          for (const feature of geojson.features) {
            let coordinates = feature.geometry.coordinates;
            let newFeature = {...feature.properties, coordinates};
            let index = '';
            let title = '';
            if (feature.properties.hptn) {
              index = index + '1';
              ++i;
              title = addText(title, '<b>HIV Prevention Trials Network (HPTN)</b>');
            }
            if (feature.properties.mtn) {
              index = index + '2';
              ++i;
              title = addText(title, '<b>Microbicide Trials Network (MTN)</b>');
            }
            if (feature.properties.hvtn) {
              index = index + '3';
              ++i;
              title = addText(title, '<b>HIV Vaccine Trials Network (HVTN)</b>');
            }
            if (feature.properties.actg) {
              index = index + '4';
              ++i;
              title = addText(title, '<b>AIDS Clinical Trials Group (ACTG)</b>');
            }
            if (feature.properties.impaact) {
              index = index + '5';
              ++i;
              title = addText(title, '<b>International Maternal Pediatric Adolescent AIDS Clinical Trials Group (IMPAACT)</b>');
            }
            while (index.length < 5) {
              index = index + '0';
            }
            feature.properties.marker = index;
            feature.properties.popupTitle = title;
            newGeojson.push({...newFeature, marker: index, popupTitle:title});
          }
        setGeneralGeojson(newGeojson);
      });


    getStateCodes().then((d) => {
      setStateCodes(d.data.rows);
    })
  },[]);
  useEffect(()=>{
    if(options && options.update) {
      startLoader();
      if(options.latestValue === 'maptype' || options.latestValue === 'datatype' || options.latestValue === 'ratescases' || options.latestValue === 'year') {
        setOptions({...options, update:true , areatype: 'all'});
      } else {
        setOptions({...options, update:true});
      }
      if (options.maptype == 'county') {
        setLayerCountiesBorders(
          new CartoLayer({
            type: MAP_TYPES.QUERY,
            pickable: true,
            id: 'countyborders',
            data: `SELECT * FROM ${countyTable(options.datatype)}`,
            getFillColor: [180,180,180,0],
            stroked: true,
            lineWidthMinPixels: 1,
            getLineColor: [ 98, 110, 120, 50 ],
          })
        )
      }
    }
  },[options]);
  const addDistricts = ()=>{
    let query = 'SELECT * FROM ' + CARTO.DISTRICT_TABLE;
    let districtsLayer = new CartoLayer({
      type: MAP_TYPES.QUERY,
      pickable: true,
      id: 'districts'+Math.random(),
      data: query,
      getFillColor: [255,255,255,0],
      stroked: true,
      getLineColor: [54,69,227],
      lineWidthMinPixels: 1,
      // getPosition: d => d.coordinates,
      getText: d => d.id,
      getSize: 12,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center'
    });
    if(stateOptions.maptype == 'county') {
      setLayers([...layers,districtsLayer, layerCountiesBordersExtra]);
    } else if(stateOptions.maptype == 'state') {
      setLayers([...layers,districtsLayer, layerStatesBordersExtra]);
    } else {
      setLayers([...layers,districtsLayer]);
    }
  }
  const getDistrictLayer = () => {
    let query = 'SELECT * FROM ' + CARTO.DISTRICT_TABLE;
    let districtsLayer = new CartoLayer({
      type: MAP_TYPES.QUERY,
      pickable: true,
      id: 'districts'+Math.random(),
      data: query,
      getFillColor: [255,255,255,0],
      stroked: true,
      getLineColor: [54,69,227],
      lineWidthMinPixels: 1,
      // getPosition: d => d.coordinates,
      getText: d => d.id,
      getSize: 12,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center'
    });
    let res = [];
    if(stateOptions.maptype == 'county') {
      res = [districtsLayer, layerCountiesBordersExtra];
    } else if(stateOptions.maptype == 'state') {
      res = [districtsLayer, layerStatesBordersExtra];
    } else {
      res = [districtsLayer];
    }
    return res;
  }
  const removeDistricts = () => {
    let filteredLayers = layers.filter((layer)=>{
      return !layer.id.includes("districts") && !layer.id.includes('extra');
    });
    setLayers(filteredLayers);
  }
  useEffect(()=>{
    setSlider(options.slider);
  },[options.slider])
  useEffect(()=>{
    if(overlays.isDistricts) {
      setTimeout(()=>{
        addDistricts();
      },1500);
    } else {
      removeDistricts();
    }
  },[overlays.isDistricts])
  useEffect(()=> {
    let currentLayers = layers;
    if(overlays.hivTesting) {
      const layerProps = {
        data: hivgeojson,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas: './assets/sprite/aidsvu@2x.png',
        iconMapping: './assets/sprite/aidsvu@2x.json',
        id: 'cluster-hivTesting'+ Math.random(),
         sizeScale: 60,
        markerDef: 'blue'
      };
      if(!currentLayers.includes(new IconClusterLayer(layerProps))) {
        currentLayers.push( new IconClusterLayer(layerProps));
      }

    } else {
      for(let i = 0; i < currentLayers.length; ++i) {
        if(currentLayers[i].id.includes('hivTesting')) {
          currentLayers.splice(i, 1);
        }
      }
    }

    if(overlays.prep){
      const layerProps = {
        data: prepgeojson,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas: './assets/sprite/aidsvu@2x.png',
        iconMapping: './assets/sprite/aidsvu@2x.json',
        id: 'cluster-prep'+ Math.random(),
        sizeScale: 60,
        markerDef: 'gray'
      };

      if(!currentLayers.includes(new IconClusterLayer(layerProps))) {
        currentLayers.push( new IconClusterLayer(layerProps));
      }
    } else {

      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('prep')
      });
    }
    if(overlays.hivRyanwhite){
      const layerProps = {
        data: ryangeojson,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas: './assets/sprite/aidsvu@2x.png',
        iconMapping: './assets/sprite/aidsvu@2x.json',
        id: 'cluster-ryanwhite',
        sizeScale: 60,
        markerDef: 'red'
      };

      if(!currentLayers.includes(new IconClusterLayer(layerProps))) {
        currentLayers.push( new IconClusterLayer(layerProps));
      }
    } else {

      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('ryanwhite')
      });
    }
    if(overlays.hopwa){
      const layerProps = {
        data: housinggeojson,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas: './assets/sprite/aidsvu@2x.png',
        iconMapping: './assets/sprite/aidsvu@2x.json',
        id: 'cluster-hopwa' + Math.random(),
        sizeScale: 60,
        markerDef: 'green'
      };

      if(!currentLayers.includes(new IconClusterLayer(layerProps))) {
        currentLayers.push( new IconClusterLayer(layerProps));
      }
    } else {
      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('hopwa')
      });
    }
    if(overlays.hptn || overlays.mtn) {
      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('hptnmtn')
      });
      let filteredValues = generalGeojson.filter((val)=> {
        return (overlays.hptn && val.hptn == 'X')||(overlays.mtn && val.mtn == 'X');
      })
      const layerProps = {
        data: filteredValues,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas: './assets/sprite/aidsvu@2x.png',
        iconMapping: './assets/sprite/aidsvu@2x.json',
        id: 'cluster-hptnmtn' + Math.random(),
        sizeScale: 60,
        markerDef: 'cyan'
      };

      if(!currentLayers.includes(new IconClusterLayer(layerProps))) {
        currentLayers.push( new IconClusterLayer(layerProps));
      }
    }  else {
      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('hptnmtn')
      });
    }
    if(overlays.actg || overlays.impaact) {
      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('actgimpaact')
      });
      let filteredValues = generalGeojson.filter((val)=> {
        return (overlays.actg && val.actg == 'X')||(overlays.impaact && val.impaact == 'X');
      })
      const layerProps = {
        data: filteredValues,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas: './assets/sprite/aidsvu@2x.png',
        iconMapping: './assets/sprite/aidsvu@2x.json',
        id: 'cluster-actgimpaact' + Math.random(),
        sizeScale: 60,
        markerDef: 'pink'
      };

      if(!currentLayers.includes(new IconClusterLayer(layerProps))) {
        currentLayers.push( new IconClusterLayer(layerProps));
      }
    }  else {
      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('actgimpaact')
      });
    }
    if(overlays.hvtn) {
      let filteredValues = generalGeojson.filter((val)=> {
        return (overlays.hvtn && val.hvtn == 'X');
      })
      const layerProps = {
        data: filteredValues,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas: './assets/sprite/aidsvu@2x.png',
        iconMapping: './assets/sprite/aidsvu@2x.json',
        id: 'cluster-hvtn',
        sizeScale: 60,
        markerDef: 'purple'
      };

      if(!currentLayers.includes(new IconClusterLayer(layerProps))) {
        currentLayers.push( new IconClusterLayer(layerProps));
      }
    }  else {
      currentLayers = currentLayers.filter(function(value,index, arr) {
        return !value.id.includes('hvtn')
      });
    }
    setTimeout(()=>{
      setLayers(currentLayers);
    },1200);
  },[overlays]);

  useEffect(()=>{

    const areas = async () => {
      let data = await searchStateOrRegionPrint(printValues.area);
      let state = await loadStatePrint();
      const geom = JSON.parse(data.the_geom);
      const centroid = JSON.parse(data.centroid);
      const envelope = JSON.parse(data.envelope);
      const center = centroid.coordinates;
      const bounds = envelope.coordinates;
      if(printValues.area.length > 0){
        let viewportWebMercator = new WebMercatorViewport({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        const {longitude, latitude, zoom} = viewportWebMercator.fitBounds(bounds, {
            padding: 140
        });
        viewportWebMercator = {
            ...initialViewState,
            longitude,
            latitude,
            zoom,
            transitionInterpolator: new FlyToInterpolator(),
            transitionDuration: 1500,
        };
        setInitialViewState(viewportWebMercator);


        if (printValues.area[0].toLowerCase().includes('north')) {
          printValues.area[0] = 'North';
        }
        if (printValues.area[0].toLowerCase().includes('south')) {
          printValues.area[0] = 'South';
        }
        if (printValues.area[0].toLowerCase() == 'region_west') {
          printValues.area[0] = 'West';
        }
        if (printValues.area[0].toLowerCase().includes('midwest')) {
          printValues.area[0] = 'Midwest';
        }
        const areaFilters = ['in', 'state'];
        if (printValues.area[0] === 'All') {
          state.forEach(info => {
            areaFilters.push(info.state);
          });
        }
        state.forEach(info => {
          if (info.abbrev.toLowerCase() === printValues.area[0].toLowerCase()) {
            areaFilters.push(info.state);
          }
          if (info.region && printValues.area[0].toLowerCase() === info.region.toLowerCase() ) {
            areaFilters.push(info.state);
          }
        });
        if(hasParams) {
          setAreaF(areaFilters);
        }
      }
    }
    areas();
  },[printValues.area]);
  useEffect(()=>{
    let newB = printValues.bounds.toString();
     newB = newB.replace(/]/g,'');
     newB = newB.replace(/\[/g,'');
    newB = JSON.parse('['+newB+']');
    // console.log("NEW B", newB);
    if(printValues.bounds .length <= 0) {
      return;
    }
    let viewportWebMercator = new WebMercatorViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const {longitude, latitude, zoom} = viewportWebMercator.fitBounds([[newB[0], newB[1]], [newB[2], newB[3]]], {
        padding: 100
    });
    viewportWebMercator = {
        // ...initialViewState,
        longitude,
        latitude,
        zoom: zoom,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
    };
    setInitialViewState(viewportWebMercator);
  },[printValues.bounds]);
  useEffect(()=>{
    setCurrentBaseMap('mapbox://styles/aidsvu/'+printValues.basemapType);
  },[printValues.basemapType])
  useEffect(()=>{
    if(isDC && options.maptype!='city') {
      setInitialViewState({...currentViewState, zoom:(isMobile?1:3.2)});
      setInitialViewState2({...currentViewState, zoom:(isMobile?1:3.2)});
    }
  },[isDC]);
  useEffect(()=>{
    setIsDraw(drawState.isOn);
  },[drawState]);
  useEffect(()=>{
    MapService.setDrawBounding(drawBounding);
  },[drawBounding]);
  useEffect(()=>{
    if(isDraw){
      setLayers([...layers, drawLayer]);
    } else {
      let newLayers = layers;
      for(let i = 0; i < newLayers.length; ++i) {
        if(newLayers[i].id.includes('geojson')) {
          newLayers.splice(i, 1);
        }
      }
      setLayers(newLayers);
      setDrawBounding(null);
    }
  },[isDraw])
  const getBoundsOnChange = ({viewState}) => {
    const viewport = new WebMercatorViewport(viewState);
    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    const {zoom, latitude, longitude} = viewState;
    printService.nw = nw;
    printService.se = se;
    printService.zoom = zoom;
    printService.latitude = latitude;
    printService.longitud = longitude;
    setCurrentBounds({nw:nw, se:se});
    setCurrentZoom(zoom);
    setInitialViewState2(viewState);
    setCurrentViewState(viewState);
  }
  const changeStateSecondMap = ({viewState}) => {
    setInitialViewState( viewState);
  }
  const afterLoad = () => {
    setTimeout(()=>{
      endLoader();
      endLoader();
    },2300);
  }
  const zoomIn =() => {
    let newZoom = initialViewState.zoom - 0.5;
    setInitialViewState({...initialViewState, latitude: currentViewState.latitude, longitude: currentViewState.longitude , zoom:newZoom, transitionInterpolator: new FlyToInterpolator(), transitionDuration: 500})
  }
  const zoomOut =() => {
    let newZoom = initialViewState.zoom + 0.5;
    setInitialViewState({...initialViewState, latitude: currentViewState.latitude, longitude: currentViewState.longitude,zoom:newZoom, transitionInterpolator: new FlyToInterpolator(),transitionDuration: 500})
  }
  useEffect(()=>{
    if(arrowValue) {
      setIsCollapsed(arrowValue.isCollapsed);
    }
  },[arrowValue])

  let firstProps = {
    id:"first",
    width:isDC?"50%":"100%",
    height:hasParams ? (isMobile?(hasParams?'100%':'87%'):'100%') : (isCollapsed?(isSquare?"95%":"83%"):(isSquare?"88%":"78%")),
    style: {top: isMobile?(hasParams?'5%':'14%'):( (hasParams)? '0%' : (isCollapsed?(isSquare?'5%':'12%'):(isSquare?'12%':'19%')) ) },
    initialViewState:initialViewState,
    controller:true,
    isDraw: isDraw,
    layers:layers,
    onViewStateChange: getBoundsOnChange,
  }
  let secondProps = {
    id:"second",
    width:isDC?"50%":'0%',
    height:isCollapsed?(isSquare?"95%":"83%"):(isSquare?"88%":"78%"),
    style:{top: (isMobile?'17%':(isCollapsed?(isSquare?'5%':'15%'):(isSquare?'12%':'19%'))),left:'49.8%' },
    initialViewState:initialViewState2,
    controller:true,
    isDraw: isDraw,
    layers:layersTwo,
    onViewStateChange:changeStateSecondMap,
  }
  let common = {
    options:options,
    stateValues:stateValues,
    countyValues:countyValues,
    cityValues:cityValues,
    stateCodes:stateCodes,
    popupsData:popups,
    stateComparison: stateComparison,
    countyComparison: countyComparison,
    cityComparison: cityComparison,
    comparisonOptions: comparisonOptions
  }
  const firstChild = (
    <StaticMap
          ref={inputEl}
          id="firstmap"
          reuseMaps
          mapStyle={currentBaseMap}
          mapboxApiAccessToken="pk.eyJ1IjoiYWlkc3Z1IiwiYSI6ImNqOG44bDFyejE3MnIzM3J0cjJqejJjanQifQ.SEb-Tl7BdBPVTI4q5y_hWA"
          // preventStyleDiffing
        >
        </StaticMap>
  )
  const secondChild = (
    <StaticMap
          id="secondmap"
          reuseMaps
          mapStyle={currentBaseMap}
          mapboxApiAccessToken="pk.eyJ1IjoiYWlkc3Z1IiwiYSI6ImNqOG44bDFyejE3MnIzM3J0cjJqejJjanQifQ.SEb-Tl7BdBPVTI4q5y_hWA"
          // preventStyleDiffing
        />
  )

  return (
    <div className="map-container">
      <FloatingButtons zoomin={zoomIn} zoomout={zoomOut} bounds={currentBounds} zoom={currentZoom} isCollapsed={isCollapsed} stateLocalStatisticsArray={stateLocalStatisticsArray} comparisonOpt={comparisonOptions} hasParams={hasParams}></FloatingButtons>
      <DeckGLWrapperTwice
        isDC={isDC} common={ common} firstProps={firstProps} firstChild={firstChild} secondProps={secondProps} secondChild={secondChild}
      >
      </DeckGLWrapperTwice>
      <div id="attribution" className={"mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-ctrl-bottom-left " + (hasParams&&isMobile?'carto-frame':'carto-attribution ')} style={{left: '100px', backgroundColor: 'transparent', marginBottom: (isCollapsed? '25px':'8px')}} >
          <a href="http://www.cartodb.com" className="map-logo cartodb-logo" target="_blank">
              <img src="assets/carto.svg" alt="CartoDB" title="CartoDB" className="carto-attribution-img"/>
          </a>
      </div>
    </div>
  )

  // return (
  //   <div className="map-container">
  //     <FloatingButtons zoomin={zoomIn} zoomout={zoomOut} bounds={currentBounds} zoom={currentZoom} isCollapsed={isCollapsed} stateLocalStatisticsArray={stateLocalStatisticsArray} comparisonOpt={comparisonOptions} hasParams={hasParams}></FloatingButtons>

  //     <DeckGLWrapper
  //       id="first"
  //       width={isDC?"50%":"100%"}
  //       height={hasParams ? '100%' : (isCollapsed?"81%":"76%")}
  //       style={{ top: isMobile?'unset':( (hasParams)? '0%' : (isCollapsed?'15%':'22%') ) }}
  //       initialViewState={initialViewState}
  //       controller={true}
  //       layers={layers}
  //       options={options}
  //       stateValues={stateValues} countyValues={countyValues} cityValues={cityValues} stateCodes={stateCodes}
  //       popupsData={popups}
  //       onViewStateChange= {getBoundsOnChange}
  //     >
  //       {
  //         popupList.map((popup, i) => {
  //           return (
  //             <PopupStatic key={'popup-' + i} data={popup} keep={true} close={removeExistant} isHovered={isHovered} options={options} popupsData={popups} stateValues={stateValues} countyValues={countyValues} cityValues={cityValues} stateCodes={stateCodes} />
  //           )
  //         })
  //       }
  //       {/* <PopupStatic data={object} keep={keep} insertPopup={insertPopup} close={closePopup}  isHovered={isHovered} options={options} popupsData={popups} stateValues={stateValues} countyValues={countyValues} cityValues={cityValues} stateCodes={stateCodes} /> */}

  //       <StaticMap
  //         ref={inputEl}
  //         id="firstmap"
  //         reuseMaps
  //         mapStyle={currentBaseMap}
  //         mapboxApiAccessToken="pk.eyJ1IjoiYWlkc3Z1IiwiYSI6ImNqOG44bDFyejE3MnIzM3J0cjJqejJjanQifQ.SEb-Tl7BdBPVTI4q5y_hWA"
  //         // preventStyleDiffing
  //       >
  //       </StaticMap>
  //     </DeckGLWrapper>
  //     {isDC &&
  //     <DeckGLWrapper
  //       id="second"
  //       width={isDC?"50%":'0%'}
  //       height={isCollapsed?"81%":"72%"}
  //       style={{top: (isCollapsed?'15%':'24%'),left:'49.7%' }}
  //       initialViewState={initialViewState2}
  //       controller={true}
  //       layers={layersTwo}
  //       onViewStateChange={changeStateSecondMap}
  //       getTooltip={x => {
  //         console.log('x', x);
  //         if (!keep && !hovered && popupList.length === 0) {
  //           setObject(x)
  //         }
  //         setHideObject(x);
  //       }}
  //     >
  //       <PopupStatic data={object} keep={keep} insertPopup={insertPopup} close={closePopup}  isHovered={isHovered} options={options} popupsData={popups} stateValues={stateValues} countyValues={countyValues} cityValues={cityValues} stateCodes={stateCodes} />
  //       <StaticMap
  //         id="secondmap"
  //         reuseMaps
  //         mapStyle={currentBaseMap}
  //         mapboxApiAccessToken="pk.eyJ1IjoiYWlkc3Z1IiwiYSI6ImNqOG44bDFyejE3MnIzM3J0cjJqejJjanQifQ.SEb-Tl7BdBPVTI4q5y_hWA"
  //         // preventStyleDiffing
  //       />
  //     </DeckGLWrapper>
  //       }
  //   </div>
  // );
}

const mapStateToProps = (state) => {
  return {
    options: state.options,
    printValues: state.printValues,
    arrowValue: state.arrowValue,
    overlays: state.overlays,
    drawState: state.drawState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (options) => {
      dispatch({ type: 'REPLACE_OPTIONS', options})
    },
    changeCity: (city) => {
      dispatch({ type: 'SELECT_CITY', key:'city', city });
    },
    startLoader: () => {
      dispatch({ type: 'START_LOADER'})
    },
    endLoader: () => {
      dispatch({ type: 'END_LOADER'})
    },
    updateDValues: (key, value) => {
      dispatch({ type: 'UPDATE_DOWNLOAD_VALUES', key, value})
    },
    updatePrintImage: (value) => {
      dispatch({ type: 'UPDATE_VALUES',  value})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map)
