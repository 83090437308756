import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';

const TutorialPopup = ({ isOpen, setIsOpen }) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  };

  const nxt = () => {
    let n = parseInt(activeTab);
    if (n !== 10) {
      setActiveTab(`${n+1}`)
    } else {
      setIsOpen(false);
    }
  }

  const className = 'modal-tutorial';

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} size={'md'} style={{height: '410px'}}>
      <ModalBody className='modal-body'>
        <Row>
          <Col xs="6" sm="4" md="4">
            <Nav pills vertical>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggleTab('1'); }}
                >
                  NEW TO AIDSVu?
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggleTab('2'); }}
                >
                  MAP OVERVIEW
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggleTab('3'); }}
                >
                  TITLE & LEGEND
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggleTab('4'); }}
                >
                  GEOGRAPHY
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => { toggleTab('5'); }}
                >
                  DATASET
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '6' })}
                  onClick={() => { toggleTab('6'); }}
                >
                  RATE / CASE COUNT
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '7' })}
                  onClick={() => { toggleTab('7'); }}
                >
                  DEMOGRAPHIC FILTERS
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '8' })}
                  onClick={() => { toggleTab('8'); }}
                >
                  TRANSMISSION CATEGORIES
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '9' })}
                  onClick={() => { toggleTab('9'); }}
                >
                  OVERLAYS
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '10' })}
                  onClick={() => { toggleTab('10'); }}
                >
                  ADDITIONAL TOOLS
                </NavLink>
              </li>
            </Nav>
          </Col>
          <Col xs="6" sm="8" md="8">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                  <h2>New to AIDSVu?</h2>
              Our 30 second tutorial will teach you how to use the map.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                <Col sm="12">
                <h2>Map Overview</h2>
                    <div>
                    Search for your location or use the mouse scroller to zoom in and out. You can also press the +/- buttons to zoom or use the mouse to click and drag the location you are viewing on the map.
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                  <h2>Title & Legend</h2>
              Each map has a legend at the bottom of the map that shows the map's color key and corresponding range. The map’s title is to the left of this key.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                  <h2>Geography</h2>
            Click City, County, or State to view data at different geographic levels. Some cities can also be viewed at the census tract and community area level.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col sm="12">
                  <h2>Dataset</h2>
            Use the Select Dataset toggle in the top red bar to see different HIV data types, including prevalence of where people are living with HIV, new diagnoses to see cases diagnosed in recent years. At different geographies, you can also view PrEP use and PnR to view PrEP-to-Need ratio across the country*, and mortality to see where people living with HIV have died.

            *PnR is the ratio of the number of PrEP users to the number of people newly diagnosed with HIV and serves as a measurement for whether PrEP use appropriately reflects the need for HIV prevention in a geographic region or demographics subgroup.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12">
                  <h2>Rates, Cases, Users, & Ratio</h2>
            The map can be viewed by rates or cases, and in some instances like PrEP and PnR, users and ratio. The rates show the number of cases per 100,000 people in the population to view the relative concentration of people in an area living with an HIV diagnosis. This differs from the number of cases, which is the actual number of people living with an HIV diagnosis.

When viewing PrEP maps, you can view the map by rates or users. The rates show the number of people using PrEP per 100,000 people in the population to view the relative concentration of people in an area who are prescribed PrEP. This differs from users, which is the number of people who are prescribed PrEP*. PnR can only be viewed as a ratio.

Additionally, the map view year can be selected from the dropdown list for new diagnoses, PrEP, and PnR.

*AIDSVu PrEP data represent a reliable and consistent estimated number of people who had at least one day of prescribed TDF/FTC for PrEP in a calendar year. These individuals are referred to as “PrEP users.”
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="7">
                <Row>
                  <Col sm="12">
                  <h2>Demographic Filters</h2>
            View the maps by age, sex, and/or race by selecting those options in the black bar. If a filter is shaded, it is unavailable for the map view.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="8">
                <Row>
                  <Col sm="12">
                  <h2>Transmission Categories</h2>
            You can also view AIDSVu maps next to maps of social determinants of health, like poverty and education.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="9">
                <Row>
                  <Col sm="12">
                  <h2>Overlays</h2>
            Select overlays for various service providers like HIV testing sites and PrEP services, or select the Congressional District lines option to see which district your county falls in.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="10">
                <Row>
                  <Col sm="12">
                  <h2>Additional Tools</h2>
            Browse options in the bottom grey bar to download the data behind the maps, download an image of the map you’re viewing, share or imbed the map, or find additional information, including the data methods.
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
          <Button className='btn-modal' color="primary" onClick={nxt}>Continue</Button>
          </Col>
          <Col md={8} className='modal-logo'>
            <img _ngcontent-c0="" alt="" className="logo-modal panel-1" src="/assets/logo.svg" />
          </Col>
        </Row>
      </ModalBody>
      {/* <ModalFooter>
      </ModalFooter> */}
    </Modal>
  )
}

export default TutorialPopup;