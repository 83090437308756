import React, { Fragment, useState, useEffect } from 'react';
import DeckGLWrapper from './DeckGLWrapper';

const DeckGLWrapperTwice = ({ isDC, common, firstProps, firstChild, secondProps, secondChild }) => {
  const [object, setObject] = useState(null);
  const [hideObject, setHideObject] = useState(null);
  const [keep, setKeep] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [hoveredMap, setHoveredMap] = useState({});

  useEffect(()=>{
    if(common.options.latestValue == 'datatype' || common.options.latestValue === 'maptype' || common.options.latestValue === 'ratescases' || common.options.latestValue === 'datacomparison' || common.options.latestValue === 'reset') {
      setPopupList([]);
      setObject(null);
    }
  },[common.options]);
  let hovered = false;

  Object.keys(hoveredMap).forEach(k => {
    if (hoveredMap[k]) {
      hovered = true;
    }
  })

  let newOnes = {
    object, setObject,
    hideObject, setHideObject,
    keep, setKeep,
    popupList, setPopupList,
    hovered,
    hoveredMap, setHoveredMap
  }


  let firstObject = {
    ...firstProps,
    ...common,
    ...newOnes,
    order: 'first'
  }

  let secondObject = {
    ...secondProps,
    ...common,
    ...newOnes,
    order: 'second'
  }

  return (
    <Fragment>
      <DeckGLWrapper
        {...firstObject}
      >
        {
          firstChild
        }
      </DeckGLWrapper>
      {isDC &&
        <DeckGLWrapper
          {...secondObject}
        >
          {secondChild}
        </DeckGLWrapper>
      }
    </Fragment>
  )
}

export default DeckGLWrapperTwice;
