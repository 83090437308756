import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import DataComparison from './DataComparison';
import Overlays from './Overlays';
import {printService} from '../services/printService';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

const Filter = ({options, changeFilterOptions, changeValue, resetValues, changeArrow, changeService}) => {
  let isMobile = false;
  const [isCollapsed3,setIsCollapsed3] = useState(false);
  const [isCollapsed3d,setIsCollapsed3d] = useState(false);
  const [isCollapsedDC, setIsCollapsedDC] = useState(false);
  const [isCollapsedSP, setIsCollapsedSP] = useState(false);
  const [dropdownOpenAge, setDropdownOpenAge] = useState(false);
  const [selectedAge, setSelectedAge] = useState({value:'Age', label:'Age'});
  const [itemsAge, setItemsAge] = useState([]);

  const [dropdownOpenSex, setDropdownOpenSex] = useState(false);
  const [selectedSex, setSelectedSex] = useState({value:'Sex', label:'Sex'});
  const [itemsSex, setItemsSex] = useState([]);

  const [dropdownOpenRace, setDropdownOpenRace] = useState(false);
  const [selectedRace, setSelectedRace] = useState({value:'Race', label:'Race'});
  const [itemsRace, setItemsRace] = useState([]);

  const [dropdownOpenTranscat, setDropdownOpenTranscat] = useState(false);
  const [selectedTranscat, setSelectedTranscat] = useState({value:'Transmission', label:'Transmission'});
  const [itemsTranscat, setItemsTranscat] = useState([]);

  const [selectedDC, setSelectedDC] = useState({value:"DC", label:'Data Comparison'});
  const [selectedOptions,setSelectedOptions] = useState([]);

  const [areaType,setSelectedAreaType] = useState({value:'all', label:'All Counties'});
  const areaTypes = [
    {value: 'all', label:'All Counties'},
    {value: 'urban', label: 'Urban Counties'},
    {value: 'rural', label: 'Rural Counties'}
  ];

  const [isCity, setIsCity] = useState(false);

  const [dropdownOpenAreaType, setDropdownOpenAreaType] = useState(false);
  // const [selectedSex, setSelectedSex] = useState({value:'Sex', label:'Sex'});


  const isDisabled = (option, type) => {
    // console.log('Is disabled', type, options, options[type]);
    if(options[type]){
      if (option === 'none' && options[type]['unavailable']) {
        return true;
      }
      if (!options[type]['unavailable']) {
        return false;
      }
      if (options[type]['unavailable'].includes(option)) {
        return true;
      }
    }
    return false;
  }
  const availableOptions = (param) => {
    if (options && !options[param]['hidden'].includes(param)) {
      let filter = [];
      options[param]['labels'].forEach((ele, idx) => {
        if (options[param]['hidden']) {
          if (!options[param]['hidden'].includes(options[param][param][idx])) {
            let labelDisplay = ele;
            if (options['maptype'] === 'city' && ele === 'Asian') {
              labelDisplay = 'Asian/Pacific Islander';
            }
            filter.push({ value: options[param][param][idx], label: labelDisplay });
          }
        }
      });
      return filter;
    }
    return [];
  }

  const  hasOption = (filter) => {
    let hasFilter = false;
    if (!availableOptions(filter).length) {
      return;
    }

    availableOptions(filter).forEach(option => {
      if (!isDisabled(option.value, filter)) {
        hasFilter = true;
      }
    });
    // have to check if this is necesary
    if (selectedOptions && selectedOptions.length) {
      hasFilter = true;
    }
    return hasFilter;
  }

  const showTestPrint = () => {
    // console.log("PAPACHINGS", printService);
  }
  const changeFilter = (filtertype, filter) => {
    changeFilterOptions(filtertype, filter.value);
    switch(filtertype) {
      case 'age':
        setSelectedAge(filter);
        return

      case 'sex':
        setSelectedSex(filter);
        return

      case 'race':
        setSelectedRace(filter);
        return

      case 'transcat':
        setSelectedTranscat(filter)
        return

      default:
        return
    }

  }
  const selectAreaType = (areaType) => {
    changeValue('areatype',areaType.value);
    setSelectedAreaType(areaType);
  }
  const updateDC = (val) => {
    setSelectedDC({value:val.value, label: val.label})
  }

  useEffect(()=>{
    isMobile = window.innerWidth < 1024 ? true: false;
  },[]);
  useEffect(()=>{
    if(isCollapsedSP){
      setIsCollapsedDC(false);
    }
  },[isCollapsedSP])
  useEffect(()=>{
    if(isCollapsedDC){
      setIsCollapsedSP(false);
    }
  },[isCollapsedDC])
  useEffect(()=>{
    if( dropdownOpenAge || dropdownOpenSex ||  dropdownOpenRace || dropdownOpenTranscat || dropdownOpenAreaType ) {
      setIsCollapsedDC(false);
      setIsCollapsedSP(false);
    }
  }, [dropdownOpenAge,
    dropdownOpenSex,
    dropdownOpenRace,
    dropdownOpenTranscat, dropdownOpenAreaType])
  useEffect(()=>{
    changeArrow(isCollapsed3d);
  },[isCollapsed3d]);
  useEffect(()=>{

    if(options.latestValue === 'reset') {
      setTimeout(()=>{
        changeService('isDistricts',false);
      },1000)
    }
    if(options.maptype == 'city' && options.city == '') {
      setIsCity(false);
    } else {
      setIsCity(true);
    }
    if(options.latestValue === 'maptype' || options.latestValue === 'datatype' || options.latestValue === 'ratescases' || options.latestValue === 'year') {
      setSelectedAreaType({value:'all', label:'All Counties'});
    }

    const labelOptions = ['age', 'sex', 'race', 'transcat', 'datacomparison'];
    let newSelectedOptions = [];
    for (const option in options) {
      if (labelOptions.includes(option)) {
        if (options[option]['selected'] !== 'none') {
          newSelectedOptions.push({ id: option, label: options[option]['labels'][ options[option][option].indexOf(options[option]['selected']) ] });
        }
      }
    }
    if (options['transcat']['selected'] === 'msm' || options['transcat']['selected'] ===  'msmidu') {
      let male = false;
      for (const option of selectedOptions) {
        if (option.label === 'Male') {
          male = true;
        }
      }
      if (!male) {
        newSelectedOptions.push({id: 'sex', label: 'Male'});
      }
    }
    if(newSelectedOptions.length > 0) {
      newSelectedOptions.sort((a, b) => {
        if(!a.label || !b.label) {
          return false
        }
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      });
    }
    setSelectedOptions(newSelectedOptions);
    setItemsAge(availableOptions('age'));
    if(!options.age.selected || options.age.selected === '') {
      setSelectedAge({value:'Age', label:'Age'});
    }
    setItemsSex(availableOptions('sex'));
    if(!options.sex.selected || options.sex.selected === '') {
      setSelectedSex({value:'Sex', label:'Sex'});
    }
    
    setItemsRace(availableOptions('race'));
    if(!options.race.selected || options.race.selected === '') {
      setSelectedRace({value:'Race', label:'Race'});
    }
    setItemsTranscat(availableOptions('transcat'));
    if(!options.transcat.selected || options.transcat.selected === '') {
      setSelectedTranscat({value:'Transcat', label:'Transmission'});
    }
    if(options.areatype == 'all' ) {
      setSelectedAreaType({value:'all', label: 'All Counties'});
    } else if(options.areatype == 'urban') {
      setSelectedAreaType({value: 'urban', label: 'Urban Counties'});
    } else if(options.areatype == 'rural') {
      setSelectedAreaType({value: 'rural', label: 'Rural Counties'});
    }
    if(options.datacomparison.selected == 'none' || options.datacomparison.selected == '') {
      setSelectedDC({value:"DC", label:'Data Comparison'});
    }
  },[options]);
  useEffect(()=>{
    selectedOptions.forEach((value) => {
      if(value.label) {
        switch(value.id){
          case 'age':
            setSelectedAge(value);
            break;
          case 'race':
            setSelectedRace(value);
            break;
          case 'sex':
            setSelectedSex(value);
            break;
          case 'transcat':
            setSelectedTranscat(value);
            break;
          // case 'datacomparison':
          //   setSlecte
          //   break;
        }
      }
    });
  },[selectedOptions]);
  const reset = () => {
    resetValues();
  }
  return (

    <nav className={"navbar navbar-secondary " + (isCollapsed3d?'navbar-height-collapsed':'')}  >
    {/* [ngClass]="{'navbar-height-collapsed': isCollapsed3d}" */}
      {/* [ngClass]="{'d-show-flex': isCollapsed3d, 'd-hide': !isCollapsed3d}" */}
    <div className={"navbar-collapsed-menu-desktop " + (isCollapsed3d?'d-show-flex':'')+ ' '+ (!isCollapsed3d?'d-hide':'') } >
      {/*  (click)="sendArrow()" */}
      <span className="d-flex flex-column align-items-center cursor-share" onClick={()=>setIsCollapsed3d(!isCollapsed3d)}>
        <span className="filter-title">FILTERS</span>
      </span>

    </div>
    <div className={"w-100 p-0 filter-margin "+ (!isCollapsed3d?'d-show-flex':'') + (isCollapsed3d?'d-hide':'') } >
      {/* (click)="changeCollapse3(); " */}
      <button className="navbar-toggler navbar-toggler-right table-hidden" type="button" onClick={() => setIsCollapsed3(!isCollapsed3)}>
        FILTERS<span><img height="12px" src="assets/arrow-collapse-down.svg" alt="" /></span>
      </button>
      {/*  [ngClass]="{'d-show-flex': globals.isCollapsed3, 'd-hide': !globals.isCollapsed3}" */}
      <div className={"navbar-menu-top " + (isCollapsed3?'d-hide':'d-show-flex')} >
        <div className="navbar-filter-top">
          <div className="navbar-mobile-title">
            {/* <span>Refine your search by:</span>
            (click)="changeCollapse3();loadMapLayers()" */}
            <span className="navbar-mobile-close" ><img height="13px" src="assets/close-mobile.svg" alt="" onClick={() => setIsCollapsed3(!isCollapsed3)}/></span>
          </div>
          <div className="navbar-collapse">
            <span className="nav-link nav-link-mobile">Filters</span>
            {
            hasOption('age') &&
            <Dropdown className={"btn-group mr-2 " + ((isDisabled('age','age') ||  !isCity)?'disabled':'')} isOpen={dropdownOpenAge} toggle={() => setDropdownOpenAge(!dropdownOpenAge)}>
              <DropdownToggle caret>
              <small> {selectedAge.label} </small>
                <i className="mdi mdi-menu-down icon-lg "></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  itemsAge.map(({ value, label }) => (
                    <DropdownItem key={ 'di-' + value } onClick={() => changeFilter('age',{value,label})}>
                      {label}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>}

            {
            hasOption('sex') &&
            <Dropdown className={"btn-group mr-2 " + ((isDisabled('sex','sex') ||  !isCity)?'disabled':'')} isOpen={dropdownOpenSex} toggle={() => setDropdownOpenSex(!dropdownOpenSex)}>
              <DropdownToggle caret>
              <small>{selectedSex.label}</small>
                <i className="mdi mdi-menu-down icon-lg "></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  itemsSex.map(({ value, label }) => (
                    <DropdownItem key={ 'di-' + value } onClick={() => changeFilter('sex', {value,label})}>
                      {label}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>}

            {
            hasOption('race') &&
            <Dropdown className={"btn-group mr-2 " + ((isDisabled('race','race') ||  !isCity)?'disabled':'')} isOpen={dropdownOpenRace} toggle={() => setDropdownOpenRace(!dropdownOpenRace)}>
              <DropdownToggle caret>
              <small> {selectedRace.label}</small>
                <i className="mdi mdi-menu-down icon-lg "></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  itemsRace.map(({ value, label }) => (
                    <DropdownItem key={ 'di-' + value } onClick={() => changeFilter('race',{value,label})}>
                      {label}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>}

            {
            hasOption('transcat') &&
            <Dropdown className={"btn-group mr-2 " + ((isDisabled('transcat','transcat') ||  !isCity)?'disabled':'')} isOpen={dropdownOpenTranscat} toggle={() => setDropdownOpenTranscat(!dropdownOpenTranscat)}>
              <DropdownToggle caret>
                <small>{selectedTranscat.label}</small>
                <i className="mdi mdi-menu-down icon-lg "></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  itemsTranscat.map(({ value, label }) => (
                    <DropdownItem key={ 'di-' + value } onClick={() => changeFilter('transcat',{value,label})}>
                      {label}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>}

            {/* *ngIf="hasOption('datacomparison')" */}
            {/* dropdown */}
            <div className="btn-group mr-2 mobile-hidden"  >
              {/* [ngClass]="{'btn': true,  disabled: isDisabled('datacomparison', 'datacomparison')}"  */}
              {/* (click)="isCollapsedDC=!isCollapsedDC; this.globals.isCollapsedSP =false;" */}
              {/* dropdownToggle */}
              <button id="button-animated"  type="button" className={"btn btn-secondary dropdown-toggle "+((isDisabled('datacomparison', 'datacomparison') || !isCity)?'disabled':'' )} aria-controls="dropdown-animated" onClick={()=>setIsCollapsedDC(!isCollapsedDC)} >
                <small>
                  {selectedDC.label }
                </small> <i className="mdi mdi-menu-down"></i>
              </button>

            </div>
              {/* (click)="onCollapseSP();" */}
            <button type="button" className={"btn-group btn btn-secondary dropdown-toggle mr-2 " + (!isCity?'disabled':'')} onClick={()=>setIsCollapsedSP(!isCollapsedSP)} >
              <small>Overlays</small> <i className="mdi mdi-menu-down"></i>
            </button>

            {
            options.maptype === 'county' &&
            <Dropdown className="btn-group mr-2 " isOpen={dropdownOpenAreaType} toggle={() => setDropdownOpenAreaType(!dropdownOpenAreaType)}>
              <DropdownToggle caret>
                <small> {areaType.label}</small>
                <i className="mdi mdi-menu-down icon-lg "></i>
              </DropdownToggle>
              <DropdownMenu className="menuitem">
                {
                  areaTypes.map(({ value, label }) => (
                    /* [id]="area.value" (click)="updateCountiesArea(area);" */
                    <DropdownItem className='dropdown-item' key={ 'di-' + value } onClick={() => selectAreaType({ value, label })}>
                      {label}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>}

          </div>
        </div>

        <div className="navbar-collapse navbar-filter">
          {/* *ngIf="options && isMobile" */}
          {/* dropdown */}
          { isMobile &&
          <div className="btn-group"  >
            {/* (click)="changeCollapse3();loadMapLayers();this.globals.isCollapsedSP =false;" */}
            <button id="viewmap" className="btn btn-label-primary text-white button-clear" onClick={showTestPrint}>
              VIEW MAP
            </button>
          </div>}
        </div>
        <div className="navbar-collapse navbar-filter" onClick={reset}>
          {/* (click)="reset()" */}
          <a  className="btn btn-label-primary text-white button-clear">Clear</a>

        </div>

      </div>
    </div>
    {
      isCollapsedDC &&
      <DataComparison updateDC={updateDC} close={() => setIsCollapsedDC(!isCollapsedDC)}></DataComparison>
    }
    {
      isCollapsedSP &&
      <Overlays close={()=>setIsCollapsedSP(!isCollapsedSP)}></Overlays>
    }
    {/* (click)="sendArrow()" */}
    <span className="filter-arrow-gray" onClick={()=>setIsCollapsed3d(!isCollapsed3d)} ><img height="16px" className="p-0" src="assets/arrow-collapse-down.svg" alt=""/></span>
  </nav>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeFilterOptions: (filter,value) => {
      dispatch({ type: 'CHANGE_SELECTED', key:filter, value });
    },
    changeValue: (filter,value) => {
      dispatch({ type: 'CHANGE_VALUE', key:filter, value });
    },
    resetValues: () => {
      dispatch({ type: 'RESET_FILTER' });
    },
    changeArrow: (value) => {
      dispatch({ type: 'CHANGE_ARROW', value});
    },
    changeService: (key, value) => {
      dispatch({type:'CHANGE_SERVICE', key, value})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter)
