import {transformLegendColors} from './colorsService';
import MapService from '../components/MapService';
import { shouldDisplayExtraCero } from '../components/utils';

export function   errorPopup(popups, options) {
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  if(popups) {
    for (const popup of popups) {
      if (options.overall) {
        if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter && popup.varvalue < 0) {
          if (!message[popup.varvalue]) {
            message[popup.varvalue] = '';
          }
          if(! message[popup.varvalue].includes(popup.message)) {
            message[popup.varvalue] += popup.message;
          }
        }
      }
      for (const opt of popupOptions) {
        if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
          options[opt].selected !== 'none' && opt === popup.filter && popup.varvalue < 0) {
            if (!message[popup.varvalue]) {
              message[popup.varvalue] = '';
            }
            if(! message[popup.varvalue].includes(popup.message)) {
              message[popup.varvalue] += popup.message;
            }
        }
      }
    }
  }
  return message;
}
export function searchPopups(popups, options) {
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  // console.log("popups filtered", popups.filter((pp) => pp.datatype === options.datatype && pp.ratescases === options.ratescases.selected));
  // this search for popups with overall value 
  for (const popup of popups) {
    if (options.overall) {
      // console.log("PIP IP ", popup.datatype, options.datatype, popup.ratescases, options.ratescases.selected);
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter) {
        if (!popup.varvalue) {
          message[popup.varorder] = popup.message;
        }
      }
    }
    // this search for popup with 
    for (const opt of popupOptions) {
      // death     additionalrates   aditionalrates    transcat   overall 
      if (
        popup.datatype === options.datatype &&
        popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== 'none' && options[opt].selected !== '' &&
        opt === popup.filter
      ) {
        // console.log('opupoptions', options.datatype, popup.ratescases, 'selected',options.ratescases.selected, "other", opt, popup.filter, options[opt] );
        // console.log('varvalue check', !popup.varvalue, popup);
        if (!popup.varvalue) {
          message[popup.varorder] = popup.message;
        }
      }
    }
  }
  let specialFilter = 'transcat';
  if (options.transcat['selected'] !== 'none' && options.transcat['selected'] !== '') {
    for (const filter of popupOptions) {
      if (filter === 'transcat') {
        continue;
      }
      if (options[filter]['selected'] !== 'none' && options[filter]['selected'] !== '') {
        specialFilter += filter;
      }
    }
  }
  if (specialFilter.length > 'transcat'.length) {
    for (const popup of popups) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        specialFilter === popup.filter) {
        if (!popup.varvalue) {
          message[popup.varorder] = popup.message;
        }
      }
    }
  }
  return message;
}

const searchHoverPopupCity = (popups, options) => {
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  for (const popup of popups) {
    if (options.overall) {
      if (popup.hovernote) {
        message[popup.hovernote] = popup.message
      }
    }
    for (const opt of popupOptions) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== 'none' && opt === popup.filter) {
        if (popup.hovernote) {
          message[popup.hovernote] = popup.message
        }
      }
    }
  }
  let specialFilter = 'transcat';
  if (options.transcat['selected'] !== 'none') {
    for (const filter of popupOptions) {
      if (filter === 'transcat') {
        continue;
      }
      if (options[filter]['selected'] !== 'none') {
        specialFilter += filter;
      }
    }
  }
  if (specialFilter.length > 'transcat'.length) {
    for (const popup of popups) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        specialFilter === popup.filter) {
        if (popup.hovernote) {
          message[popup.hovernote] = popup.message
        }
      }
    }
  }
  return message;
}
///////////
const searchDC = (options,comparisonOptions) => {
  const ans = comparisonOptions.filter(ele => {
    
    if (options.maptype === 'city') {
      // tslint:disable-next-line:max-line-length
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
    } else {
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
    }
  });
  return ans;
}
const searchDCState = (options,comparisonOptions, abbrev) => {
  const ans = comparisonOptions.filter(ele => {
    
    if (options.maptype === 'city') {
      // tslint:disable-next-line:max-line-length
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
    } else {
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && abbrev === ele.state;
    }
  });
  return ans;
}
const errorPopupDC = () => {
  return 'Data not available because the data source does not publish these data for this jurisdiction. ';
}
///////////
const searchPopupCity = (popups, options) => {
  const message = [];
  message.length = 10;
  // console.log("CITY", options.overall, options.datatype, options.ratescases.selected);
  // console.log("POPUPS ", popups);
  // console.log(popupOptions, options['age'],options['race'],options['sex'],options['transcat']);

  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  for (const popup of popups) {
    if (options.overall) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter) {
        if(popup.varvalue?popup.varvalue>0:true) {
          message[popup.varorder] = popup.message;            
        }
      }
    }
    for (const opt of popupOptions) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== '' && opt === popup.filter) {
          if(popup.varvalue?popup.varvalue>0:true) {
            message[popup.varorder] = popup.message;
          }
      }
    }
  }
  let specialFilter = 'transcat';
  if (options.transcat['selected'] !== 'none') {
    for (const filter of popupOptions) {
      if (filter === 'transcat') {
        continue;
      }
      if (options[filter]['selected'] !== 'none') {
        specialFilter += filter;
      }
    }
  }
  if (specialFilter.length > 'transcat'.length) {
    for (const popup of popups) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        specialFilter === popup.filter) {
        message[popup.varorder] = popup.message;
      }
    }
  }
  return message;
}
const errorPopupCity = (popups, options) => {
  // -4 is the lowest value
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  for (const popup of popups) {
    if (options.overall) {
      // console.log(popup.varvalue);
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter && popup.varvalue < 0) {
        message[popup.varvalue] = popup.message;
      }
    }
    for (const opt of popupOptions) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== 'none' && opt === popup.filter && popup.varvalue < 0) {
        message[popup.varvalue] = popup.message;
      }
    }
  }
  return message;
}

export const getStatePopUpHTML = ( state, popupsData, options, stateValues,  setPopupHTML) => {
      let messages = searchPopups(popupsData, options);
      let advertise = messages[3];
      let message = messages[0];
      let message2 = '';
      // setMessage(messages[0]);
      if(messages[1] && messages[1]!='undefined') {
        message2 = (`<div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"><em><small>${messages[1]}</small></em></div>`);
      }
      let value = '';
      let pos = -1;
      let yn = false;
      let corrwarning = 0;
      let showButton = '';
      let yearExtra = 'none';
      if(options.maptype == 'state') {
        for (const data of stateValues) {
          if (data.state === state) {
            yearExtra = data.year?data.year:yearExtra;
            value = data.value;
            pos = (data.pos) % 12;
            yn = getWarningYn(data.yn);
            corrwarning = data.corrwarning;
          }
        }
      } 
      if (+value < 0) {
        const errorPopups = errorPopup(popupsData, options);
        // {showButton}  esto falta poner pero todavia no se qués
        let error = `<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr><div style="line-height:1.2;"> ${errorPopups[+value]} </div>`
        if (!yn) {
          // pos 2 in csv file
          let message2 = '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px; ">' + messages[2] + '</div>';
          message2 = message2.includes('undef') ? '' : message2;
          
          error += message2;
        }
        if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR')) {
          error += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px; line-height: 1.1">' + advertise + '</div>';
        }
        // mapService.addPopup(event.lngLat, error); < = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
        setPopupHTML(error);
        // if (!isHover) {
        //   let pin = document.getElementById(`pin${counter}`);
        //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
        // }
        return;
      }
      if (+value >= 1000) {
        let point = 0;
        if (+value >= 1000) {
          point = 1;
        }
        if (+value >= 10000) {
          point = 2;
        }
        if (+value >= 100000) {
          point = 3;
        }
        value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
      }

      message = message ? message.replace('{varvalue}', '' + value): message;
      if(yearExtra != 'none') {
        message = message? message.replace('{year}', '' + yearExtra): message;
      } else {
        message = message ? message.replace('{year}', '' + options.year['selected']): message;
      }
      const legendcolorsArray = transformLegendColors(options['legendcolors'], 'state', options.ratescases.selected);
      const legendValues = options['legendvalues'].split(',');

      let colorRow = '<table style="width:100%"> <tr >';
      const divide = 100 / legendValues.length;
      legendcolorsArray.forEach( (element, index) => {
        if (index === pos) {
          colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
        } else {
          colorRow += `<th width="${divide}%"> &nbsp; </th>`;
        }
      });
      colorRow += '</tr><tr>';
      for(let i = 0 ; i < legendValues.length; ++i){
        const color = legendcolorsArray[i];
        colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
      }
      colorRow += '</tr> </table>';
      if (yn && !corrwarning) {
      // // console.log('corrwarning 2', corrwarning);
        message = '<div style="line-height:1.3; margin-bottom:0px;"> ' + message + '</div>';
        
        if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR')) {
          // tslint:disable-next-line:max-line-length
          // AQUI ESTA EL POPUP ARMADO     < = = = == = = = = = = = = = = = = = ==  = == = = = = = = ==  == = = 
          // console.log("THIS ASCK",`<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2} <div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"> ${advertise} </div>  ${colorRow} <hr>`);
          setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message} ${message2} <div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"> ${advertise} </div>  ${colorRow} <hr>`);
          // mapService.addPopup(event.lngLat, `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2} <div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"> ${advertise} </div>  ${colorRow} <hr>`);
        } else {
          // // console.log('EMEM', `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message}  ${colorRow} <hr>`);
          // AQUI ESTA EL POPUP ARMADO 2  < = = = == = = = = = = = = = = = = = ==  = == = = = = = = ==  == = = 
          // console.log(" CHECK 3", `<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2}  ${colorRow} <hr>`);
          setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message} ${message2}  ${colorRow} <hr>`);
          // mapService.addPopup(event.lngLat, `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2}  ${colorRow} <hr>`);
        }
        // if (!isHover) {
        //   let pin = document.getElementById(`pin${counter}`);
        //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
        // }
      } else {
        if (!yn) {
          // pos 2 in csv file
          let message2 = '<br><div style="line-height:1.2; margin-top:5px; margin-bottom:0px; ">' + messages[2] + '</div>';
          message2 = message2.includes('undef') ? '' : message2;
          message += message2;
          // // console.log('Message 1', message);
          if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR') ) {
            message += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px; line-height: 1.1">' + advertise + '</div>';
            // // console.log('Message 2', message);
          }
        }
        message = '<div style="line-height:1.3; margin-bottom:0px;"> ' + message + '</div>';
        // AQUI ESTA EL POPUP ARMADO 3 < = = = == = = = = = = = = = = = = = ==  = == = = = = = = ==  == = = 
        // console.log("MADRE PATRI ", `<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message}  ${colorRow} <hr>`);
        setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message}  ${colorRow} <hr>`);
        // mapService.addPopup(event.lngLat, `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message}  ${colorRow} <hr>`);
        // if (!isHover) {
        //   let pin = document.getElementById(`pin${counter}`);
        //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
        // }
      }
}
const getWarningYn = (currentYn) => {
  // if false or N will display the message
  if ( currentYn == false || currentYn == 'N') {
    return false;
  } else {
    return true;
  }
};

export const getCountyPopUpHTML = ( geo_id2, county, state, popupsData, options, countyValues, stateCodes, setPopupHTML) => {
  const messages = searchPopups(popupsData, options);
  const advertise = messages[3];
  // const counter = mapService.infinite_counter;
  let message = messages[0];
  let message2 = messages[1]  ? (`<div style="margin-top:5px; margin-bottom:0px; line-height: 0.6;"><small>${messages[1]}</small></div>`) : '';
   message2 = message2.includes('undef') ? '' : message2;
  let headerMessage = '';
  stateCodes.forEach(stateCode => {
    if (stateCode.state == state) {
      headerMessage = stateCode.name;
    }
  });
  let value = '';
  let pos = -1;
  let yn = false;
  let corrwarning = 0;
  let showButton = '';
  let yearExtra = 'none';
  // if (!isHover) {
  //   showButton = `<button class="pin active" id="pin${counter}" type="button" name="button" class="btn" placement="right"><i class="mdi mdi-pin"></i></button>`;
  //   mapService.infinite_counter++;
  // }
  let legendValues = shouldDisplayExtraCero(options) ? [0]: [];
  legendValues = [...legendValues, ...options.legendvalues.split(',')];
  const shouldMoveGray = () => {
    return (
      (options.datatype !== "linkage" && options.datatype !== "receipt" && options.datatype !== "viralsuppression") &&
      ((options.ratescases.selected === 'cases' || (options.datatype === 'newdx' && options.rateslabel === 'Rates'))
      ||
      (options.additionalrateslabel === 'Rates' && options.ratescases.selected == "additionalrates"))
    )
  }
  const legendcolorsArray = transformLegendColors(options['legendcolors'], 'county', options.ratescases.selected, options.caseslabel, undefined, options.datatype, options.rateslabel, options);
  const getPosition = (data) => {
    if (shouldMoveGray()) {
      if(legendcolorsArray.length < 10 && data.pos === 10) {
        return 0;
      } else {
        return (data.pos + 1)%legendcolorsArray.length;
      }
    } else {
      return data.pos;
    }
  }
  for (const data of countyValues) {
    if (data.geo_id2 === geo_id2) {
      console.log('geojasdca in caounty ', data);
      yearExtra = data.year?data.year:yearExtra;
      value = data.value;
      pos = getPosition(data);
      yn = getWarningYn(data.yn);
      corrwarning = data.corrwarning;
      county = data.countyname?data.countyname: data.county;
    }
  }
  // console.log("YEAR EXTRA", yearExtra);
  if (+value < 0) {
    const errorPopups = errorPopupCity(popupsData, options);
    let error = `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"> <h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5> </b> <hr> <div style="line-height:1.2;"> ${errorPopups[+value]} </div>`;
    if (!yn) {
      // pos 2 in csv file
      let   message2 = '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[2] + '</div>';
      message2 = message2.includes('undef') ? '' : message2;
      error += message2;
    }
    if (advertise && !(
        options.datatype === 'PrEP' ||
        options.datatype === 'PNR' ||
        options.datatype === 'linkage' ||
        options.datatype === 'receipt' ||
        options.datatype === 'viralsuppression'
        ) ) {
      error += '<div style=" margin-top:5px; margin-bottom:0px; line-height: 0.7">' + advertise + '</div>';
    } else if (corrwarning == 1 && (
      options.datatype !== 'PrEP' &&
      options.datatype !== 'PNR' && 
      options.datatype !== 'linkage' &&
      options.datatype !== 'receipt' &&
      options.datatype !== 'viralsuppression'
    )) {
      // pos 3 in csv file
      error += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    error = error.replace('{state}', '' + headerMessage);
    // mapService.addPopup(event.lngLat, );
    setPopupHTML(error)
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
    return;
  }
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  // console.log('message', message);
  message = message.replace('{varvalue}', '' + value);
  if(yearExtra != 'none') {
    message = message.replace('{year}', '' + yearExtra);
  } else {
    message = message.replace('{year}', '' + options.year['selected']);
  }
  if(legendcolorsArray.length < 10 && pos === 10){
    pos = legendcolorsArray.length - 1;
  }
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendValues.forEach( (_, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (let i = 0 ; i < legendValues.length; i++) {
    const color = legendcolorsArray[i];
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  // for (const color of legendcolorsArray) {
  //   colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  // }
  colorRow += '</tr> </table>';
  if (yn && corrwarning) {
    message = '<div style="line-height:1.3; margin-bottom:0px;"> ' + message + '</div>';
    if (corrwarning == 1 && !(
      (
        options.datatype === 'PrEP'
        || options.datatype === 'PNR'
        || options.datatype ===  'linkage'
        || options.datatype === 'receipt'
        || options.datatype === 'viralsuppression'
      ))) {
      // pos 3 in csv file
      message += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR')) { 
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"> <h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5> </b> <hr> ${message} ${message2} ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5> <hr> ${message} ${message2} ${colorRow} <hr>`);
    } else {
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"><h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5></b> <hr> ${message}  ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5> <hr> ${message}  ${colorRow} <hr>`);
    }
    
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  } else {
    if (!yn && !((options.datatype === 'PrEP' || options.datatype === 'PNR'))) {
      // pos 2 in csv file
      let message2 = '<br><div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[2] + '</div>';
      message2 = message2.includes('undef') ? '' : message2;
      message += message2;
    }
    if (corrwarning == 1 && !((options.datatype === 'PrEP' || options.datatype === 'PNR'))) {
      // pos 3 in csv file
      message += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    message = '<div style="line-height:1.3; margin-bottom:0px;"> ' + message + '</div>';
    if (options.datatype === 'PrEP' || options.datatype === 'PNR') { 
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"><h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5></b> <hr> ${message} ${message2} ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5> <hr> ${message} ${message2} ${colorRow} <hr>`);
    } else { 
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"><h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px"> ${county} </h5> </b> <hr> ${message}  ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold"> ${county} </h5> <hr> ${message}  ${colorRow} <hr>`);
    }
    
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  }
}
const getDataTypeAbbrev = (datatype) => {
  switch (datatype) {
    case "latedx":
      return 'ld'
    case 'linkage':
      return 'lc';
    case "receipt":
      return 'rc';
    case 'viralsuppression':
      return 'vs';
    default:
      return '';
  }
}
const getFilterAbbrev = (filter, subfilter) => {
  if (filter === 'sex') {
    return subfilter === 'male' ? 'm' : 'f';
  }
  if (filter === 'race') {
    // 'asian', 'black', 'hispanic', 'nhpi', 'multirace', 'white'
    // Black (bnh), White (wnh), Asian (asnh), Hispanic (hisp)
    switch (subfilter) {
      case 'asian':
        return 'asnh'
      case 'black':
        return 'bnh';
      case 'hispanic':
        return 'hisp';
      case 'white':
        return 'wnh'
      default:
        return ''
    }
  }
  if (filter === 'age') {
    switch (subfilter) {
      case 'age1324':
        return '13_24';
      case 'age2544':
        return '25_44';
      case 'age3544':
        return '35_44';
      case 'age4559':
        return '45_59';
      case 'age60over':
        return '60plus'
      default:
        break;
    }
  }
}
export const getCityPopUpHTML = ( properties, popupsData, options, cityValues,setPopupHTML) => {
  let city = properties.city;
  const zip = properties.zip;
  const census = properties.geoid10;
  const cartodb_id = properties.cartodb_id;
  const datatypeAbbrev = getDataTypeAbbrev(options.datatype);
  const area = properties.area_numbe;
  let community = properties.community;
  const ward = properties.ward;
  if (community > 0) {
    community = community.split(' ');
    const community_string = [];
    for (let com of community) {
      com = com.substring(0, 1) + com.substring(1).toLowerCase();
      community_string.push(com);
    }
    community = community_string.join(' ');
  }
  const label = properties.label;
  let messages = searchPopupCity(popupsData, options);
  const popupOptions = ['age', 'race', 'sex'];
  let selectedFilter = '';
  let selectedSubFilter = '';
  for (const opt of popupOptions) {
    if(options[opt].selected !== '') {
      selectedFilter = opt;
      selectedSubFilter = options[opt].selected;
    }
  }
  const filterAbbrev = getFilterAbbrev(selectedFilter, selectedSubFilter);
  let message = messages[0];  
  let showButton = '';
  const hoverMessages = searchHoverPopupCity(popupsData, options);
  let value = '';
  let value1 = '';
  let value2 = '';
  let pos = -1;
  let yn = false;
  let corrwarning = 0;
  let hovernote = '';
  let year = '';
  
  const legendcolorsArray = transformLegendColors(options['legendcolors'],'city', options.ratescases.selected, options.caseslabel, undefined, options.datatype, options.rateslabel, options);
  let legendValues = shouldDisplayExtraCero(options) ? [0]: [];
  legendValues = [...legendValues, ...options.legendvalues.split(',')];
  const moveGray = () => {
    return (
      options.ratescases.selected === 'cases'
      || ((options.datatype === 'latedx') && options.rateslabel === '% Cases' && options.ratescases.selected === 'rates')
      || (options.datatype === 'newdx' && options.ratescases.selected === 'rates' && options.rateslabel === 'Risk') 
    );
  };
  const getPositionSpecial = (value, position, legendValues) => {
    if (value === 0) {
      return 0;
    } else {
      return (position + 1) % (legendValues.length)
    }
    
  };
  for (const data of cityValues) {
    if (options.geographytype['selected'] === 'zipcode') {
      
      if (data.zip === zip) {
        let zipCode = '' + zip;
        while (zipCode.length < 5) {
          zipCode = '0' + zipCode;
        }
        value = data.value;
        city = data.cityname + `, Zip Code ${zipCode}`;
        pos = ( moveGray() ? getPositionSpecial(data.value, data.pos, legendValues) : data.pos) ;
        yn = getWarningYn(data.yn);
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
        if (filterAbbrev && filterAbbrev != '' && datatypeAbbrev && datatypeAbbrev != '' && options.ratescases.selected !== 'cases') {
          let filterValue;
          let filterFirstValue;
          let filterSecondValue;
          if (selectedFilter == 'age'){
            filterSecondValue = 'case'+filterAbbrev+(datatypeAbbrev === 'ld' ? (datatypeAbbrev+'x') : datatypeAbbrev)+'_denom';
            filterFirstValue = selectedSubFilter + 'case'+datatypeAbbrev;
            filterValue = selectedSubFilter + 'prop'+datatypeAbbrev
          } else {
            filterSecondValue = filterAbbrev+'case'+datatypeAbbrev+'_denom';
            filterFirstValue = filterAbbrev+'case'+datatypeAbbrev;
            filterValue = filterAbbrev+'prop'+datatypeAbbrev;
          }
          // for male and female in late diagnosis, the key of ld is inconsistent in the data, sometimes it is ld and others ldx
          if (!data[filterSecondValue] && datatypeAbbrev === 'ld') {
            filterSecondValue = filterAbbrev+'case'+datatypeAbbrev+'x'+'_denom';
          }
          // console.log("zipccc", filterValue, filterFirstValue, filterSecondValue, data,
          // data[filterValue],
          // data[filterFirstValue],
          // data[filterSecondValue]);
          value = data[filterValue];
          value1 = data[filterFirstValue];
          value2 = data[filterSecondValue];
        } else {
          value1 = value;
          value2 = value;
        }
      }
    }
    if (options.geographytype['selected'] === 'censustract') {
      if (+data.census === +census) {
        value = data.value;
        city = data.cityname + `, Census Tract ${census}`;
        pos = data.pos;
        yn = getWarningYn(data.yn);
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
        if (filterAbbrev && filterAbbrev != '' && datatypeAbbrev && datatypeAbbrev != '' && options.ratescases.selected !== 'cases') {
          // console.log("zip", filterAbbrev+'prop'+datatypeAbbrev, filterAbbrev+'case'+datatypeAbbrev, filterAbbrev+'case'+datatypeAbbrev+'_denom', data,
          // data[filterAbbrev+'prop'+datatypeAbbrev],
          // data[filterAbbrev+'case'+datatypeAbbrev],
          // data[filterAbbrev+'case'+datatypeAbbrev+'_denom']);
          value = data[filterAbbrev+'prop'+datatypeAbbrev];
          value1 = data[filterAbbrev+'case'+datatypeAbbrev];
          value2 = data[(selectedFilter != 'age' ? filterAbbrev : '' )+'case'+datatypeAbbrev+'_denom'];
        } else {
          value1 = value;
          value2 = value;
        }
      }
    }
    if (options.geographytype['selected'] === 'communityarea') {
      if (data.area === area) {
        value = data.value;
        city = data.cityname + `, ${data.neighborhood ? data.neighborhood : ''} neighborhood`;
        pos = data.pos;
        yn = getWarningYn(data.yn);
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
        if (filterAbbrev && filterAbbrev != '' && datatypeAbbrev && datatypeAbbrev != '' && options.ratescases.selected !== 'cases') {
          // console.log("communitu", filterAbbrev+'prop'+datatypeAbbrev, filterAbbrev+'case'+datatypeAbbrev, filterAbbrev+'case'+datatypeAbbrev+'_denom', data,
          // data[filterAbbrev+'prop'+datatypeAbbrev],
          // data[filterAbbrev+'case'+datatypeAbbrev],
          // data[filterAbbrev+'case'+datatypeAbbrev+'_denom']);
          value = data[filterAbbrev+'prop'+datatypeAbbrev];
          value1 = data[filterAbbrev+'case'+datatypeAbbrev];
          value2 = data[(selectedFilter != 'age' ? filterAbbrev : '' )+'case'+datatypeAbbrev+'_denom'];
        } else {
          value1 = value;
          value2 = value;
        }
      }
    }
    if (options.geographytype['selected'] === 'ward') {
      if (data.ward === ward) {
        value = data.value;
        city = data.cityname + `, ${label}`;
        pos = data.pos;
        yn = getWarningYn(data.yn);
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
        if (filterAbbrev && filterAbbrev != '' && datatypeAbbrev && datatypeAbbrev != '' && options.ratescases.selected !== 'cases') {
          // console.log("ward", filterAbbrev+'prop'+datatypeAbbrev, filterAbbrev+'case'+datatypeAbbrev, filterAbbrev+'case'+datatypeAbbrev+'_denom', data,
          // data[filterAbbrev+'prop'+datatypeAbbrev],
          // data[filterAbbrev+'case'+datatypeAbbrev],
          // data[filterAbbrev+'case'+datatypeAbbrev+'_denom']);
          value = data[filterAbbrev+'prop'+datatypeAbbrev];
          value1 = data[filterAbbrev+'case'+datatypeAbbrev];
          value2 = data[(selectedFilter != 'age' ? filterAbbrev : '' )+'case'+datatypeAbbrev+'_denom'];
        } else {
          value1 = value;
          value2 = value;
        }
      }
    }
    
  }
  if (+value < 0) {
    const errorPopups = errorPopupCity(popupsData, options);
    let error = `${showButton}<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${city} </h5></b>  <hr> <div style="line-height:1.2;"> ${errorPopups[+value]} </div>`;
    if (!yn) {
      // pos 2 in csv file
      if (messages[2]) {
        error += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[2] + '</div>';
      }
    }
    if (corrwarning == 1) {
      // pos 3 in csv file
      if (messages[3]) {
        error += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
      }
    }
    if (hovernote) {
      if (hoverMessages[hovernote]) {
        error += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + hoverMessages[hovernote] + '</div>';
      }
    }
    // mapService.addPopup(event.lngLat, error);
    setPopupHTML(error);
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
    return;
  }
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  
  message = message?message.replace('{varvalue}', '' + value):'';
  message = message?message.replace('{varvalue1}', '' + value1):'';
  message = message?message.replace('{varvalue2}', '' + value2):'';
  message = message?message.replace('{year}', '' + year):'';
  message = message?message.replace('{yearrange}', '' + year):'';
  
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendValues.forEach( (_, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (let i = 0 ; i < legendValues.length; i++) {
    const color = legendcolorsArray[i];
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  if (yn && corrwarning) {
    if(typeof messages[1] !== 'undefined') {
      message += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[1] + '</div>'
    }
    message = '<div style="line-height:1.2; margin-bottom:0px;"> ' + message + '</div>';
   
    if (corrwarning == 1) {
      // pos 3 in csv file
      message += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    let popupMessage = `${showButton}<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${city} </h5></b> <hr> ${message}  ${colorRow} <hr>`;
    if (hovernote) {
      const newHoverMessage = '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + hoverMessages[hovernote] +'</div>';
      popupMessage  = `${showButton}<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${city} </h5></b> <hr> ${message} ${newHoverMessage} ${colorRow} <hr>` ;
    }
    // mapService.addPopup(event.lngLat, popupMessage);
    setPopupHTML(popupMessage);
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  } else {
    if(typeof messages[1] !== 'undefined') {
      message += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[1] + '</div>'
    }
    if (!yn) {
      // pos 2 in csv file
      if ( typeof messages[2] !== 'undefined') {
        message += '<br><div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[2] + '</div>';
      }
    }
    if (corrwarning == 1) {
      // pos 3 in csv file
      message += '<div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    message = '<div style="line-height:1.2; margin-bottom:0px;"> ' + message + '</div>';
    let popupMessage = `${showButton}<h5 style="font-weight: bold"> ${city} </h5> <hr> ${message}  ${colorRow} <hr>`;
    if (hovernote) {
      popupMessage = `${showButton}<h5 style="font-weight: bold"> ${city} </h5> <hr> ${message} <div style="line-height:1.2; margin-top:5px; margin-bottom:0px;">${hoverMessages[hovernote]}</div>  ${colorRow} <hr>`;
    }
    // mapService.addPopup(event.lngLat, popupMessage);
    setPopupHTML(popupMessage);
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  }
}

export const getCountyComparerPopUpHTML = (geo_id2, county, state, popupsData, options, dataComparisonOptions, stateCodes, setPopupHTML, comparisonOptions) => {
  let headerMessage = '';
  stateCodes.forEach(stateCode => {
    if (stateCode.state == state) {
      headerMessage = stateCode.name;
    }
  });
  let value = '';
  let pos = -1;
  let dcyear = 2018;
  dataComparisonOptions.forEach((element, index) => {
    if (element.geo_id2 === geo_id2) {
      value = (element.value)?element.value:(element.value == 0)?element.value:value;
      dcyear = element.year?element.year:dcyear;
      // pos = element.legendpos?(element.legendpos):pos;
      pos = element.legendpos?(element.legendpos - 1):pos;
    }
  });
  if (+value === -9 || +value === -4 || +value === -2) {
    const errorPopups = errorPopup(popupsData, options);
    setPopupHTML(`<h6>${headerMessage}</h6> <b style="font-weight: 900;"> <h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px" > ${county} </h5> </b> <hr> ${errorPopups[+value].replace('{state}', headerMessage)} <br>`);
    return;
  }
  
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  // if( options.datacomparison['selected'] == 'none') {
  //   options.datacomparison['selected'] = globals.dcSelected;
  // }

  const comparer = searchDC(options, comparisonOptions)[0];
  let message = comparer.maphover;
  message =  message.replace('{varvalue}', value) ;
  message =  message.replace('{year}', dcyear) ;
  const availableColors =  comparer['legendcolors'];
  const legendcolorsArray = transformLegendColors(availableColors, 'county', options.ratescases.selected, options.caseslabel, true, options.datatype, options.rateslabel, options);

  const selectedComparator = MapService.search(options);
  let legends2 = [];
  if (selectedComparator && selectedComparator.length > 0) {
    let legend2 = selectedComparator[0]['legendvalues'];
     legends2 = legend2.split(',');

  }
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legends2.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (let index = 0 ; index < legends2.length; ++index) {
    const color = legendcolorsArray[index];
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  message = '<div style="line-height:1.2; margin-bottom:5px;"> ' + message + '</div>';
  setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"> <h5 style="font-weight: bold; margin-top:-6px; margin-bottom:-9px" > ${county} </h5> </b> <hr> ${message} ${colorRow} <hr>`);
}
export const getStateComparerPopUpHTML = (state, popupsData, options, dataComparisonOptions,  setPopupHTML, comparisonOptions) => {
  // const self = this;
  // if( !dataComparisonOptions ) {
  //   dataComparisonOptions = globals.datacomparisonOptions;
  // } 
  let value = '';
  let pos = -1;
  let year = 2018;
  let stateComp = '';
  let abbrevState = '';
  dataComparisonOptions.forEach((element, index) => {
    if (element.state === state) {
      value = element.value;
      pos = element.legendpos - 1;
      year = element.year;
      stateComp = element.state;
      abbrevState = element.abbrev;
    }
  });
  
  let comparer = searchDC(options, comparisonOptions)[0];
  let hivHover = '';
  if(options.datacomparison.selected == "hivprosecutionlaws" || options.datacomparison.selected == 'hivcrimlaws') {
    comparer = searchDCState(options, comparisonOptions, abbrevState)[0];
    hivHover = comparer.hivcrimhover;
  }
  let message = comparer.maphover;
  if (+value === -9 || +value === -4 || +value === -2) {
    const errorPopups = errorPopup(popupsData, options);
    setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${errorPopups[+value]} <br>`);
    return;
  }
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  
  message = message.replace('{varvalue}', value);
  message = message.replace('{year}', year);
  message = message.replace('{state}', state);
  message = message.replace('{State}', stateComp);
  const availableColors =  comparer['legendcolors'];
  const legendcolorsArray = transformLegendColors(availableColors, 'state', options.ratescases.selected, options.caseslabel, true, options.datatype, options.rateslabel, options);
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendcolorsArray.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (const color of legendcolorsArray) {
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  message = '<div style="line-height:1.2; margin-bottom:5px;"> ' + message + '</div>';
  if(hivHover != '') {
    message = message + '<div style="line-height:1.2; margin-bottom:5px;"> ' + hivHover + '</div>';
  }
  setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message} ${colorRow} <hr>`);
}
export const getCityComparerPopUpHTML = ( properties, popupsData, options, cityValues,setPopupHTML, comparisonOptions) => {
  let city = properties.city;
  const zip = properties.zip;
  const census = properties.geoid10;
  let value = '';
  let pos = -1;
  let year = 2018;
  cityValues.forEach((element, index) => {
    if (options.geographytype['selected'] === 'zipcode') {
      if (element.zipcensus === zip) {
        value = element.value;
        year = element.year?element.year:year;
        // pos = element.legendpos;
        pos = element.legendpos - 1;
        city = `${element.city}, Zip Code ${zip}`;
      }
    } else {
      if (+element.zipcensus === +census) {
        value = element.value;
        year = element.year?element.year:year;
        pos = element.legendpos - 1;
        city = `${element.city}, Census Tract ${census}`;
      }
    }
  });
  if (+value === -9 || +value === -4 || +value === -2) {
    setPopupHTML(` <h5 style="font-weight: bold"> ${city} </h5> <hr> ${errorPopup()} <br>`);
    return;
  }
  const comparer = searchDC(options,comparisonOptions)[0];
  let message = comparer.maphover;
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  message = message.replace('{varvalue}', value);
  if (options.year['selected'] != 'none' && options.year['selected'] != 'hidden') {
    message = message.replace('{year}', '' + options.year['selected']);
  } else {
    message = message.replace('{year}', year);
  }

  const availableColors = comparer['legendcolors'];
  const legendcolorsArray = transformLegendColors(availableColors, 'city', options.ratescases.selected, options.caseslabel, true, options.datatype, options.rateslabel, options);
  let colorRow = '<table style="width:100%"> <tr >';
  legendcolorsArray.pop();
  const divide = 100 / legendcolorsArray.length;
  legendcolorsArray.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  
  for (const color of legendcolorsArray) {
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  message = '<div style="line-height:1.2; margin-bottom:5px;"> ' + message + '</div>';
  setPopupHTML( `<h5 style="font-weight: bold"> ${city} </h5> <hr> ${message} ${colorRow} <hr>`);
}