import {environment} from './environments/environment';
export const SERVER = (function() {
  //  const BASE_URL = 'http://34.194.243.6:8080';
    // const BASE_URL = 'http://localhost:8080';
  //  const BASE_URL = 'http://18.218.191.169:8080';
     const BASE_URL = environment.BASE_URL;
     const PROD_URL = 'http://34.194.243.6:8080';
     const HEP_URL = 'http://13.58.251.188';
  // const BASE_URL = '/api'; //for secure connection
  return {
      BASE_URL: BASE_URL,
      COUNTY_FILTERS: `${BASE_URL}/county-query`,
      GET_CITIES: `${BASE_URL}/cities-names`,
      STATES_FILTERS: `${BASE_URL}/state-query`,
      CITY_FILTERS: `${BASE_URL}/city-query`,
      CITY_GEOTYPE: `${BASE_URL}/cityoptions`,
      PRINT: `${BASE_URL}/print`,
      PRINT_COMPARISON: `${BASE_URL}/print-comparison`,
      CITY_POPUPS: `${BASE_URL}/popupbycity`,
      COUNTY_POPUPS: `${BASE_URL}/popup-county`,
      STATE_POPUPS: `${BASE_URL}/popup-state`,
      LOCAL_STATISTICS: `${BASE_URL}/localstatistics`,
      UPLOAD_PERM: `${BASE_URL}/update-permutation`,
      UPLOAD_POP: `${BASE_URL}/update-popup`,
      UPLOAD_PROD_PERM: `${PROD_URL}/update-permutation`,
      UPLOAD_PROD_POP: `${PROD_URL}/update-popup`,
      UPLOAD_DC: `${BASE_URL}/update-datacomparison-csv`,
      DELETE_DC: `${BASE_URL}/delete_dc`,
      GET_DC: `${BASE_URL}/datacomparison`,
      HEP_URL: HEP_URL,
      GET_CSV: `${BASE_URL}/getcsv`,
      LOGIN: `${BASE_URL}/authenticate`
  };
} )();

export const CARTO = (function () {
  const USERNAME = environment.carto_account;
  const API_KEY = environment.carto_api_key;
  const BASE_URL = `https://${USERNAME}.carto.com/api/v2`;
  const API_KEY_PARAMETER = `&api_key=${API_KEY}`;
  const FORMAT = '&format=GeoJSON';
  const DISTRICT_TABLE = 'congressional_districts_118';
  const TABLE = 'nih_data_site_listing_2017';
  const STATE_TABLE = 'state2010';
  const COUNTY_TABLE = 'counties2020';
  const COUNTY_TABLE2 = 'counties2020';
  const STATE_COMPARER = 'table_2023_state_sdoh_for_2021';
  // const STATE_COMPARER = 'table_2020stateprev2018'; //funcionan los popups 
  const COUNTY_COMPARER = 'table_2023_countyprev2021';
  const CITY_ZIP_COMPARER = 'table_2023zipprev2021';
  const CITY_CENSUS_COMPARER = 'table_2023censusprev2021';
  // const CITY_LATEDX_COMPARER = 'table_2018zipprev2016';
  // const CITY_VIRALSUPPRESSION_COMPARER = 'table_2018censusprev2016';
  const CITY_TABLE = 'aidsvu_zip_codes_2023';
  const CITY_ZIP_CODE = 'aidsvu_zip_codes_2023';
  const CITY_CENSUS = 'allcities_census_2017';
  const CITY_WARD = 'dc_ward_2017';
  const CITY_COMMUNITY = 'chicago_community_area_2017';
  const HIV_TABLE = 'hiv_testing_sites';
  const HCS_TABLE = 'chc_sites';
  const HOUSING_TABLE = 'mlexport_aidsvu_2016hopwa';
  const PREP_TABLE = 'prep_services';
  const RYAN_TABLE = 'primary_care_hpsa_points';
  const CITIES_CENTROIDS = 'cities_centroids';
  const URBAN_RURAL_TABLE = 'urbanruralzipcodes';
  return {
    API_KEY: API_KEY,
    API_KEY_PARAMETER: API_KEY_PARAMETER,
    BASE_URL: BASE_URL,
    CITY_CENSUS: CITY_CENSUS,
    CITY_COMMUNITY: CITY_COMMUNITY,
    CITY_TABLE: CITY_TABLE,
    CITY_WARD: CITY_WARD,
    CITY_ZIP_CODE: CITY_ZIP_CODE,
    CITY_ZIP_COMPARER: CITY_ZIP_COMPARER,
    CITY_CENSUS_COMPARER: CITY_CENSUS_COMPARER,
    COUNTY_TABLE: COUNTY_TABLE,
    COUNTY_TABLE2: COUNTY_TABLE2,
    COUNTY_COMPARER: COUNTY_COMPARER,
    DISTRICT_TABLE: DISTRICT_TABLE,
    GET_QUERY: function (sql, geojson) {
      let url = `${BASE_URL}/sql?q=${sql}${API_KEY_PARAMETER}`;
      if (geojson) {
        url = url + FORMAT;
      }
      return url;
    },
    FORMAT: FORMAT,
    HIV_TABLE: HIV_TABLE,
    HOUSING_TABLE: HOUSING_TABLE,
    PREP_TABLE: PREP_TABLE,
    HCS_TABLE: HCS_TABLE,
    RYAN_TABLE: RYAN_TABLE,
    STATE_TABLE: STATE_TABLE,
    STATE_COMPARER: STATE_COMPARER,
    CITIES_CENTROIDS: CITIES_CENTROIDS,
    TABLE: TABLE,
    USERNAME: USERNAME,
    URBAN_RURAL_TABLE: URBAN_RURAL_TABLE
  };
})();

// export const MAPZEN = (function () {
//   const API_KEY = 'mapzen-2s1uEQU';
//   const BASE_URL = `https://search.mapzen.com/v1`;
//   const API_KEY_PARAMETER = `&api_key=${API_KEY}`;
//   const AUTOCOMPLETE_URL = `${BASE_URL}/autocomplete`;
//   const TEXT_PARAMETER = '&text=';
//   const COUNTRY_PARAMETER = '&boundary.country=USA';
//   return {
//     API_KEY: API_KEY,
//     BASE_URL: BASE_URL,
//     API_KEY_PARAMETER: API_KEY_PARAMETER,
//     AUTOCOMPLETE_URL: AUTOCOMPLETE_URL,
//     TEXT_PARAMETER: TEXT_PARAMETER,
//     COUNTRY_PARAMETER: COUNTRY_PARAMETER,
//     GET_AUTOCOMPLETE: function (text) {
//       let url = `${AUTOCOMPLETE_URL}?${TEXT_PARAMETER}${text}${COUNTRY_PARAMETER}${API_KEY_PARAMETER}`;
//       return url;
//     },
//   };
// })();
